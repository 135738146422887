import { ItemStimulusSubTypes } from "../../lib/ItemUtil";
import AdvancedSearchBase, { AdvancedSearchBaseProps } from "../lib/AdvancedSearchBase";

class StimulusAdvancedSearch extends AdvancedSearchBase {
    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = "stimulus";
        this.title = "Stimulus";
        const now = new Date();
        const year = String(now.getFullYear());
        const month = String(now.getMonth() + 1);
        const mday = String(now.getDate());
        this.state.values["title"] = "";
        this.state.values["keyword"] = "";
        this.state.values["description"] = "";
        this.state.values["doi"] = "";
        this.state.values["stimulus_type"] = "";
        this.state.values["developer"] = "";
        this.state.values["publication_year"] = year;
        this.state.values["publication_month"] = month;
        this.state.values["publication_mday"] = mday;
        this.state.values["file.preview.caption"] = "";
        this.setIgnoreKey("publication_year");
        this.setIgnoreKey("publication_month");
        this.setIgnoreKey("publication_mday");
    }

    getRows() {
        const rows = [
            { label: "[en]Title[/en][ja]タイトル[/ja]", value: this.renderFieldInputText("title", 50) },
            { label: "[en]Free Keywords[/en][ja]フリーキーワード[/ja]", value: this.renderFieldInputText("keyword", 50) },
            { label: "[en]Description[/en][ja]概要[/ja]", value: this.renderFieldInputText("description", 50) },
            { label: "ID", value: this.renderFieldInputText("doi", 50) },
            { label: "[en]Stimulus Type[/en][ja]刺激タイプ[/ja]", value: this.renderFieldSelect("stimulus_type", ItemStimulusSubTypes) },
            { label: "[en]Developer[/en][ja]開発者[/ja]", value: this.renderFieldInputText("developer", 50) },
            { label: "[en]Date[/en][ja]日付[/ja]", value: this.renderFieldDate("", "publication_year", "publication_month", "publication_mday") },
            { label: "[en]Caption[/en][ja]キャプション[/ja]", value: this.renderFieldInputText("file.preview.caption", 50) },
        ];
        return rows;
    }
}

export default StimulusAdvancedSearch;
