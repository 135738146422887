import React from "react";
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AppRoot from "./common/AppRoot";

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <CookiesProvider>
                <HelmetProvider>
                    <AppRoot />
                </HelmetProvider>
            </CookiesProvider>
        </BrowserRouter>
    );
};

export default App;
