import BrainCoordinateUtil, { BrainCoordinate } from "../../nimgcenter/lib/BrainCoordinateUtil";
import AdvancedSearchQuery from "../../xoonips/lib/AdvancedSearchQuery";
import ItemUtil, { ItemNimgcenter, SearchCallbackFunc, SortCondition } from "../../xoonips/lib/ItemUtil";
import brainArea from "../assets/brainarea.json";

export type BrainCoordinateAxis = "x" | "y" | "z";
interface BrainCoordinateBase<T> {
    x: T;
    y: T;
    z: T;
}
export type BrainCoordinateString = BrainCoordinateBase<string>;
export type BrainCoordinateType = "mni" | "talairach" | "unspecified";
export type BrainImageSize = "large" | "medium" | "small";
export type SearchPaperType = "all" | "normal" | "patients";

const brainImageConfig = {
    x: { pics: 91, width: 109, height: 91, range: { num: 180, start: -90, end: 90 } },
    y: { pics: 109, width: 91, height: 91, range: { num: 216, start: -126, end: 90 } },
    z: { pics: 91, width: 91, height: 109, range: { num: 180, start: 108, end: -72 } },
};

const brainImageFactor = {
    large: 2.0,
    medium: 1.5,
    small: 1.0,
};

const brainImageSpotConfig = {
    gapSize: [
        { gap: 4, size: 8 },
        { gap: 8, size: 6 },
        { gap: 12, size: 4 },
        { gap: 16, size: 2 },
    ],
    color: ["aqua", "yellow", "green", "red", "purple", "orange", "pink", "blue", "springgreen", "silver"],
};

const fixRange = (value: number, min: number, max: number) => (value < min ? min : value > max ? max : value);

const loadImage = async (url: string): Promise<HTMLImageElement> => {
    const image = new Image();
    await new Promise((resolve, reject) => {
        image.addEventListener("load", resolve, { once: true });
        image.addEventListener("error", reject, { once: true });
        image.src = url;
    });
    return image;
};

const getResourceUrl = (name: string): string => {
    return `/modules/nimgsearch/res/${name}`;
};

const getBrainImageUrl = (brainImageId: number, axis: BrainCoordinateAxis): string => {
    return getResourceUrl(`${axis}/brain${brainImageId}.png`);
};

const getBrainAreaImageUrl = (brainAreaId: number, brainImageId: number, axis: BrainCoordinateAxis): string => {
    const level = brainAreaId % 10;
    return getResourceUrl(`level${level}/${brainAreaId}/${axis}/brain${brainImageId}.png`);
};

const roundBrainCoordinate = (coord: BrainCoordinate) => {
    return { x: Math.round(coord.x), y: Math.round(coord.y), z: Math.round(coord.z) };
};

const fixBrainCoordinate = (coord: BrainCoordinate, type: BrainCoordinateType): BrainCoordinate => {
    const mniCoord = type === "talairach" ? BrainCoordinateUtil.convertCoordinate("tal2icbm_spm", coord) : { ...coord };
    const fixedMniCoord = {
        x: fixRange(mniCoord.x, brainImageConfig.x.range.start, brainImageConfig.x.range.end),
        y: fixRange(mniCoord.y, brainImageConfig.y.range.start, brainImageConfig.y.range.end),
        z: fixRange(mniCoord.z, brainImageConfig.z.range.end, brainImageConfig.z.range.start),
    };
    return roundBrainCoordinate(type === "talairach" ? BrainCoordinateUtil.convertCoordinate("icbm_spm2tal", fixedMniCoord) : fixedMniCoord);
};

const getBrainCoordinateFromOffset = (currentCoord: BrainCoordinate, type: BrainCoordinateType, offset: { x: number; y: number }, imageSize: BrainImageSize, axis: BrainCoordinateAxis): BrainCoordinate => {
    const factor = brainImageFactor[imageSize];
    const mniCoord = type === "talairach" ? BrainCoordinateUtil.convertCoordinate("tal2icbm_spm", currentCoord) : { ...currentCoord };
    switch (axis) {
        case "y":
            mniCoord.x = (offset.x * brainImageConfig.x.range.num) / brainImageConfig.y.width / factor + brainImageConfig.x.range.start;
            mniCoord.z = (offset.y * -brainImageConfig.z.range.num) / brainImageConfig.y.height / factor + brainImageConfig.z.range.start;
            break;
        case "x":
            mniCoord.y = (offset.x * brainImageConfig.y.range.num) / brainImageConfig.x.width / factor + brainImageConfig.y.range.start;
            mniCoord.z = (offset.y * -brainImageConfig.z.range.num) / brainImageConfig.x.height / factor + brainImageConfig.z.range.start;
            break;
        case "z":
            mniCoord.x = (offset.x * brainImageConfig.x.range.num) / brainImageConfig.z.width / factor + brainImageConfig.x.range.start;
            mniCoord.y = (offset.y * -brainImageConfig.y.range.num) / brainImageConfig.z.height / factor + brainImageConfig.y.range.end;
            break;
    }
    return roundBrainCoordinate(type === "talairach" ? BrainCoordinateUtil.convertCoordinate("icbm_spm2tal", mniCoord) : mniCoord);
};

const getOffsetFromBrainCoordinate = (coord: BrainCoordinate, type: BrainCoordinateType, imageSize: BrainImageSize, axis: BrainCoordinateAxis) => {
    const mniCoord = type === "talairach" ? BrainCoordinateUtil.convertCoordinate("tal2icbm_spm", coord) : { ...coord };
    const factor = brainImageFactor[imageSize];
    const offset = { x: 0, y: 0 };
    switch (axis) {
        case "y":
            offset.x = ((mniCoord.x - brainImageConfig.x.range.start) / brainImageConfig.x.range.num) * brainImageConfig.y.width;
            offset.y = ((mniCoord.z - brainImageConfig.z.range.start) / -brainImageConfig.z.range.num) * brainImageConfig.y.height;
            break;
        case "x":
            offset.x = ((mniCoord.y - brainImageConfig.y.range.start) / brainImageConfig.y.range.num) * brainImageConfig.x.width;
            offset.y = ((mniCoord.z - brainImageConfig.z.range.start) / -brainImageConfig.z.range.num) * brainImageConfig.x.height;
            break;
        case "z":
            offset.x = ((mniCoord.x - brainImageConfig.x.range.start) / brainImageConfig.x.range.num) * brainImageConfig.z.width;
            offset.y = ((mniCoord.y - brainImageConfig.y.range.end) / -brainImageConfig.y.range.num) * brainImageConfig.z.height;
            break;
    }
    return {
        x: fixRange(Math.round(offset.x * factor), 0, Math.round(brainImageConfig[axis].width * factor)),
        y: fixRange(Math.round(offset.y * factor), 0, Math.round(brainImageConfig[axis].height * factor)),
    };
};

const getBrainImageId = (coord: BrainCoordinate, axis: BrainCoordinateAxis): number => {
    const im = brainImageConfig[axis];
    const offset = coord[axis] - (axis === "z" ? im.range.end : im.range.start);
    return Math.floor(offset / 2 + 1);
};

const getBrainAreaImage = async (brainAreaIds: number[], brainImageId: number, axis: BrainCoordinateAxis): Promise<HTMLImageElement> => {
    const canvas = document.createElement("canvas");
    canvas.width = brainImageConfig[axis].width;
    canvas.height = brainImageConfig[axis].height;
    const ctx = canvas.getContext("2d");
    if (ctx !== null) {
        const areas = await Promise.all(brainAreaIds.map(async (brainAreaId) => loadImage(getBrainAreaImageUrl(brainAreaId, brainImageId, axis))));
        areas.forEach((area) => {
            ctx.drawImage(area, 0, 0, canvas.width, canvas.height);
        });
        const idata = ctx.getImageData(0, 0, canvas.width, canvas.height);
        for (let ii = 0; ii < idata.data.length; ii += 4) {
            if (idata.data[ii + 3] > 0) {
                idata.data[ii] = 0xff;
                idata.data[ii + 1] = 0;
                idata.data[ii + 2] = 0;
                idata.data[ii + 3] = 0xff;
            }
        }
        ctx.putImageData(idata, 0, 0);
    }
    const image = await loadImage(canvas.toDataURL("image/gif"));
    return image;
};

const updateBrainImage = async (canvas: HTMLCanvasElement, coord: BrainCoordinate, type: BrainCoordinateType, axis: BrainCoordinateAxis, imageSize: BrainImageSize, brainAreaIds: number[], showLines: boolean, myItems: ItemNimgcenter[]): Promise<void> => {
    const ctx = canvas.getContext("2d");
    if (ctx !== null) {
        const factor = brainImageFactor[imageSize];
        const width = Math.round(brainImageConfig[axis].width * factor);
        const height = Math.round(brainImageConfig[axis].height * factor);
        if (canvas.width !== width || canvas.height !== height) {
            canvas.width = width;
            canvas.height = height;
        }
        const brainImageId = getBrainImageId(coord, axis);
        const brainImage = await loadImage(getBrainImageUrl(brainImageId, axis));
        ctx.drawImage(brainImage, 0, 0, width, height);
        if (brainAreaIds.length > 0) {
            const brainAreaImage = await getBrainAreaImage(brainAreaIds, brainImageId, axis);
            ctx.save();
            ctx.globalAlpha = 0.3;
            ctx.drawImage(brainAreaImage, 0, 0, width, height);
            ctx.restore();
        }
        if (myItems.length > 0) {
            myItems.forEach((item, index) => {
                ctx.save();
                ctx.fillStyle = brainImageSpotConfig.color[index % 10];
                ctx.globalAlpha = 0.6;
                item.coordinate.forEach((spotCoord) => {
                    const gapSize = brainImageSpotConfig.gapSize.find((value) => Math.abs(coord[axis] - spotCoord[axis]) <= value.gap);
                    if (typeof gapSize !== "undefined") {
                        const offset = getOffsetFromBrainCoordinate(spotCoord, type, imageSize, axis);
                        ctx.beginPath();
                        ctx.arc(offset.x + 0.5, offset.y - 0.5, gapSize.size, 0, Math.PI * 2, true);
                        ctx.fill();
                    }
                });
                ctx.restore();
            });
        }
        if (showLines) {
            const offset = getOffsetFromBrainCoordinate(coord, type, imageSize, axis);
            ctx.save();
            ctx.strokeStyle = "#8080c0";
            ctx.lineWidth = 2.5;
            ctx.beginPath();
            ctx.moveTo(offset.x + 0.5, 0);
            ctx.lineTo(offset.x + 0.5, height);
            ctx.moveTo(0, offset.y - 0.5);
            ctx.lineTo(width, offset.y - 0.5);
            ctx.stroke();
            ctx.restore();
        }
    }
};

const getBrainAreaNames = (level: number): string[] => {
    if (level < 1 || level > 5) {
        return [];
    }
    return brainArea[level - 1];
};

const getBrainAreaName = (id: number): string => {
    const level = (id % 10) - 1;
    const index = Math.floor(id / 10) - 1;
    if (brainArea.length <= level || brainArea[level].length <= index) {
        return "";
    }
    return brainArea[level][index];
};

const getBrainAreaId = (level: number, index: number): number => {
    return (index + 1) * 10 + level;
};

const searchXoonipsItems = (coord: BrainCoordinate, radius: number, coordType: BrainCoordinateType, searchType: SearchPaperType, sortCondition: SortCondition, advancedQeury: AdvancedSearchQuery, andOr: string, func: SearchCallbackFunc) => {
    const searchMain = (baseItemIds: number[]) => {
        const query = new AdvancedSearchQuery();
        const coordTypeMap = { mni: "M", talairach: "T", unspecified: "U" };
        query.set("nimgcenter", "tx", coord.x.toString());
        query.set("nimgcenter", "ty", coord.y.toString());
        query.set("nimgcenter", "tz", coord.z.toString());
        query.set("nimgcenter", "radius", radius.toString());
        query.set("nimgcenter", "coord_type", coordTypeMap[coordType]);
        query.set("nimgcenter", "coord_type_conversion", "yes");
        if (andOr !== "") {
            query.set("nimgcenter", "nimgsearch_andor", andOr);
        }
        if (searchType !== "all") {
            query.set("nimgcenter", "index_id", searchType === "normal" ? "4375" : "4376");
        }
        if (baseItemIds.length > 0) {
            query.set("nimgcenter", "baseitem_id", baseItemIds.join(","));
        }
        ItemUtil.getListByAdvancedSearchQuery(query, sortCondition, func);
    };
    if (!advancedQeury.empty()) {
        ItemUtil.getListByAdvancedSearchQuery(advancedQeury, null, (result) => {
            const baseItemIds = result.data.map((item) => item.item_id);
            searchMain(baseItemIds);
        });
    } else {
        searchMain([]);
    }
};

const NimgsearchUtil = {
    fixBrainCoordinate,
    roundBrainCoordinate,
    getBrainCoordinateFromOffset,
    updateBrainImage,
    getBrainAreaNames,
    getBrainAreaName,
    getBrainAreaId,
    searchXoonipsItems,
};

export default NimgsearchUtil;
