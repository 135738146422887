import React from "react";
import ItemType from "..";
import { MultiLang } from "../../../config";
import Functions from "../../../functions";
import ItemUtil, { Item, ItemBinder } from "../../lib/ItemUtil";
import DetailBase from "../lib/DetailBase";
import ItemTypeField from "../lib/field";

interface Props {
    lang: MultiLang;
    item: ItemBinder;
}

interface State {
    items: Item[];
}

class BinderLinkItems extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.updateItems();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const prevItemIds = prevProps.item.item_link;
        const nextItemIds = this.props.item.item_link;
        if (prevItemIds.toString() !== nextItemIds.toString()) {
            this.updateItems();
        }
    }

    updateItems() {
        const { item } = this.props;
        const itemIds = item.item_link;
        ItemUtil.getList(itemIds, (results) => {
            const items = results.data;
            this.setState({ items });
        });
    }

    render() {
        const { lang } = this.props;
        return (
            <table className="listTable">
                <tbody>
                    {this.state.items.map((item, idx) => {
                        const evenodd = idx % 2 ? "even" : "odd";
                        return (
                            <tr key={item.item_id}>
                                <td className={evenodd}>
                                    <ItemType.List lang={lang} item={item} />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
}

class BinderDetail extends DetailBase {
    getFields() {
        const { lang } = this.props;
        const item = this.props.item as ItemBinder;
        return [
            { label: "ID", value: item.doi },
            { label: "[en]Title[/en][ja]タイトル[/ja]", value: Functions.mlang(item.title, lang) },
            { label: "[en]Free Keywords[/en][ja]フリーキーワード[/ja]", value: <ItemTypeField.FreeKeyword lang={lang} keyword={item.keyword} /> },
            { label: "[en]Description[/en][ja]概要[/ja]", value: <ItemTypeField.Description lang={lang} description={item.description} /> },
            { label: "[en]Last Modified Date[/en][ja]最終更新日[/ja]", value: <ItemTypeField.DateTime lang={lang} date={item.last_update_date} /> },
            { label: "[en]Created Date[/en][ja]作成日[/ja]", value: <ItemTypeField.DateTime lang={lang} date={item.creation_date} /> },
            { label: "[en]Contributor[/en][ja]登録者[/ja]", value: <ItemTypeField.Contributer lang={lang} uname={item.uname} name={item.name} /> },
            { label: "[en]Item Type[/en][ja]アイテムタイプ[/ja]", value: item.item_type_display_name },
            { label: "[en]Change Log(History)[/en][ja]変更履歴[/ja]", value: <ItemTypeField.ChangeLog lang={lang} changelog={item.changelog} /> },
            { label: "Index", value: <ItemTypeField.ItemIndex lang={lang} index={item.index} /> },
        ];
    }

    render() {
        const { lang } = this.props;
        const item = this.props.item as ItemBinder;
        const detail = super.render.call(this);
        return (
            <>
                {detail}
                <h4>Registered Items</h4>
                <BinderLinkItems lang={lang} item={item} />
            </>
        );
    }
}

export default BinderDetail;
