import BookAdvancedSearch from "./BookAdvancedSearch";
import BookDetail from "./BookDetail";
import BookList from "./BookList";
import BookTop from "./BookTop";

const ItemTypeBook = {
    Top: BookTop,
    List: BookList,
    Detail: BookDetail,
    AdvancedSearch: BookAdvancedSearch,
};

export default ItemTypeBook;
