import React from "react";
import { MultiLang } from "../../../config";
import ItemUtil, { ItemBasicFile } from "../../lib/ItemUtil";

interface BannerFileProps {
    lang: MultiLang;
    file: ItemBasicFile[];
}

const BannerFile: React.FC<BannerFileProps> = (props: BannerFileProps) => {
    const { file } = props;
    const data = file.find((value) => {
        return value.file_type_name === "url_banner_file";
    });
    if (typeof data === "undefined") {
        return null;
    }
    const url = ItemUtil.getFileUrl(data);
    return (
        <a href={url} download={data.original_file_name} target="_blank" rel="noopener noreferrer">
            <img src={url} alt="banner" />
        </a>
    );
};

const UrlUtil = {
    BannerFile,
};

export default UrlUtil;
