import React from "react";
import { Helmet } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../common/lib/PageNotFound";
import { MultiLang } from "../config";
import Functions from "../functions";
import BrainExplorerIndex from "./BrainExplorerIndex";

interface Props {
    lang: MultiLang;
}

const BrainExplorer: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <>
            <Helmet>
                <title>Brain Explorer - {Functions.siteTitle(lang)}</title>
            </Helmet>
            <Routes>
                <Route path="index_jm_eng.php" element={<BrainExplorerIndex lang="en" type="jm" />} />
                <Route path="index_jm_jpn.php" element={<BrainExplorerIndex lang="ja" type="jm" />} />
                <Route path="index_hu_eng.php" element={<BrainExplorerIndex lang="en" type="hu" />} />
                <Route path="index_hu_jpn.php" element={<BrainExplorerIndex lang="ja" type="hu" />} />
                <Route path="*" element={<PageNotFound lang={lang} />} />
            </Routes>
        </>
    );
};

export default BrainExplorer;
