import React, { useState } from "react";
import { MultiLang } from "../../../../config";
import ItemUtil, { ItemBasicFile } from "../../../lib/ItemUtil";
import styles from "./FileDownloadButton.module.css";
import LicenseAgreementDialog from "./LicenseAgreementDialog";

interface Props {
    lang: MultiLang;
    file: ItemBasicFile;
    rights: string;
    useCc: number;
    ccCommercialUse: number;
    ccModification: number;
}

const FileDownloadButton: React.FC<Props> = (props: Props) => {
    const { lang, file, rights, useCc, ccCommercialUse, ccModification } = props;
    const [isShow, setIsShow] = useState<boolean>(false);

    const handleClickDownload = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (rights !== "") {
            e.stopPropagation();
            e.preventDefault();
            setIsShow(true);
        }
    };

    const url = ItemUtil.getFileUrl(file);
    return (
        <>
            <a className={styles.downloadButton} href={url} download={file.original_file_name} target="_blank" rel="noopener noreferrer" onClick={handleClickDownload}>
                Download
            </a>
            <LicenseAgreementDialog lang={lang} file={file} rights={rights} useCc={useCc} ccCommercialUse={ccCommercialUse} ccModification={ccModification} show={isShow} unsetShow={() => setIsShow(false)} />
        </>
    );
};

export default FileDownloadButton;
