import React from "react";
import { Link } from "react-router-dom";
import { MultiLang } from "../../config";
import Functions from "../../functions";

interface Props {
    lang: MultiLang;
}

const HowToUseLinks: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = "[en]How to use NIMG-PF[/en][ja]NIMG-PFの使い方[/ja]";
    const content = {
        en: (
            <ul>
                <li>
                    <a href="/modules/nimgdocs/docs/pdf/NIMGPFmanual_en.pdf">NeuroImaging-Plarform (NIMG-PF) User Manual</a>
                </li>
            </ul>
        ),
        ja: (
            <ul>
                <li>
                    <a href="/modules/nimgdocs/docs/pdf/NIMGPFmanual_ja.pdf">ニューロイメージング・プラットフォーム(NIMG-PF)の利用マニュアル</a>
                </li>
                <li>
                    <Link to="/modules/nimgdocs/index.php?docname=introduction">NIMG-PFの機能と使い方 解説一覧表</Link>
                </li>
            </ul>
        ),
    };
    return (
        <div className="block">
            <div className="blockTitle">{Functions.mlang(title, lang)}</div>
            <div className="blockContent">{content[lang]}</div>
        </div>
    );
};

export default HowToUseLinks;
