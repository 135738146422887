import iconFile from "../../assets/images/icon_nimgcenter.gif";
import TopBase, { TopBaseProps } from "../lib/TopBase";

class NimgcenterTop extends TopBase {
    constructor(props: TopBaseProps) {
        super(props);
        this.type = "nimgcenter";
        this.label = "BrainCoordinates";
        this.icon = iconFile;
        this.description = '[en]"Brain Coordinates" contains the coordinates of brain activations. Using "Search with Brain Atlas" on the task bar, activated locations are visualized on the brain atlas and linked to the paper carrying the data.[/en][ja]賦活部位の座標データを収集しています。タスクバーにある「脳図検索モード」を使用して、賦活部位の脳図上の表示、座標からの論文検索が可能です。[/ja]';
    }
}

export default NimgcenterTop;
