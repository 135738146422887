import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { MultiLang } from "../config";
import Functions from "../functions";
import { BrainCoordinate } from "../nimgcenter/lib/BrainCoordinateUtil";
import ItemType from "../xoonips/item-type";
import AdvancedSearchQuery from "../xoonips/lib/AdvancedSearchQuery";
import ItemUtil, { Item, ItemNimgcenter, ItemPaper, SearchResult, SortCondition, SortConditionLimit, SortConditionOrderBy, SortConditionOrderDir } from "../xoonips/lib/ItemUtil";
import NimgsearchUtil, { BrainCoordinateAxis, BrainCoordinateString, BrainCoordinateType, BrainImageSize, SearchPaperType } from "./lib/NimgsearchUtil";
import styles from "./Nimgsearch.module.css";

interface Props {
    lang: MultiLang;
}

interface State {
    pointer: BrainCoordinate;
    coord: BrainCoordinate;
    coordType: BrainCoordinateType;
    showSettings: boolean;
    showHelp: boolean;
    imageSize: BrainImageSize;
    showLines: boolean;
    showSpots: boolean;
    formCoord: BrainCoordinateString;
    formRadiusRadio: string;
    formRadiusSelect: string;
    formCoordType: BrainCoordinateType;
    formPaperType: SearchPaperType;
    brainAreaLevel: number;
    brainAreaIds: number[];
    itemType: string;
    andOr: string;
    query: AdvancedSearchQuery;
    sortCondition: SortCondition;
    searchResult: SearchResult;
    showInfoItemIds: number[];
    myItems: ItemNimgcenter[];
    tab: number;
}

class Nimgsearch extends React.Component<Props, State> {
    private readonly defaultCoord: BrainCoordinate = { x: 0, y: 0, z: 0 };
    private readonly defaultSortCondition: SortCondition = {
        limit: 20,
        orderBy: "title",
        orderDir: SortConditionOrderDir.DESC,
        page: 1,
    };
    private isActive = false;
    private brainImage = {
        x: React.createRef<HTMLCanvasElement>(),
        y: React.createRef<HTMLCanvasElement>(),
        z: React.createRef<HTMLCanvasElement>(),
    };
    private brainArea = {
        source: React.createRef<HTMLSelectElement>(),
        target: React.createRef<HTMLSelectElement>(),
    };
    private baseItems = new Map<number, Item>();

    constructor(props: Props) {
        super(props);
        this.state = {
            pointer: { ...this.defaultCoord },
            coord: { ...this.defaultCoord },
            coordType: "mni",
            showSettings: false,
            showHelp: false,
            imageSize: "large",
            showLines: true,
            showSpots: true,
            formCoord: { x: "0", y: "0", z: "0" },
            formRadiusRadio: "2",
            formRadiusSelect: "10",
            formCoordType: "mni",
            formPaperType: "all",
            brainAreaLevel: 1,
            brainAreaIds: [],
            itemType: "",
            andOr: "and",
            query: new AdvancedSearchQuery(),
            sortCondition: { ...this.defaultSortCondition },
            searchResult: { total: 0, data: [] },
            showInfoItemIds: [],
            myItems: [],
            tab: 0,
        };
        this.onClickUpdateButton = this.onClickUpdateButton.bind(this);
    }

    componentDidMount() {
        this.isActive = true;
        this.onUpdateBrainImage({ coord: this.defaultCoord });
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    onClickBrainImage(axis: BrainCoordinateAxis) {
        const coord = {
            x: axis === "x" ? this.state.coord.x : this.state.pointer.x,
            y: axis === "y" ? this.state.coord.y : this.state.pointer.y,
            z: axis === "z" ? this.state.coord.z : this.state.pointer.z,
        };
        this.onUpdateBrainImage({ coord });
    }

    onMouseMoveBrainImage(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>, axis: BrainCoordinateAxis) {
        const offset = { x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY };
        const pointer = NimgsearchUtil.getBrainCoordinateFromOffset(this.state.pointer, this.state.formCoordType, offset, this.state.imageSize, axis);
        if (pointer.x !== this.state.pointer.x || pointer.y !== this.state.pointer.y || pointer.z !== this.state.pointer.z) {
            this.setState({ pointer });
        }
    }

    onChnageCoordinate(event: React.ChangeEvent<HTMLInputElement>, axis: BrainCoordinateAxis) {
        const value = event.target.value;
        const formCoord = { ...this.state.formCoord };
        formCoord[axis] = value;
        this.setState({ formCoord: formCoord });
    }

    onUpdateBrainImage(nextState: Partial<State>) {
        const coord = typeof nextState.coord !== "undefined" ? nextState.coord : this.state.coord;
        const imageSize = typeof nextState.imageSize !== "undefined" ? nextState.imageSize : this.state.imageSize;
        const brainAreaIds = typeof nextState.brainAreaIds !== "undefined" ? nextState.brainAreaIds : this.state.brainAreaIds;
        const showLines = typeof nextState.showLines !== "undefined" ? nextState.showLines : this.state.showLines;
        const showSpots = typeof nextState.showSpots !== "undefined" ? nextState.showSpots : this.state.showSpots;
        const myItems = typeof nextState.myItems != "undefined" ? nextState.myItems : this.state.myItems;
        const formCoordType = typeof nextState.formCoordType != "undefined" ? nextState.formCoordType : this.state.formCoordType;
        if (this.isActive) {
            nextState.formCoord = { x: coord.x.toString(), y: coord.y.toString(), z: coord.z.toString() };
            this.brainImage.x.current && NimgsearchUtil.updateBrainImage(this.brainImage.x.current, coord, formCoordType, "x", imageSize, brainAreaIds, showLines, showSpots ? myItems : []);
            this.brainImage.y.current && NimgsearchUtil.updateBrainImage(this.brainImage.y.current, coord, formCoordType, "y", imageSize, brainAreaIds, showLines, showSpots ? myItems : []);
            this.brainImage.z.current && NimgsearchUtil.updateBrainImage(this.brainImage.z.current, coord, formCoordType, "z", imageSize, brainAreaIds, showLines, showSpots ? myItems : []);
            this.setState(nextState as State);
        }
    }

    onClickUpdateButton(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        const coord = NimgsearchUtil.fixBrainCoordinate(
            {
                x: parseInt(this.state.formCoord.x, 10),
                y: parseInt(this.state.formCoord.y, 10),
                z: parseInt(this.state.formCoord.z, 10),
            },
            this.state.formCoordType
        );
        this.onUpdateBrainImage({ coord });
    }

    updateSearchResults(sortCondition: SortCondition, coordType: BrainCoordinateType) {
        const radius = parseInt(this.state.formRadiusRadio !== "" ? this.state.formRadiusRadio : this.state.formRadiusSelect, 10);
        NimgsearchUtil.searchXoonipsItems(this.state.coord, radius, coordType, this.state.formPaperType, sortCondition, this.state.query, this.state.itemType !== "" ? this.state.andOr : "", async (searchResult) => {
            await Promise.all(
                searchResult.data
                    .filter((item) => {
                        const nimgcenterItem = item.item_type_name === "xnpnimgcenter" ? (item as ItemNimgcenter) : undefined;
                        return nimgcenterItem && !this.baseItems.has(nimgcenterItem.baseitem_id);
                    })
                    .map(async (item) => {
                        const nimgcenterItem = item as ItemNimgcenter;
                        return new Promise<void>((resolve) => {
                            ItemUtil.get(nimgcenterItem.baseitem_id, (baseItem) => {
                                if (baseItem !== null) {
                                    this.baseItems.set(baseItem.item_id, baseItem);
                                }
                                resolve();
                            });
                        });
                    })
            );
            if (this.isActive) {
                this.setState({ tab: 2, sortCondition, searchResult, showInfoItemIds: [], coordType });
            }
        });
    }

    renderListItem(item: Item) {
        const { lang } = this.props;
        const nimgcenterItem = item.item_type_name === "xnpnimgcenter" ? (item as ItemNimgcenter) : undefined;
        if (typeof nimgcenterItem === "undefined") {
            return <div>Unexpected Error: {item.title}</div>;
        }
        const baseItem = this.baseItems.get(nimgcenterItem.baseitem_id);
        const paperItem = baseItem?.item_type_name === "xnppaper" ? (baseItem as ItemPaper) : undefined;
        const isShowInfo = this.state.showInfoItemIds.includes(item.item_id);
        const isShowSpot = !!this.state.myItems.find((value) => value.item_id === item.item_id);
        return (
            <div className={styles.listItem}>
                <div>
                    <a href={ItemUtil.getUrl(item)} target="_blank" rel="noopener noreferrer">
                        {Functions.mlang(item.title, lang)}
                    </a>
                </div>
                {paperItem && (
                    <>
                        <div>
                            {paperItem.author.map((author, index) => (
                                <Fragment key={index}>
                                    <span>{author}</span>
                                    {index + 1 !== paperItem.author.length && <>, </>}
                                </Fragment>
                            ))}
                        </div>
                        <div>
                            <span>({paperItem.publication_year})</span> <em>{paperItem.journal}</em>, <span>{paperItem.volume}</span>, <span>{paperItem.page}</span>
                            <input
                                style={{ fontWeight: isShowInfo ? "bold" : "normal" }}
                                type="button"
                                value="[INFO]"
                                onClick={() => {
                                    const showInfoItemIds = isShowInfo ? this.state.showInfoItemIds.filter((id) => id !== item.item_id) : this.state.showInfoItemIds.concat(item.item_id);
                                    this.setState({ showInfoItemIds });
                                }}
                            />
                            <input
                                style={{ fontWeight: isShowSpot ? "bold" : "normal" }}
                                type="button"
                                value="[SPOT]"
                                onClick={() => {
                                    const myItems = isShowSpot ? this.state.myItems.filter((value) => value.item_id !== item.item_id) : this.state.myItems.concat(nimgcenterItem);
                                    this.onUpdateBrainImage({ myItems });
                                }}
                            />
                        </div>
                    </>
                )}
                {isShowInfo && (
                    <div className={styles.listItemCoordinates}>
                        {nimgcenterItem.coord_type_name}:{" "}
                        {nimgcenterItem.coordinate.map((coord, index) => (
                            <Fragment key={index}>
                                {index !== 0 && <>, </>}
                                <span>
                                    ({coord.x}, {coord.y}, {coord.z})
                                </span>
                            </Fragment>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    renderBrainAreaTab() {
        return (
            <div className={styles.tabBrainArea}>
                <div className={styles.tabBrainAreaSource}>
                    <div>
                        <select value={this.state.brainAreaLevel} onChange={(e) => this.setState({ brainAreaLevel: parseInt(e.target.value, 10) })}>
                            {[1, 2, 3, 4, 5].map((value) => (
                                <option key={value} value={value}>
                                    Level {value}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <select ref={this.brainArea.source} className={styles.tabBrainAreaSelect} size={12} multiple>
                            {NimgsearchUtil.getBrainAreaNames(this.state.brainAreaLevel).map((value, index) => {
                                const id = NimgsearchUtil.getBrainAreaId(this.state.brainAreaLevel, index);
                                return (
                                    <option key={id} value={id}>
                                        {value}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className={styles.tabBrainAreaButtons}>
                    <p>
                        <input
                            type="button"
                            value="->"
                            onClick={() => {
                                const source = this.brainArea.source.current;
                                if (source !== null) {
                                    const brainAreaIds = this.state.brainAreaIds.concat();
                                    for (let ii = 0; ii < source.selectedOptions.length; ii++) {
                                        const value = parseInt(source.selectedOptions[ii].value, 10);
                                        if (!this.state.brainAreaIds.includes(value)) {
                                            brainAreaIds.push(value);
                                        }
                                    }
                                    if (this.state.brainAreaIds.length !== brainAreaIds.length || !this.state.brainAreaIds.every((value, index) => value === brainAreaIds[index])) {
                                        this.onUpdateBrainImage({ brainAreaIds });
                                    }
                                }
                            }}
                        />
                    </p>
                    <p>
                        <input
                            type="button"
                            value="<-"
                            onClick={() => {
                                const target = this.brainArea.target.current;
                                if (target !== null) {
                                    const ids: number[] = [];
                                    for (let ii = 0; ii < target.selectedOptions.length; ii++) {
                                        ids.push(parseInt(target.selectedOptions[ii].value, 10));
                                    }
                                    const brainAreaIds = this.state.brainAreaIds.filter((value) => !ids.includes(value));
                                    if (this.state.brainAreaIds.length !== brainAreaIds.length || !this.state.brainAreaIds.every((value, index) => value === brainAreaIds[index])) {
                                        this.onUpdateBrainImage({ brainAreaIds });
                                    }
                                }
                            }}
                        />
                    </p>
                </div>
                <div className={styles.tabBrainAreaList}>
                    <select ref={this.brainArea.target} className={styles.tabBrainAreaSelect} size={15} multiple>
                        {this.state.brainAreaIds.map((value) => (
                            <option key={value} value={value}>
                                {NimgsearchUtil.getBrainAreaName(value)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    }

    renderAdvancedSearchConditionTab() {
        const { lang } = this.props;
        return (
            <div className={styles.tabItemRetrieval}>
                <table className={styles.tabItemRetrievalTable}>
                    <tbody>
                        <tr>
                            <th>Search area</th>
                            <th>Condition</th>
                        </tr>
                        <tr>
                            <td>Advanced Search</td>
                            <td>
                                <div className="advancedSearchCondition">
                                    <select
                                        value={this.state.itemType}
                                        onChange={(e) => {
                                            this.state.query.clear();
                                            this.setState({ itemType: e.currentTarget.value });
                                        }}
                                    >
                                        <option value="">None</option>
                                        <option value="url">Url</option>
                                        <option value="paper">Paper</option>
                                        <option value="tool">Tool</option>
                                        <option value="model">Model</option>
                                        <option value="book">Book</option>
                                        <option value="data">Data</option>
                                        <option value="presentation">Presentation&amp;Doc</option>
                                    </select>
                                </div>
                                {this.state.itemType !== "" && (
                                    <>
                                        <div className="advancedSearchCondition">
                                            <span>
                                                <input type="radio" name="andOr" onChange={() => this.setState({ andOr: "and" })} checked={this.state.andOr === "and"} />
                                                and{" "}
                                            </span>
                                            <span>
                                                <input type="radio" name="andOr" onChange={() => this.setState({ andOr: "or" })} checked={this.state.andOr === "or"} />
                                                or{" "}
                                            </span>
                                        </div>
                                        <ItemType.AdvancedSearch key={this.state.itemType} type={"xnp" + this.state.itemType} lang={lang} query={this.state.query} />
                                    </>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderSearchResultTab() {
        const { lang } = this.props;
        const { sortCondition, searchResult } = this.state;
        const startNum = 1 + sortCondition.limit * (sortCondition.page - 1);
        let endNum = sortCondition.limit * sortCondition.page;
        if (endNum > searchResult.total) {
            endNum = searchResult.total;
        }
        const maxPage = Math.floor(searchResult.total / sortCondition.limit) + (searchResult.total % sortCondition.limit !== 0 ? 1 : 0);
        const link = (title: string, page: number, key: string) => {
            if (page === 0 || page === sortCondition.page) {
                return (
                    <span key={key} className={styles.pageNaviItem}>
                        {title}
                    </span>
                );
            }
            return (
                <a
                    key={key}
                    className={styles.pageNaviItem}
                    href="/"
                    onClick={(e) => {
                        e.preventDefault();
                        const nextSortCondition = { ...sortCondition };
                        nextSortCondition.page = page;
                        this.updateSearchResults(nextSortCondition, this.state.coordType);
                    }}
                >
                    {title}
                </a>
            );
        };
        let startPage = sortCondition.page - 4 > 1 ? sortCondition.page - 4 : 1;
        const endPage = startPage + 9 > maxPage ? maxPage : startPage + 9;
        if (endPage - startPage < 9) {
            startPage = endPage - 9 > 0 ? endPage - 9 : 1;
        }
        let pageLinks: JSX.Element[] = [];
        pageLinks.push(link("PREV", sortCondition.page - 1, "p"));
        for (let i = startPage; i <= endPage; i++) {
            pageLinks.push(link(i.toString(), i, i.toString()));
        }
        pageLinks.push(link("NEXT", sortCondition.page >= maxPage ? 0 : sortCondition.page + 1, "n"));
        return (
            <div className={styles.tabSearchResult}>
                {searchResult.total === 0 && <p>{Functions.mlang("[en]No search results found.[/en][ja]該当するアイテムはありません[/ja]", lang)}</p>}
                {searchResult.total > 0 && (
                    <div>
                        <div>
                            <strong>{Functions.mlang("[en][Results][/en][ja][検索結果][/ja]", lang)}</strong>{" "}
                            {searchResult.total > 1 && (
                                <>
                                    <strong>{startNum}</strong> - <strong>{endNum}</strong> {Functions.mlang("[en]of[/en][ja]件目を表示[/ja]", lang)}{" "}
                                </>
                            )}
                            {Functions.mlang("[ja](全[/ja]", lang)}
                            <strong>{searchResult.total}</strong>
                            {Functions.mlang("[en] Items[/en][ja]件)[/ja]", lang)}
                        </div>
                        {maxPage > 1 && <div className={styles.tabSearchResultPageNavi}>{pageLinks}</div>}
                        <div className={styles.tabSearchResultSortCondition}>
                            <span>
                                {Functions.mlang("[en]Results per page[/en][ja]表示件数[/ja]", lang)}{" "}
                                <select
                                    value={sortCondition.limit}
                                    onChange={(e) => {
                                        const nextSortCondition = { ...sortCondition };
                                        nextSortCondition.limit = parseInt(e.target.value, 10) as SortConditionLimit;
                                        nextSortCondition.page = 1;
                                        this.updateSearchResults(nextSortCondition, this.state.coordType);
                                    }}
                                >
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </span>
                            <span>
                                Order by{" "}
                                <select
                                    value={this.state.sortCondition.orderBy}
                                    onChange={(e) => {
                                        const nextSortCondition = { ...sortCondition };
                                        nextSortCondition.orderBy = e.target.value as SortConditionOrderBy;
                                        this.updateSearchResults(nextSortCondition, this.state.coordType);
                                    }}
                                >
                                    <option value="title">{Functions.mlang("[en]Title[/en][ja]タイトル[/ja]", lang)}</option>
                                    <option value="doi">ID</option>
                                    <option value="last_update_date">{Functions.mlang("[en]Last Modified Date[/en][ja]最終更新日[/ja]", lang)}</option>
                                    <option value="creation_date">{Functions.mlang("[en]Created Date[/en][ja]作成日[/ja]", lang)}</option>
                                    <option value="publication_date">{Functions.mlang("[en]Date[/en][ja]日付[/ja]", lang)}</option>
                                </select>
                                <span>
                                    {sortCondition.orderDir === SortConditionOrderDir.DESC && (
                                        <>
                                            &#9660;{" "}
                                            <a
                                                href="/"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const nextSortCondition = { ...sortCondition };
                                                    nextSortCondition.orderDir = SortConditionOrderDir.ASC;
                                                    this.updateSearchResults(nextSortCondition, this.state.coordType);
                                                }}
                                            >
                                                &#9650;
                                            </a>
                                        </>
                                    )}
                                    {sortCondition.orderDir === SortConditionOrderDir.ASC && (
                                        <>
                                            <a
                                                href="/"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const nextSortCondition = { ...sortCondition };
                                                    nextSortCondition.orderDir = SortConditionOrderDir.DESC;
                                                    this.updateSearchResults(nextSortCondition, this.state.coordType);
                                                }}
                                            >
                                                &#9660;
                                            </a>{" "}
                                            &#9650;
                                        </>
                                    )}
                                </span>
                            </span>
                        </div>
                        <div className={styles.tabSearchResultItems}>
                            <ol start={startNum}>
                                {searchResult.data.map((item) => {
                                    return <li key={item.item_id}>{this.renderListItem(item)}</li>;
                                })}
                            </ol>
                        </div>
                        {maxPage > 1 && <div className={styles.tabSearchResultPageNavi}>{pageLinks}</div>}
                    </div>
                )}
            </div>
        );
    }

    renderMyItemsTab() {
        const { lang } = this.props;
        return (
            <div className={styles.tabMyItems}>
                <h3>{Functions.mlang("[en]The items which is display a spot[/en][ja]スポット表示中のアイテム[/ja]", lang)}</h3>
                {this.state.myItems.length > 0 ? (
                    <ol>
                        {this.state.myItems.map((item) => (
                            <li key={item.item_id}>{this.renderListItem(item)}</li>
                        ))}
                    </ol>
                ) : (
                    <p>{Functions.mlang("[en]No items found.[/en][ja]表示中のアイテムはありません[/ja]", lang)}</p>
                )}
            </div>
        );
    }

    render() {
        const { lang } = this.props;
        return (
            <div className="nimgsearch">
                <Helmet>
                    <title>NIMG Search - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <div className={styles.container}>
                    <div className={styles.headerPanel}>
                        <div className={styles.headerMessage}>{Functions.mlang("[en]You can confirm the brain area and search the article.[/en][ja]脳部位の確認や論文の検索ができるページです[/ja]", lang)}</div>
                        <div className={styles.headerLinks}>
                            <span onClick={() => this.setState({ showSettings: !this.state.showSettings, showHelp: false })}>{Functions.mlang("[en][Setting][/en][ja]表示設定[/ja]", lang)}</span>
                            <span onClick={() => this.setState({ showSettings: false, showHelp: !this.state.showHelp })}>{Functions.mlang("[en][Help][/en][ja]ヘルプ[/ja]", lang)}</span>
                        </div>
                    </div>
                    <div className={`${styles.mainPanel} ${this.state.imageSize}`}>
                        <div className={`${styles.brainPanel} ${this.state.imageSize}`}>
                            <div className={`${styles.brainViewY} ${this.state.imageSize}`}>
                                <canvas ref={this.brainImage.y} onClick={() => this.onClickBrainImage("y")} onMouseMove={(e) => this.onMouseMoveBrainImage(e, "y")} />
                            </div>
                            <div className={`${styles.brainViewX} ${this.state.imageSize}`}>
                                <canvas ref={this.brainImage.x} onClick={() => this.onClickBrainImage("x")} onMouseMove={(e) => this.onMouseMoveBrainImage(e, "x")} />
                            </div>
                            <div className={`${styles.brainViewZ} ${this.state.imageSize}`}>
                                <canvas ref={this.brainImage.z} onClick={() => this.onClickBrainImage("z")} onMouseMove={(e) => this.onMouseMoveBrainImage(e, "z")} />
                            </div>
                            <div className={`${styles.coordView} ${this.state.imageSize}`}>
                                <span>{`(${this.state.pointer.x}, ${this.state.pointer.y}, ${this.state.pointer.z})`}</span>
                            </div>
                            <div className={`${styles.searchPanel} ${this.state.imageSize}`}>
                                <div className={styles.searchConditions}>
                                    <span>
                                        x <input type="text" size={3} value={this.state.formCoord.x} onChange={(e) => this.onChnageCoordinate(e, "x")} />
                                    </span>
                                    <span>
                                        y <input type="text" size={3} value={this.state.formCoord.y} onChange={(e) => this.onChnageCoordinate(e, "y")} />
                                    </span>
                                    <span>
                                        z <input type="text" size={3} value={this.state.formCoord.z} onChange={(e) => this.onChnageCoordinate(e, "z")} />
                                    </span>
                                    <span>
                                        <input type="button" value={Functions.mlang("[en]Update[/en][ja]更新[/ja]", lang)} onClick={this.onClickUpdateButton} />
                                    </span>
                                    <span>
                                        <input type="button" value={Functions.mlang("[en]Reset[/en][ja]戻す[/ja]", lang)} onClick={() => this.onUpdateBrainImage({ coord: this.defaultCoord })} />
                                    </span>
                                </div>
                                <div className={styles.searchConditions}>
                                    <span>
                                        <input type="radio" name="radius" onChange={() => this.setState({ formRadiusRadio: "2" })} checked={this.state.formRadiusRadio === "2"} />
                                        2mm
                                    </span>
                                    <span>
                                        <input type="radio" name="radius" onChange={() => this.setState({ formRadiusRadio: "6" })} checked={this.state.formRadiusRadio === "6"} />
                                        6mm
                                    </span>
                                    <span>
                                        <input type="radio" name="radius" onChange={() => this.setState({ formRadiusRadio: "" })} checked={this.state.formRadiusRadio === ""} />
                                        <select value={this.state.formRadiusSelect} onChange={(e) => this.setState({ formRadiusSelect: e.target.value })}>
                                            {[2, 4, 6, 8, 10, 12].map((value) => (
                                                <option key={value} value={value}>
                                                    {value}
                                                </option>
                                            ))}
                                        </select>
                                        mm
                                    </span>
                                </div>
                                <div className={styles.searchConditions}>
                                    <span>
                                        <input type="radio" name="coordType" onChange={() => this.onUpdateBrainImage({ formCoordType: "mni" })} checked={this.state.formCoordType === "mni"} />
                                        MNI
                                    </span>
                                    <span>
                                        <input type="radio" name="coordType" onChange={() => this.onUpdateBrainImage({ formCoordType: "talairach" })} checked={this.state.formCoordType === "talairach"} />
                                        Talairach
                                    </span>
                                    <span>
                                        <input type="radio" name="coordType" onChange={() => this.onUpdateBrainImage({ formCoordType: "unspecified" })} checked={this.state.formCoordType === "unspecified"} />
                                        Not Specified
                                    </span>
                                </div>
                                <div className={styles.searchConditions}>
                                    <span>
                                        <input type="radio" name="paperType" onChange={() => this.setState({ formPaperType: "all" })} checked={this.state.formPaperType === "all"} />
                                        All
                                    </span>
                                    <span>
                                        <input type="radio" name="paperType" onChange={() => this.setState({ formPaperType: "normal" })} checked={this.state.formPaperType === "normal"} />
                                        Normal
                                    </span>
                                    <span>
                                        <input type="radio" name="paperType" onChange={() => this.setState({ formPaperType: "patients" })} checked={this.state.formPaperType === "patients"} />
                                        Patients
                                    </span>
                                </div>
                                <div className={styles.searchButton}>
                                    <span>
                                        <input type="button" value={Functions.mlang("[en]Search Related-papers[/en][ja]　　　検索　　　[/ja]", lang)} onClick={() => this.updateSearchResults(this.defaultSortCondition, this.state.formCoordType)} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {(this.state.showSettings || this.state.showHelp) && (
                            <div className={styles.popupPanel}>
                                {this.state.showSettings && (
                                    <div className={styles.settings}>
                                        <dl>
                                            <dt>{Functions.mlang("[en]Image-Size[/en][ja]画像サイズ[/ja]", lang)}</dt>
                                            <dd>
                                                <span>
                                                    <input type="radio" name="imageSize" onChange={() => this.onUpdateBrainImage({ imageSize: "large" })} checked={this.state.imageSize === "large"} />
                                                    {Functions.mlang("[en]Large[/en][ja]大[/ja]", lang)}
                                                </span>
                                                <span>
                                                    <input type="radio" name="imageSize" onChange={() => this.onUpdateBrainImage({ imageSize: "medium" })} checked={this.state.imageSize === "medium"} />
                                                    {Functions.mlang("[en]Medium[/en][ja]中[/ja]", lang)}
                                                </span>
                                                <span>
                                                    <input type="radio" name="imageSize" onChange={() => this.onUpdateBrainImage({ imageSize: "small" })} checked={this.state.imageSize === "small"} />
                                                    {Functions.mlang("[en]Small[/en][ja]小[/ja]", lang)}
                                                </span>
                                            </dd>
                                            <dt>{Functions.mlang("[en]On/Off[/en][ja]表示切替[/ja]", lang)}</dt>
                                            <dd>
                                                <span>
                                                    <input type="checkbox" onChange={() => this.onUpdateBrainImage({ showLines: !this.state.showLines })} checked={this.state.showLines} />
                                                    {Functions.mlang("[en]Show Lines[/en][ja]ラインを表示[/ja]", lang)}
                                                </span>
                                                <span>
                                                    <input type="checkbox" onChange={() => this.onUpdateBrainImage({ showSpots: !this.state.showSpots })} checked={this.state.showSpots} />
                                                    {Functions.mlang("[en]Show Spots[/en][ja]スポットを表示[/ja]", lang)}
                                                </span>
                                            </dd>
                                        </dl>
                                    </div>
                                )}
                                {this.state.showHelp && (
                                    <div className={styles.help}>
                                        <div>
                                            <span>{Functions.mlang("[en]Demo of usage[/en][ja]使い方のデモ[/ja]", lang)}</span>
                                        </div>
                                        <div>
                                            <video width={240} height={210} poster="./help/demo.png" controls>
                                                <source src="./help/demo.mp4" type="video/mp4" />
                                                <source src="./help/demo.webm" type="video/webm" />
                                            </video>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={styles.footerPanel}>
                        <ul className={styles.navTabs}>
                            <li className={this.state.tab === 0 ? "actived" : "normal"} onClick={() => this.setState({ tab: 0 })}>
                                {Functions.mlang("[en]Brain area[/en][ja]脳部位[/ja]", lang)}
                            </li>
                            <li className={this.state.tab === 1 ? "actived" : "normal"} onClick={() => this.setState({ tab: 1 })}>
                                {Functions.mlang("[en]Item retrieval[/en][ja]アイテム検索[/ja]", lang)}
                            </li>
                            <li className={this.state.tab === 2 ? "actived" : "normal"} onClick={() => this.setState({ tab: 2 })}>
                                {Functions.mlang("[en]Result of retrieval[/en][ja]検索結果[/ja]", lang)}
                            </li>
                            <li className={this.state.tab === 3 ? "actived" : "normal"} onClick={() => this.setState({ tab: 3 })}>
                                {Functions.mlang("[en]My items[/en][ja]マイアイテム[/ja]", lang)}
                            </li>
                        </ul>
                        <div className={styles.tabContent}>
                            {this.state.tab === 0 && this.renderBrainAreaTab()}
                            {this.state.tab === 1 && this.renderAdvancedSearchConditionTab()}
                            {this.state.tab === 2 && this.renderSearchResultTab()}
                            {this.state.tab === 3 && this.renderMyItemsTab()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Nimgsearch;
