import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MultiLang } from "../../config";
import mlangEnglish from "../assets/images/mlang_english.gif";
import mlangJapanese from "../assets/images/mlang_japanese.gif";

interface Props {
    lang: MultiLang;
    className?: string;
    image?: string;
}

const langResources = {
    en: { image: mlangEnglish, title: "English" },
    ja: { image: mlangJapanese, title: "Japanese" },
};

const LangFlag: React.FC<Props> = (props: Props) => {
    const { lang, className, image } = props;
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const flagLang = lang === "en" ? "ja" : "en";
    params.set("ml_lang", flagLang);
    const url = location.pathname + "?" + params.toString();
    const imageSrc = image || langResources[flagLang].image;
    return (
        <Link className={className} to={url}>
            <img src={imageSrc} alt={langResources[flagLang].title} title={langResources[flagLang].title} />
        </Link>
    );
};

export default LangFlag;
