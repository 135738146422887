import iconFile from "../../assets/images/icon_tool.gif";
import { ItemToolSubTypes } from "../../lib/ItemUtil";
import TopBase, { TopBaseProps } from "../lib/TopBase";

class ToolTop extends TopBase {
    constructor(props: TopBaseProps) {
        super(props);
        this.type = "tool";
        this.label = "Tool";
        this.icon = iconFile;
        this.description = "[en]Tool programs/scripts.[/en][ja]データ解析用プログラム/スクリプト[/ja]";
        this.subTypes = ItemToolSubTypes;
    }
}

export default ToolTop;
