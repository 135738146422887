import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import Config, { MultiLang } from "../config";
import Page from "../custom/Page";

const AppMain: React.FC = () => {
    const [cookies, setCookie] = useCookies();
    const [lang, setLang] = useState<MultiLang>(["en", "ja"].includes(cookies.ml_lang) ? cookies.ml_lang : "en");
    const location = useLocation();

    useEffect(() => {
        if (Config.GOOGLE_ANALYTICS_TRACKING_ID !== "") {
            ReactGA.send("pageview");
        }
        const params = new URLSearchParams(location.search);
        const ml_lang = params.get("ml_lang");
        if (ml_lang != null && ["en", "ja"].includes(ml_lang)) {
            if (cookies.ml_lang !== ml_lang) {
                setCookie("ml_lang", ml_lang);
            }
            if (lang !== ml_lang) {
                setLang(ml_lang as MultiLang);
            }
        }
        window.scrollTo(0, 0);
    }, [cookies, setCookie, lang, location]);

    return <Page lang={lang} />;
};

const AppRoot: React.FC = () => {
    if (Config.GOOGLE_ANALYTICS_TRACKING_ID !== "") {
        ReactGA.initialize(Config.GOOGLE_ANALYTICS_TRACKING_ID);
    }

    return <AppMain />;
};

export default AppRoot;
