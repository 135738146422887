import React from "react";
import XoopsCode from "../../../../common/lib/XoopsCode";
import { MultiLang } from "../../../../config";

interface Props {
    lang: MultiLang;
    description: string;
    className?: string;
}

const Description: React.FC<Props> = (props: Props) => {
    const { lang, description, className } = props;
    const textarea = <XoopsCode lang={lang} text={description} dobr={true} />;
    const name = typeof className === "undefined" ? "description" : className;
    return <div className={name}>{textarea}</div>;
};

export default Description;
