import React from "react";
import { MultiLang } from "../../../config";
import { ItemModelSubType, ItemModelSubTypes } from "../../lib/ItemUtil";

interface ModelTypeProps {
    lang: MultiLang;
    type: ItemModelSubType;
}

const ModelType: React.FC<ModelTypeProps> = (props: ModelTypeProps) => {
    const { type } = props;
    const subtype = ItemModelSubTypes.find((value) => {
        return value.type === type;
    });
    if (typeof subtype === "undefined") {
        return null;
    }
    return <span>{subtype.label}</span>;
};

const ModelUtil = {
    ModelType,
};

export default ModelUtil;
