import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import BrainExplorerIndex from "../brainexplorer/BrainExplorer";
import XoopsPathRedirect from "../common/XoopsPathRedirect";
import { MultiLang } from "../config";
import CreditsMenu from "../credits/blocks/CreditsMenu";
import Credits from "../credits/Credits";
import NimgcenterUtilities from "../nimgcenter/NimgenterUtilities";
import Nimgdocs from "../nimgdocs/Nimgdocs";
import Nimgsearch from "../nimgsearch/Nimgsearch";
import IndexTree from "../xoonips/blocks/IndexTree";
import Ranking from "../xoonips/blocks/Rankings";
import RecentContents from "../xoonips/blocks/RecentContents";
import Search2 from "../xoonips/blocks/Search";
import Xoonips from "../xoonips/Xoonips";
import BulletinBoard from "./blocks/BulletinBoard";
import HowToUseLinks from "./blocks/HowToUseLinks";
import HowToUseVideo from "./blocks/HowToUseVideo";
import Information from "./blocks/Information";
import Questionnaire from "./blocks/Questionnaire";
import RecentStatus from "./blocks/RecentStatus";
import Footer from "./Footer";
import Header from "./Header";

interface Props {
    lang: MultiLang;
}

const MainContent: React.FC<Props> = (props: Props) => {
    return (
        <div id="main">
            <div className="mainContent">
                <Outlet />
            </div>
        </div>
    );
};

const CenterColumn: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <div id="centercolumn">
            <Outlet />
            <Routes>
                <Route path="/*" element={<MainContent lang={lang} />}>
                    <Route path="modules/credits/*" element={<Credits lang={lang} />} />
                    <Route path="modules/xoonips/*" element={<Xoonips lang={lang} />} />
                    <Route path="modules/nimgdocs/*" element={<Nimgdocs lang={lang} />} />
                    <Route path="modules/nimgsearch/*" element={<Nimgsearch lang={lang} />} />
                    <Route path="modules/xnpnimgcenter/utilities/*" element={<NimgcenterUtilities lang={lang} />} />
                    <Route path="modules/brainexplorer/*" element={<BrainExplorerIndex lang={lang} />} />
                    <Route path="*" element={<XoopsPathRedirect lang={lang} />} />
                </Route>
            </Routes>
        </div>
    );
};

const CenterColumnBlock: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <>
            <div id="centerCcolumn">
                <Information lang={lang} />
                <BulletinBoard lang={lang} />
                <HowToUseVideo lang={lang} />
                <HowToUseLinks lang={lang} />
                <Questionnaire lang={lang} />
                <RecentStatus lang={lang} />
            </div>
            <div className="col2">
                <div id="centerLcolumn">
                    <div className="block">
                        <div className="blockTitle">XooNIps Ranking</div>
                        <div className="blockContent">
                            <Ranking lang={lang} />
                        </div>
                    </div>
                </div>
                <div id="centerRcolumn">
                    <div className="block">
                        <div className="blockTitle">XooNIps Update</div>
                        <div className="blockContent">
                            <RecentContents lang={lang} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const LeftColumn: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <div id="leftcolumn">
            <Outlet />
            <div className="block">
                <div className="blockTitle">Index Tree</div>
                <div className="blockContent">
                    <IndexTree lang={lang} />
                </div>
            </div>
            <div className="block">
                <div className="blockTitle">Site Information</div>
                <div className="blockContent">
                    <CreditsMenu lang={lang} />
                </div>
            </div>
        </div>
    );
};

const XoopsSearchBlock: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <div className="block">
            <div className="blockTitle">Item Search</div>
            <div className="blockContent">
                <Search2 lang={lang} />
            </div>
        </div>
    );
};

const Page: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <div id="page">
            <Header lang={lang} />
            <div className="col2">
                <Routes>
                    <Route path="/*" element={<CenterColumn lang={lang} />}>
                        <Route index element={<CenterColumnBlock lang={lang} />} />
                    </Route>
                </Routes>
                <Routes>
                    <Route path="/*" element={<LeftColumn lang={lang} />}>
                        <Route path="modules/xoonips/*" element={<XoopsSearchBlock lang={lang} />} />
                    </Route>
                </Routes>
            </div>
            <Footer lang={lang} />
        </div>
    );
};

export default Page;
