import React, { useState } from "react";
import { Modal } from "react-overlays";
import { Link, useLocation } from "react-router-dom";
import { MultiLang } from "../config";
import Functions from "../functions";
import imageLogoAist from "./assets/images/logo_aist.png";
import imageNaviHome from "./assets/images/navi_home.png";
import imageNaviInfo from "./assets/images/navi_info.png";
import styles from "./BrainExplorerIndex.module.css";

interface Props {
    lang: MultiLang;
    type: "jm" | "hu";
}

const title = {
    en: "Brain Atlas Database of Japanese Monkey for WWW.",
    ja: "脳画像データベース",
};
const description = {
    en: "Neuroscience researches for elucidation of brain functions require electrophysiological, pharmacological or anatomical operations into the brain. However, no brain atlas of a Japanese monkey, which is used widely in these experiments, was made except detail maps of the brain stem. We have made magnetic resonance images as well as histological sections of a Japanese monkey brain. For the purpose of the practical and efficient use of these images, we will construct computer database for WWW, in which we can display, magnify, and compare these images by easy mouse operations.",
    ja: "脳の優れた機能を解明し工学に利用するために、ヒトに近い機能を持った霊長類の神経科学的研究が行われている。脳機能の解明のためには脳の内部に対してさまざまな処置を施す必要がある。例えば、脳の特定の位置に記録、刺激電極を刺入する、薬剤を注入する、局部的に破壊するなどの場合がある。これらの処置に必要な資料は、脳の組織標本である。しかし、研究で良く使われるニホンザルの脳の組織標本は脳幹部を除いては今まで利用できる資料はなかった。そこで脳のMRI（磁気共鳴映像法）連続画像を撮ると共に、高次機能を調べるうえで重要な脳皮質全体を含む組織標本の連続切片を作成した。実験上また文献を読むときなどの資料として有効かつ簡便に活用するために、それらの画像をデジタル化し、マウスの操作のみで簡便に参照できるデータベースを構築し、WWW上で公開する。",
};

const BrainExplorerIndex: React.FC<Props> = (props: Props) => {
    const { lang, type } = props;
    const [showInfo, setShowInfo] = useState(false);
    const location = useLocation();
    const parentUrl = location.pathname.substring(0, location.pathname.lastIndexOf("/"));

    const getUrl = (type: string, lang: MultiLang) => {
        const label = { en: "eng", ja: "jpn" };
        return `${parentUrl}/index_${type}_${label[lang]}.php`;
    };

    const renderShowInfo = () => {
        return (
            <div>
                <h1>{title[lang]}</h1>
                <hr />
                <p>{description[lang]}</p>
                <hr />
            </div>
        );
    };

    const renderBrainExplorerContent = () => {
        const url = `./${type === "hu" ? "human" : "jmonkey"}.html?LANG=${lang === "en" ? "ENG" : "JPN"}`;
        return <iframe src={url} title="image"></iframe>;
    };

    return (
        <Modal show={true}>
            <div className={styles.panel}>
                <div className={styles.panelNavi}>
                    <div className={styles.panelButtons}>
                        <Link to="/">
                            <img src={imageNaviHome} alt="HOME" />
                        </Link>
                        &nbsp;
                        <a
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowInfo(true);
                            }}
                        >
                            <img src={imageNaviInfo} alt="INFO" />
                        </a>
                    </div>
                    <div className={styles.panelMenu}>
                        <h3>Brain Explorer [MRI]</h3>
                        <ul>
                            <li>
                                <Link to={getUrl("jm", lang)} onClick={() => setShowInfo(false)}>
                                    {Functions.mlang("[en]Japanese Monkey[/en][ja]ニホンザル[/ja]", lang)}
                                    <br />
                                    <em>(Macaca fuscata)</em>
                                    <br />
                                    {Functions.mlang("[en]Developmental Data[/en][ja]発達の様子[/ja]", lang)}
                                </Link>
                            </li>
                            <li>
                                <Link to={getUrl("hu", lang)} onClick={() => setShowInfo(false)}>
                                    {Functions.mlang("[en]Human[/en][ja]ヒト[/ja]", lang)}
                                    <br />
                                    <em>(Homo sapiens)</em>
                                </Link>
                            </li>
                        </ul>
                        <hr />
                        <ul>
                            <li>
                                <Link to={getUrl(type, lang === "en" ? "ja" : "en")}>{Functions.mlang("[en]Japanese page.[/en][ja]English page.[/ja]", lang)}</Link>
                            </li>
                        </ul>
                        <hr />
                        <div className={styles.panelMenuAistLink}>
                            <a href="http://www.aist.go.jp/" target="_blank" rel="noopener noreferrer">
                                <img src={imageLogoAist} alt="AIST" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.panelContent}>{showInfo ? renderShowInfo() : renderBrainExplorerContent()}</div>
            </div>
        </Modal>
    );
};

export default BrainExplorerIndex;
