import React from "react";
import { MultiLang } from "../../../../config";
import Functions from "../../../../functions";

interface Props {
    lang: MultiLang;
    uname: string;
    name: string;
}

const Contributer: React.FC<Props> = (props: Props) => {
    const { lang, name, uname } = props;
    const unsubscribed = "([en]Unsubscribed User[/en][ja]退会済みユーザ[/ja])";
    const label = uname === "" ? unsubscribed : name === "" ? uname : name + " (" + uname + ")";
    return <span>{Functions.mlang(label, lang)}</span>;
};

export default Contributer;
