import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Loading from "../common/lib/Loading";
import NoticeSiteHasBeenArchived from "../common/lib/NoticeSiteHasBeenArchived";
import PageNotFound from "../common/lib/PageNotFound";
import { MultiLang } from "../config";
import Functions from "../functions";
import CreditsUtils, { CreditsIndexData } from "./lib/CreditsUtils";

interface Props {
    lang: MultiLang;
}

const CreditsIndex: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const [loading, setLoading] = useState<boolean>(true);
    const [index, setIndex] = useState<CreditsIndexData | null>(null);

    useEffect(() => {
        const updatePage = async () => {
            const index = await CreditsUtils.getIndex();
            setLoading(false);
            setIndex(index);
        };
        updatePage();
    }, [lang]);

    if (loading) {
        return <Loading />;
    }
    if (null === index) {
        return <PageNotFound lang={lang} />;
    }
    const mtitle = Functions.mlang(index.name, lang);
    return (
        <>
            <Helmet>
                <title>
                    {mtitle} - {Functions.siteTitle(lang)}
                </title>
            </Helmet>
            <h3>{mtitle}</h3>
            <NoticeSiteHasBeenArchived lang={lang} />
            <ul>
                {index.pages.map((page) => {
                    const title = Functions.mlang(page.title, lang);
                    const url = CreditsUtils.getPageUrl(page.id);
                    return (
                        <li key={page.id}>
                            <Link to={url}>{title}</Link>
                        </li>
                    );
                })}
            </ul>
        </>
    );
};
export default CreditsIndex;
