import AdvancedSearchBase, { AdvancedSearchBaseProps } from "../lib/AdvancedSearchBase";

class UrlAdvancedSearch extends AdvancedSearchBase {
    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = "url";
        this.title = "Url";
        const keys = ["title", "keyword", "description", "doi", "url"];
        const show = props.query.hasType(this.type);
        const values: any = {};
        keys.forEach((key) => (values[key] = props.query.get(this.type, key)));
        this.state = { show, values };
    }

    getRows() {
        const rows = [
            { label: "[en]Title[/en][ja]タイトル[/ja]", value: this.renderFieldInputText("title", 50) },
            { label: "[en]Free Keywords[/en][ja]フリーキーワード[/ja]", value: this.renderFieldInputText("keyword", 50) },
            { label: "[en]Description[/en][ja]概要[/ja]", value: this.renderFieldInputText("description", 50) },
            { label: "ID", value: this.renderFieldInputText("doi", 50) },
            { label: "URL", value: this.renderFieldInputText("url", 50) },
        ];
        return rows;
    }
}

export default UrlAdvancedSearch;
