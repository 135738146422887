import React from "react";
import Spinner from "react-spinner-material";

const Loading: React.FC = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", margin: "100px 0" }}>
            <Spinner radius={60} color={"#cccccc"} stroke={8} visible={true} />
        </div>
    );
};

export default Loading;
