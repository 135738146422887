import { ItemModelSubTypes } from "../../lib/ItemUtil";
import AdvancedSearchBase, { AdvancedSearchBaseProps } from "../lib/AdvancedSearchBase";

class ModelAdvancedSearch extends AdvancedSearchBase {
    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = "model";
        this.title = "Model";
        const keys = ["title", "keyword", "description", "doi", "model_type", "creator", "file.preview.caption", "file.model_data.original_file_name"];
        const show = props.query.hasType(this.type);
        const values: any = {};
        keys.forEach((key) => (values[key] = props.query.get(this.type, key)));
        this.state = { show, values };
    }

    getRows() {
        const rows = [
            { label: "[en]Title[/en][ja]タイトル[/ja]", value: this.renderFieldInputText("title", 50) },
            { label: "[en]Free Keywords[/en][ja]フリーキーワード[/ja]", value: this.renderFieldInputText("keyword", 50) },
            { label: "[en]Description[/en][ja]概要[/ja]", value: this.renderFieldInputText("description", 50) },
            { label: "ID", value: this.renderFieldInputText("doi", 50) },
            { label: "[en]Model Type[/en][ja]モデルタイプ[/ja]", value: this.renderFieldSelect("model_type", ItemModelSubTypes) },
            { label: "[en]Creator[/en][ja]作成者[/ja]", value: this.renderFieldInputText("creator", 50) },
            { label: "[en]Caption[/en][ja]キャプション[/ja]", value: this.renderFieldInputText("file.preview.caption", 50) },
            { label: "[en]Model File[/en][ja]モデルファイル[/ja]", value: this.renderFieldInputText("file.model_data.original_file_name", 50) },
        ];
        return rows;
    }
}

export default ModelAdvancedSearch;
