import PaperAdvancedSearch from "./PaperAdvancedSearch";
import PaperDetail from "./PaperDetail";
import PaperList from "./PaperList";
import PaperTop from "./PaperTop";

const ItemTypePaper = {
    Top: PaperTop,
    List: PaperList,
    Detail: PaperDetail,
    AdvancedSearch: PaperAdvancedSearch,
};

export default ItemTypePaper;
