import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { MultiLang } from "../../../../config";
import Functions from "../../../../functions";
import ItemUtil, { ItemBasicFile } from "../../../lib/ItemUtil";
import styles from "./Preview.module.css";

interface Props {
    lang: MultiLang;
    file: ItemBasicFile[];
}

const Preview: React.FC<Props> = (props: Props) => {
    const { lang, file } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [imageIndex, setImageIndex] = useState<number>(0);

    const imageUrls: string[] = [];
    const previews = file
        .filter((value) => {
            return value.file_type_name === "preview";
        })
        .map((value, idx) => {
            const fileUrl = ItemUtil.getFileUrl(value);
            const previewUrl = ItemUtil.getPreviewFileUrl(value);
            const caption = Functions.mlang(value.caption, lang);
            imageUrls.push(fileUrl);
            return (
                <figure key={value.file_id} className={styles.preview}>
                    <a
                        href={fileUrl}
                        download={value.original_file_name}
                        onClick={(e) => {
                            e.preventDefault();
                            setIsOpen(true);
                            setImageIndex(idx);
                        }}
                    >
                        <img src={previewUrl} alt={caption} />
                    </a>
                    <figcaption>{caption}</figcaption>
                </figure>
            );
        });
    if (previews.length === 0) {
        return null;
    }
    return (
        <>
            <div className={styles.previewBox}>{previews}</div>
            {isOpen && <Lightbox mainSrc={imageUrls[imageIndex]} nextSrc={imageUrls[(imageIndex + 1) % previews.length]} prevSrc={imageUrls[(imageIndex + previews.length - 1) % previews.length]} onCloseRequest={() => setIsOpen(false)} onMovePrevRequest={() => setImageIndex((imageIndex + previews.length - 1) % previews.length)} onMoveNextRequest={() => setImageIndex((imageIndex + 1) % previews.length)} />}
        </>
    );
};

export default Preview;
