import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Config, { MultiLang } from "../../config";
import Functions from "../../functions";
import ItemUtil from "../lib/ItemUtil";

interface Props {
    lang: MultiLang;
}

const Search: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const [type, setType] = useState("all");
    const [keyword, setKeyword] = useState("");
    const [pathname, setPathname] = useState(location.pathname);

    const options = [
        { value: "all", label: "[en]All[/en][ja]全て[/ja]" },
        { value: "basic", label: "[en]Title & Keyword[/en][ja]タイトル＆キーワード[/ja]" },
    ];
    Config.XOONIPS_ITEMTYPES.forEach((type) => {
        options.push({ value: `xnp${type}`, label: Functions.pascalCase(type) });
    });

    useEffect(() => {
        const pathnameNext = location.pathname;
        if (pathname !== pathnameNext) {
            if (pathnameNext === "/modules/xoonips/itemselect.php") {
                const query = ItemUtil.getSearchKeywordByQuery(location.search);
                setKeyword(query.keyword);
                setType(query.type);
                setPathname(pathnameNext);
            }
        }
    }, [location, pathname]);

    const handleChangeKeyword = (event: ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value.trim());
    };

    const handleChangeType = (event: ChangeEvent<HTMLSelectElement>) => {
        setType(event.target.value);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const url = ItemUtil.getSearchByKeywordUrl(type, keyword);
        navigate(url);
    };
    return (
        <form onSubmit={handleSubmit}>
            <input style={{ width: "170px" }} type="text" value={keyword} onChange={handleChangeKeyword} />
            &nbsp;&nbsp;
            <select value={type} onChange={handleChangeType}>
                {options.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>
                            {Functions.mlang(option.label, lang)}
                        </option>
                    );
                })}
            </select>
            <br />
            <input className="formButton" type="submit" value="Search" />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/modules/xoonips/advanced_search.php">{Functions.mlang("[en]Advanced[/en][ja]詳細検索[/ja]", lang)}</Link>
        </form>
    );
};

export default Search;
