import React from "react";
import Functions from "../../../functions";
import { ItemNimgcenterBrainCoordinateBaseItemTyps, ItemSubTypes } from "../../lib/ItemUtil";
import AdvancedSearchBase, { AdvancedSearchBaseProps } from "../lib/AdvancedSearchBase";

type CoordinateType = "M" | "T" | "U";
const CoordinateTypes: ItemSubTypes<CoordinateType> = [
    { type: "M", label: "MNI" },
    { type: "T", label: "Talairach" },
    { type: "U", label: "Unclear" },
];

class NimgcenterAdvancedSearch extends AdvancedSearchBase {
    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = "nimgcenter";
        this.title = "BrainCoodinates";
        this.state.values["baseitem_id"] = "";
        this.state.values["base_type_name"] = "";
        this.state.values["cogfunc"] = "";
        this.state.values["areaname"] = "";
        this.state.values["reference"] = "";
        this.state.values["coord_type"] = "";
        this.state.values["tx"] = "";
        this.state.values["ty"] = "";
        this.state.values["tz"] = "";
        this.state.values["coord"] = "";
        this.state.values["radius"] = "0";
    }

    renderFieldBrainAreas() {
        const key = "areaname";
        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            this.updateField(key, e.target.value);
        };
        const onDoubleClick = (e: React.MouseEvent<HTMLSelectElement, MouseEvent>) => {
            const option = e.target as HTMLOptionElement;
            let value = this.state.values[key];
            if (value.length !== 0) {
                value += " ";
            }
            value += '"' + option.innerText.replace('"', "\\") + '"';
            this.updateField(key, value);
        };
        return (
            <>
                <input className="fieldInputText" type="text" value={this.state.values[key]} size={50} onChange={onChange} />
                <br />
                <select size={3} onDoubleClick={onDoubleClick}>
                    <option>Unidentified</option>
                    <option>Inter-Hemispheric</option>
                    <option>Left Cerebrum</option>
                    <option>Right Cerebrum</option>
                    <option>Right Cerebellum</option>
                    <option>Right Brainstem</option>
                    <option>Left Brainstem</option>
                    <option>Left Cerebellum</option>
                </select>
            </>
        );
    }

    renderFieldBrainCoordinates() {
        const { lang } = this.props;
        const onChangeTx = (e: React.ChangeEvent<HTMLInputElement>) => {
            this.updateField("tx", e.target.value);
        };
        const onChangeTy = (e: React.ChangeEvent<HTMLInputElement>) => {
            this.updateField("ty", e.target.value);
        };
        const onChangeTz = (e: React.ChangeEvent<HTMLInputElement>) => {
            this.updateField("tz", e.target.value);
        };
        const onChangeCoord = (e: React.ChangeEvent<HTMLInputElement>) => {
            this.updateField("coord", e.target.value);
        };
        const onChangeRadius = (e: React.ChangeEvent<HTMLInputElement>) => {
            this.updateField("radius", e.target.value);
        };
        return (
            <>
                <div style={{ whiteSpace: "nowrap" }}>
                    X: <input className="fieldInputText" type="text" value={this.state.values["tx"]} size={10} onChange={onChangeTx} />
                    &nbsp; Y: <input className="fieldInputText" type="text" value={this.state.values["ty"]} size={10} onChange={onChangeTy} />
                    &nbsp; Z: <input className="fieldInputText" type="text" value={this.state.values["tz"]} size={10} onChange={onChangeTz} />
                </div>
                <div style={{ whiteSpace: "nowrap" }}>
                    x,y,z: x,y,z: ... <input className="fieldInputText" type="text" value={this.state.values["coord"]} size={50} onChange={onChangeCoord} />
                </div>
                <div style={{ whiteSpace: "nowrap" }}>
                    {Functions.mlang("[en]Radius[/en][ja]半径[/ja]", lang)}: <input className="fieldInputText" type="text" value={this.state.values["radius"]} size={10} onChange={onChangeRadius} />
                </div>
            </>
        );
    }

    getRows() {
        const rows = [
            { label: "[en]Base-item ID[/en][ja]ベースアイテムID[/ja]", value: this.renderFieldInputText("baseitem_id", 20) },
            { label: "[en]Base-item Type[/en][ja]ベースアイテムタイプ[/ja]", value: this.renderFieldSelect("base_type_name", ItemNimgcenterBrainCoordinateBaseItemTyps) },
            { label: "[en]Cognitive Functions[/en][ja]認知機能[/ja]", value: this.renderFieldInputText("cogfunc", 50) },
            { label: "[en]Brain Areas[/en][ja]脳領域名[/ja]", value: this.renderFieldBrainAreas() },
            { label: "[en]Reference[/en][ja]引用元[/ja]", value: this.renderFieldInputText("reference", 50) },
            { label: "[en]Coordinate Type[/en][ja]脳座標の種類[/ja]", value: this.renderFieldSelect("coord_type", CoordinateTypes) },
            { label: "[en]Brain Coordinates[/en][ja]脳座標[/ja]", value: this.renderFieldBrainCoordinates() },
        ];
        return rows;
    }
}

export default NimgcenterAdvancedSearch;
