import { Link } from "react-router-dom";
import Functions from "../../../functions";
import iconFile from "../../assets/images/icon_files.gif";
import { ItemFiles } from "../../lib/ItemUtil";
import Contributer from "../lib/field/Contributer";
import ListBase, { ListBaseProps } from "../lib/ListBase";

class FilesList extends ListBase {
    constructor(props: ListBaseProps) {
        super(props);
        this.label = "Files";
        this.icon = iconFile;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemFiles;
        return (
            <>
                <Link to={this.url}>{Functions.mlang(item.title, lang)}</Link>
                <br />
                <Contributer lang={lang} uname={item.uname} name={item.name} />
                <br />
                {item.data_file_mimetype}
            </>
        );
    }
}

export default FilesList;
