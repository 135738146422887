import ModelAdvancedSearch from "./ModelAdvancedSearch";
import ModelDetail from "./ModelDetail";
import ModelList from "./ModelList";
import ModelTop from "./ModelTop";

const ItemTypeModel = {
    Top: ModelTop,
    List: ModelList,
    Detail: ModelDetail,
    AdvancedSearch: ModelAdvancedSearch,
};

export default ItemTypeModel;
