import split from "split-string";
import BrainCoordinateUtil, { BrainCoordinate } from "../../../nimgcenter/lib/BrainCoordinateUtil";

const splitString = (str: string): string[] => {
    return split(str, { separator: " ", quotes: true })
        .map((value) => value.replace(/^['"](.*)['"]$/, (whole, m1) => m1).trim())
        .filter((value) => value.length > 0);
};

const getAdvancedSearchFilter = (data: URLSearchParams): any => {
    const type = "nimgcenter";
    const regex = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const filter: any[] = [];
    const coord: BrainCoordinate = { x: NaN, y: NaN, z: NaN };
    const coords: BrainCoordinate[] = [];
    const baseItemIds: number[] = [];
    let andOr = "";
    let coordType = "";
    let doCoordTypeConversion = false;
    let radius = 0;
    filter.push({ item_type_name: "xnp" + type });
    data.forEach((value, key) => {
        switch (key) {
            case "areaname": {
                const areanames = splitString(value);
                areanames.forEach((areaname) => {
                    filter.push({ areaname: { $regex: [regex(areaname), "i"] } });
                });
                break;
            }
            case "coord_type": {
                coordType = value;
                break;
            }
            case "tx": {
                coord.x = parseInt(value, 10);
                break;
            }
            case "ty": {
                coord.y = parseInt(value, 10);
                break;
            }
            case "tz": {
                coord.z = parseInt(value, 10);
                break;
            }
            case "coord": {
                value.split(":").forEach((coordStr) => {
                    const xyz = coordStr
                        .trim()
                        .split(",")
                        .map((xyzStr) => parseInt(xyzStr.trim(), 10));
                    if (xyz.length === 3) {
                        coords.push({ x: xyz[0], y: xyz[1], z: xyz[2] });
                    }
                });
                break;
            }
            case "radius": {
                radius = parseInt(value, 10);
                break;
            }
            case "title":
            case "description":
            case "name":
            case "cogfunc":
            case "reference": {
                filter.push({ [key]: { $regex: [regex(value), "i"] } });
                break;
            }
            case "uname":
            case "base_type_name":
            case "base_type_display_name":
            case "coord_type_name": {
                filter.push({ [key]: { $eq: value } });
                break;
            }
            case "coord_type_conversion": {
                doCoordTypeConversion = /(1|t|y|true|yes)/i.test(value);
                break;
            }
            case "baseitem_id": {
                value
                    .split(",")
                    .map((value) => value.trim())
                    .filter((value) => /^\d+$/.test(value))
                    .forEach((value) => baseItemIds.push(parseInt(value, 10)));
                break;
            }
            case "index_id": {
                value
                    .split(",")
                    .map((value) => value.trim())
                    .filter((value) => /^\d+$/.test(value))
                    .forEach((value) => {
                        filter.push({ index: { $elemMatch: { index_id: parseInt(value, 10) } } });
                    });
                break;
            }
            case "nimgsearch_andor": {
                andOr = value;
                break;
            }
        }
    });
    if (!isNaN(coord.x) && !isNaN(coord.y) && !isNaN(coord.z)) {
        coords.push(coord);
    }
    if (!doCoordTypeConversion) {
        const coord_types = { T: 0, M: 1, U: 2 };
        if (coordType in coord_types) {
            filter.push({ coord_type: { $eq: coord_types[coordType as "T" | "M" | "U"] } });
        }
    }
    if (coords.length > 0) {
        const filterCoord = (coord: BrainCoordinate) =>
            radius > 0
                ? {
                      x: { $between: [coord.x - radius, coord.x + radius] },
                      y: { $between: [coord.y - radius, coord.y + radius] },
                      z: { $between: [coord.z - radius, coord.z + radius] },
                  }
                : {
                      x: { $eq: coord.x },
                      y: { $eq: coord.y },
                      z: { $eq: coord.z },
                  };
        if (doCoordTypeConversion && coordType !== "") {
            const filterTalairach: any[] = [{ coord_type: { $eq: 0 } }];
            const filterMni: any[] = [{ coord_type: { $eq: 1 } }];
            const filterUnclear: any[] = [{ coord_type: { $eq: 2 } }];
            coords.forEach((coord) => {
                const coordTalailarach = coordType === "M" ? BrainCoordinateUtil.convertCoordinate("icbm_spm2tal", coord) : coord;
                const coordMni = coordType === "T" ? BrainCoordinateUtil.convertCoordinate("tal2icbm_spm", coord) : coord;
                filterTalairach.push({ coordinate: { $elemMatch: filterCoord(coordTalailarach) } });
                filterMni.push({ coordinate: { $elemMatch: filterCoord(coordMni) } });
                filterUnclear.push({ coordinate: { $elemMatch: filterCoord(coord) } });
            });
            filter.push({
                $or: [{ $and: filterTalairach }, { $and: filterMni }, { $and: filterUnclear }],
            });
        } else {
            coords.forEach((coord) => {
                filter.push({ coordinate: { $elemMatch: filterCoord(coord) } });
            });
        }
    }
    const filterBaseItemId = { baseitem_id: { $in: baseItemIds } };
    if (baseItemIds.length === 0) {
        if (andOr === "and") {
            filter.push({ item_id: -1 });
        }
    } else {
        if (andOr === "or") {
            return { $or: [{ $and: filter }, filterBaseItemId] };
        }
        filter.push(filterBaseItemId);
    }
    return { $and: filter };
};

const SearchQuery = {
    getAdvancedSearchFilter,
};

export default SearchQuery;
