import iconFile from "../../assets/images/icon_conference.gif";
import { ItemConferenceSubTypes } from "../../lib/ItemUtil";
import TopBase, { TopBaseProps } from "../lib/TopBase";

class ConferenceTop extends TopBase {
    constructor(props: TopBaseProps) {
        super(props);
        this.type = "conference";
        this.label = "Conference";
        this.icon = iconFile;
        this.description = "[en]Electrical presentation files for conference.[/en][ja]学会発表[/ja]";
        this.subTypes = ItemConferenceSubTypes;
    }
}

export default ConferenceTop;
