import UrlAdvancedSearch from "./UrlAdvancedSearch";
import UrlDetail from "./UrlDetail";
import UrlList from "./UrlList";
import UrlTop from "./UrlTop";

const ItemTypeUrl = {
    Top: UrlTop,
    List: UrlList,
    Detail: UrlDetail,
    AdvancedSearch: UrlAdvancedSearch,
};

export default ItemTypeUrl;
