import React from "react";
import { Link } from "react-router-dom";
import LangFlag from "../common/lib/LangFlag";
import Config, { MultiLang } from "../config";
import imageJnode from "./assets/images/jnode.png";

interface Props {
    lang: MultiLang;
}

const Header: React.FC<Props> = (props: Props) => {
    return (
        <header id="header">
            <div className="logo">
                <Link to="/" title="Home">
                    <span className="hidden">{Config.SITE_TITLE}</span>
                </Link>
            </div>
            <div className="jnode">
                <a href="https://www.neuroinf.jp/" target="_blank" rel="noopener noreferrer" title="INCF Japan Node">
                    <img src={imageJnode} alt="INCF Japan Node" />
                </a>
            </div>
            <nav className="menubar">
                <ul className="mainmenu">
                    <li>
                        <Link id="hmm01" to="/modules/nimgdocs/index.php?docname=about_NIMGPF" title="NIMG-PFの基本理念、委員会構成、取り扱い説明等を掲載しています。">
                            NIMG-PFについて About This Site
                        </Link>
                    </li>
                    <li>
                        <Link id="hmm02" to="/modules/xoonips/" title="データベースを検索することができます。">
                            XooNIpsモード XooNIps database mode
                        </Link>
                    </li>
                    <li>
                        <Link id="hmm03" to="/modules/nimgsearch/" title="脳図から関連する文献を検索することができます。">
                            脳図検索モード search with Brain Atlas
                        </Link>
                    </li>
                    <li>
                        <Link id="hmm04" to="/modules/nimgdocs/tutorials/" title="脳イメージングについて説明した教育資料を閲覧できます。">
                            チュートリアル NIMG-PF Tutorials
                        </Link>
                    </li>
                    <li>
                        <Link id="hsm01" to="/modules/xnpnimgcenter/utilities/" title="ユーティリティ">
                            ユーティリティ Utilities
                        </Link>
                    </li>
                    <li>
                        <Link id="hsm03" to="/modules/nimgdocs/index.php?docname=3DBrowse" title="日本人脳データ">
                            日本人脳データ 3D Browse
                        </Link>
                    </li>
                    <li>
                        <Link id="hsm04" to="/modules/nimgdocs/index.php?docname=3DBrowse" title="日本ザル脳データ">
                            日本ザル脳データ 3D Browse
                        </Link>
                    </li>
                    <li>
                        <div className="lang">
                            <LangFlag lang="en" />
                            <LangFlag lang="ja" />
                        </div>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
