import React from "react";
import { useNavigate } from "react-router-dom";
import Config, { MultiLang } from "../config";
import Functions from "../functions";
import ItemType from "./item-type";
import AdvancedSearchQuery from "./lib/AdvancedSearchQuery";
import ItemUtil from "./lib/ItemUtil";

interface Props {
    lang: MultiLang;
}

const XoonipsAdvancedSearch: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const query = new AdvancedSearchQuery();
    const navigate = useNavigate();

    const handleClickSearchButton = () => {
        if (!query.empty()) {
            const url = ItemUtil.getSearchByAdvancedKeywordsUrl(query);
            navigate(url);
        }
    };

    return (
        <div className="advancedSearch">
            <h3>{Functions.mlang("[en]Search Items[/en][ja]アイテム検索[/ja]", lang)}</h3>
            <div className="search">
                <button className="formButton" onClick={handleClickSearchButton}>
                    Search
                </button>
            </div>
            {Config.XOONIPS_ITEMTYPES.map((type) => {
                return <ItemType.AdvancedSearch key={type} type={"xnp" + type} lang={lang} query={query} />;
            })}
            <div className="search">
                <button className="formButton" onClick={handleClickSearchButton}>
                    Search
                </button>
            </div>
        </div>
    );
};

export default XoonipsAdvancedSearch;
