import React from "react";
import { Link } from "react-router-dom";
import { MultiLang } from "../../config";
import Functions from "../../functions";

interface Props {
    lang: MultiLang;
}

const BulletinBoard: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <div className="block">
            <div className="blockTitle">{Functions.mlang("[en]Bulletin Board[/en][ja]掲示板[/ja]", lang)}</div>
            <div className="blockContent">
                <p style={{ fontWeight: "bold", textAlign: "center" }}>{Functions.mlang("[en]There is no post yet.[/en][ja]まだ投稿がありません[/ja]", lang)}</p>
                <Link to="/modules/nimgdocs/bulletin/log.php">{Functions.mlang("[en]View expired posts[/en][ja]掲示期間が終了した投稿を見る[/ja]", lang)}</Link>
            </div>
        </div>
    );
};

export default BulletinBoard;
