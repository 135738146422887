import React from "react";
import { Navigate } from "react-router";
import { useLocation } from "react-router-dom";
import { MultiLang } from "../config";
import PageNotFound from "./lib/PageNotFound";

interface Props {
    lang: MultiLang;
}

const XoopsPathRedirect: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const location = useLocation();

    const getRedirectUrl = () => {
        const { pathname } = location;
        switch (pathname || "") {
            case "/index.php": {
                return "/";
            }
        }
        return "";
    };

    if (location.pathname === "/") {
        return null;
    }
    const url = getRedirectUrl();
    if (url === "") {
        return <PageNotFound lang={lang} />;
    }
    return <Navigate to={url} />;
};

export default XoopsPathRedirect;
