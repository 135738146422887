import React from "react";
import { MultiLang } from "../config";
import Functions from "../functions";
import ItemUtil, { SearchCallbackFunc, SortCondition } from "./lib/ItemUtil";
import XoonipsListItem from "./lib/XoonipsListItem";

interface Props {
    lang: MultiLang;
    itemType: string | null;
    itemSubType: string | null;
}

const XoonipsSearchByItemType: React.FC<Props> = (props: Props) => {
    const { lang, itemType, itemSubType } = props;

    const searchFunc = (condition: SortCondition, func: SearchCallbackFunc) => {
        if (itemType === null || itemSubType === null) {
            const res = { total: 0, data: [] };
            func(res);
        } else {
            ItemUtil.getListByItemType(itemType, itemSubType, condition, func);
        }
    };

    const baseUrl = ItemUtil.getItemTypeSearchUrl(itemType, itemSubType);
    return (
        <div className="list">
            <h3>{Functions.mlang("[en]Listing item[/en][ja]アイテム一覧[/ja]", lang)}</h3>
            <XoonipsListItem lang={lang} url={baseUrl} search={searchFunc} />
        </div>
    );
};

export default XoonipsSearchByItemType;
