import PresentationAdvancedSearch from "./PresentationAdvancedSearch";
import PresentationDetail from "./PresentationDetail";
import PresentationList from "./PresentationList";
import PresentationTop from "./PresentationTop";

const ItemTypePresentation = {
    Top: PresentationTop,
    List: PresentationList,
    Detail: PresentationDetail,
    AdvancedSearch: PresentationAdvancedSearch,
};

export default ItemTypePresentation;
