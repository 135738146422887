import React from "react";
import { MultiLang } from "../../../../config";

interface Props {
    lang: MultiLang;
    keyword: string[];
}

const FreeKeyword: React.FC<Props> = (props: Props) => {
    const { keyword } = props;
    if (keyword.length === 0) {
        return null;
    }
    const label = keyword.join(", ");
    return <span>{label}</span>;
};

export default FreeKeyword;
