import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import ItemType from "..";
import Loading from "../../../common/lib/Loading";
import { MultiLang } from "../../../config";
import Functions from "../../../functions";
import ItemUtil, { Item, ItemNimgcenter } from "../../lib/ItemUtil";
import DetailBase from "../lib/DetailBase";
import ItemTypeField from "../lib/field";
import NimgcenterUtil from "./NimgcenterUtil";

interface NimgcenterItemDetailProps {
    lang: MultiLang;
    item: ItemNimgcenter;
}

interface NimgcenterItemDetailState {
    loading: boolean;
    baseitem: Item | null;
    redirectToBaseItem: boolean;
}

class NimgcenterItemDetail extends React.Component<NimgcenterItemDetailProps, NimgcenterItemDetailState> {
    private isActive: boolean;

    constructor(props: NimgcenterItemDetailProps) {
        super(props);
        this.state = {
            loading: true,
            baseitem: null,
            redirectToBaseItem: false,
        };
        this.isActive = false;
    }

    componentDidMount() {
        const { item } = this.props;
        this.isActive = true;
        this.updateItem(item);
    }

    componentDidUpdate(prevProps: NimgcenterItemDetailProps, prevState: NimgcenterItemDetailState) {
        const { item } = this.props;
        const prevItem = prevProps.item;
        if (item.item_id !== prevItem.item_id) {
            this.setState({ loading: true, baseitem: null });
            this.updateItem(item);
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    updateItem(item: ItemNimgcenter) {
        ItemUtil.get(item.baseitem_id, (baseitem) => {
            if (this.isActive) {
                this.setState({ loading: false, baseitem });
            }
        });
    }

    renderField(label: string, value: ReactNode, idx: number) {
        const { lang } = this.props;
        const evenodd = idx % 2 === 0 ? "even" : "odd";
        return (
            <tr key={idx}>
                <td className="head">{Functions.mlang(label, lang)}</td>
                <td className={evenodd}>{value}</td>
            </tr>
        );
    }

    render() {
        const { lang, item } = this.props;
        if (this.state.loading || this.state.baseitem === null) {
            return <Loading />;
        }
        if (this.state.redirectToBaseItem) {
            const url = ItemUtil.getUrl(this.state.baseitem);
            return <Navigate to={url} />;
        }
        const coordinates = NimgcenterUtil.renderBrainCoodinates(item.coordinate);
        const fields = [
            { label: "[en]Last Modified Date of Brain-Coordinates item[/en][ja]Brain-Coordinatesアイテム最終更新日[/ja]", value: <ItemTypeField.DateTime lang={lang} date={item.last_update_date} /> },
            { label: "[en]Created Date of Brain-Coordinates item[/en][ja]Brain-Coordinatesアイテム作成日[/ja]", value: <ItemTypeField.DateTime lang={lang} date={item.creation_date} /> },
            { label: "[en]Contributor of Brain-Coordinates item[/en][ja]Brain-Coordinatesアイテム登録者[/ja]", value: <ItemTypeField.Contributer lang={lang} uname={item.uname} name={item.name} /> },
            { label: "[en]Cognitive Functions[/en][ja]認知機能[/ja]", value: item.cogfunc },
            { label: "[en]Brain Areas[/en][ja]脳領域名[/ja]", value: item.areaname },
            { label: "[en]Reference[/en][ja]引用元[/ja]", value: item.reference },
            { label: "[en]Coordinate Type[/en][ja]脳座標の種類[/ja]", value: item.coord_type_name },
            { label: "[en]Brain Coordinates[/en][ja]脳座標[/ja]", value: coordinates },
            { label: "Index", value: <ItemTypeField.ItemIndex lang={lang} index={item.index} /> },
        ];
        return (
            <div>
                <div>
                    <button
                        onClick={() => {
                            this.setState({ redirectToBaseItem: true });
                        }}
                    >
                        {Functions.mlang("[en]Show the base-item[/en][ja]ベースアイテムを表示[/ja]", lang)}
                    </button>
                </div>
                <table className="outer itemDetail">
                    <tbody>{this.renderField("Base-item ID", item.baseitem_id.toString(), 1)}</tbody>
                </table>
                <ItemType.Detail lang={lang} item={this.state.baseitem} isBaseItem={true} />
                <table className="outer itemDetail">
                    <tbody>{fields.map((field, idx) => this.renderField(field.label, field.value, idx))}</tbody>
                </table>
            </div>
        );
    }
}

class NimgcenterDetail extends DetailBase {
    render() {
        const { lang } = this.props;
        const item = this.props.item as ItemNimgcenter;
        return <NimgcenterItemDetail lang={lang} item={item} />;
    }
}

export default NimgcenterDetail;
