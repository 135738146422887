import React from "react";
import { MultiLang } from "../../config";
import Functions from "../../functions";

interface Props {
    lang: MultiLang;
}

const Questionnaire: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = "[en]Questionnaire and Contact address[/en][ja]NIMG-PFの使い方[/ja]";
    return (
        <div className="block">
            <div className="blockTitle">{Functions.mlang(title, lang)}</div>
            <div className="blockContent">
                <div>
                    {Functions.mlang("[en]Please fill out the questionnaire.[/en][ja]アンケートにご協力ください[/ja]", lang)} ⇒{" "}
                    <a href="https://spreadsheets.google.com/viewform?formkey=cEpsb3doVHlOTUJ0UHcxQ3k1TFNHSlE6MA" target="_blank" rel="noopener noreferrer">
                        {Functions.mlang("[en]Go to the page of questionnaire.[/en][ja]アンケートのページへ[/ja]", lang)}
                    </a>
                </div>
                <div>{Functions.mlang("[en]Our e-mail address is as follows. If you have any questions, please let me know.[/en][ja]NIMG-PFについてご質問等ありましたら、以下までお願いします。[/ja]", lang)}</div>
                <p style={{ textAlign: "center", textDecoration: "underline" }}>
                    {Functions.mlang("[en]Contact information of NIMG-PF[/en][ja]NIMG-PFについてのご連絡先[/ja]", lang)}: <a href="mailto:NIMG-info@ml.nict.go.jp">NIMG-info@ml.nict.go.jp</a>
                </p>
            </div>
        </div>
    );
};

export default Questionnaire;
