import SimulatorAdvancedSearch from "./SimulatorAdvancedSearch";
import SimulatorDetail from "./SimulatorDetail";
import SimulatorList from "./SimulatorList";
import SimulatorTop from "./SimulatorTop";

const ItemTypeSimulator = {
    Top: SimulatorTop,
    List: SimulatorList,
    Detail: SimulatorDetail,
    AdvancedSearch: SimulatorAdvancedSearch,
};

export default ItemTypeSimulator;
