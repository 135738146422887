import MemoAdvancedSearch from "./MemoAdvancedSearch";
import MemoDetail from "./MemoDetail";
import MemoList from "./MemoList";
import MemoTop from "./MemoTop";

const ItemTypeMemo = {
    Top: MemoTop,
    List: MemoList,
    Detail: MemoDetail,
    AdvancedSearch: MemoAdvancedSearch,
};

export default ItemTypeMemo;
