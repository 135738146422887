import React from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PageNotFound from "../common/lib/PageNotFound";
import XoopsCode from "../common/lib/XoopsCode";
import { MultiLang } from "../config";
import Functions from "../functions";
import json3DBrowse from "./assets/3dbrowse.json";
import json3DBrowseUsage from "./assets/3dbrowse_usage.json";
import jsonAbout from "./assets/about.json";
import jsonAdditem from "./assets/additem.json";
import jsonIntroduction from "./assets/introduction.json";
import jsonNimgcenter from "./assets/nimgcenter.json";
import jsonNimgsearch from "./assets/nimgsearch.json";
import NimgdocsBulletinLog from "./NimgdocsBulletinLog";
import NimgdocsTutorilas from "./NimgdocsTutorials";

interface Props {
    lang: MultiLang;
}

const jsonDocuments = [
    { name: "about_NIMGPF", data: jsonAbout },
    { name: "additem", data: jsonAdditem },
    { name: "introduction", data: jsonIntroduction },
    { name: "nimgcenter", data: jsonNimgcenter },
    { name: "nimgsearch", data: jsonNimgsearch },
    { name: "3DBrowse", data: json3DBrowse },
    { name: "3DBrowse_usage", data: json3DBrowseUsage },
];

const NimgdocsPage: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const docname = params.get("docname") || "";
    const doc = jsonDocuments.find((value) => value.name === docname);
    if (typeof doc !== "undefined") {
        return (
            <>
                <Helmet>
                    <title>NIMG Documents - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <XoopsCode lang={lang} text={doc.data[lang]} dohtml={true} />
            </>
        );
    }
    return <PageNotFound lang={lang} />;
};

const Nimgdocs: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <Routes>
            <Route path="" element={<Navigate to="/modules/nimgdocs/index.php?docname=about_NIMGPF" />} />
            <Route path="index.php" element={<NimgdocsPage lang={lang} />} />
            <Route path="tutorials/index.php" element={<Navigate to="/modules/nimgdocs/tutorials/" />} />
            <Route path="tutorials/" element={<NimgdocsTutorilas lang={lang} />} />
            <Route path="bulletin/log.php" element={<NimgdocsBulletinLog lang={lang} />} />
            <Route path="*" element={<PageNotFound lang={lang} />} />
        </Routes>
    );
};

export default Nimgdocs;
