import React, { CSSProperties, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Modal } from "react-overlays";
import { MultiLang } from "../config";
import Functions from "../functions";

interface Props {
    lang: MultiLang;
}

const NimgdocsTutorials: React.FC<Props> = (props: Props) => {
    const { lang } = props;

    const [isShowVideo, setIsShowVideo] = useState<boolean>(false);
    const [videoName, setVideoName] = useState<string>("");
    const [videoWidth, setVideoWidth] = useState<number>(640);
    const [videoHeight, setVideoHeight] = useState<number>(480);
    const [isOpenA0, setIsOpenA0] = useState<boolean>(false);
    const [isOpenA1, setIsOpenA1] = useState<boolean>(false);
    const [isOpenA2, setIsOpenA2] = useState<boolean>(false);
    const [isOpenA3, setIsOpenA3] = useState<boolean>(false);
    const [isOpenA4, setIsOpenA4] = useState<boolean>(false);
    const [isOpenA5, setIsOpenA5] = useState<boolean>(false);

    const styleBackdrop: CSSProperties = { position: "fixed", zIndex: 1, top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "#000000", opacity: 0.8 };
    const styleOverlay: CSSProperties = { position: "fixed", zIndex: 2, top: 0, left: 0, width: "100%", height: "100%", textAlign: "center" };
    const styleDialog: CSSProperties = { display: "inline-block", padding: "30px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#eeeeee", borderRadius: "10px" };
    const styleClose: CSSProperties = { display: "inline-block", position: "absolute", top: "5px", right: "10px", color: "black", fontSize: "28px", fontWeight: "bold", cursor: "pointer" };

    const showVideo = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, movie: string, width: number, height: number) => {
        event.preventDefault();
        setIsShowVideo(true);
        setVideoName(movie);
        setVideoWidth(width);
        setVideoHeight(height);
    };

    const togglePanel = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, type: string) => {
        event.preventDefault();
        switch (type) {
            case "a":
                setIsOpenA0(!isOpenA0);
                break;
            case "a1":
                setIsOpenA1(!isOpenA1);
                break;
            case "a2":
                setIsOpenA2(!isOpenA2);
                break;
            case "a3":
                setIsOpenA3(!isOpenA3);
                break;
            case "a4":
                setIsOpenA4(!isOpenA4);
                break;
            case "a5":
                setIsOpenA5(!isOpenA5);
                break;
        }
    };

    const getContent = () => {
        const contents = {
            en: (
                <>
                    <h1 id="top">Introduction to Neuroimaging</h1>
                    <ul>
                        <li>
                            <p>
                                <strong>This page provides a variety of materials and tutorials for students and researchers who will become interested in Neuroimaging. This page contains the following items.</strong>
                            </p>
                            <ul>
                                <li>
                                    <a href="#neuroimg">What is Neuroimaging?</a>
                                </li>
                                <li>
                                    <a href="#fmri">functional Magnetic Resonance Imaging (fMRI)</a>
                                </li>
                                <li>
                                    <a href="#meg">Magnetoencephalography (MEG)</a>
                                </li>
                                <li>
                                    <a href="#nirs">Near Infrared Spectroscopy (NIRS)</a>
                                </li>
                                <li>
                                    <a href="#eeg">Electroencephalography (EEG)</a>
                                </li>
                                <li>
                                    <a href="#stim">Experimental Control</a>
                                </li>
                                <li>
                                    <a href="#soft">Softwares</a>
                                </li>
                                <li>
                                    <a href="#basic">Text Books</a>
                                </li>
                                <li>
                                    <a href="#training%20course">Training Course</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <fieldset id="neuroimg">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/neuro.png" alt="NeuroImaging" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>What is Neuroimaging?</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    <strong>Here is a video which explains equipments and methods of neuroimaging.</strong>
                                                </p>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?id=DD022" target="_blank" rel="noopener noreferrer">
                                                            NICT tutorial video [Searching brain information processes] (2004)
                                                        </a>
                                                        <ul>
                                                            <li>This is a tutorial video how to operate the equipment and measurement principles of brain activity measurements for the public. This video is made by NICT in 2004.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">Page Top</a>
                    </div>
                    <fieldset id="fmri">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/fMRI.png" alt="fMRI" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>functional Magnetic Resonance Imaging (fMRI)</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    <strong>Explanation of principles, equipments, methods and analysis and Information of websites related to functional Magnetic Resonance Imaging: fMRI.</strong>
                                                </p>
                                                <h3>
                                                    <em>About principles and equipments of fMRI</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/" onClick={(e) => showVideo(e, "tutorial_english_MRI1m_comp", 480, 320)}>
                                                            About fMRI <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="movie icon" style={{ width: "18", height: "18" }} />
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                This video is an excerpt a portion of fMRI in the tutorial video how to operate the equipment and measurement principles of brain activity measurements.(You can watch a full-length video from the preceding paragraph "<a href="#neuroimg">What is Neuroimaging?</a>")
                                                                <br />
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        Explanation of the principle of MRI
                                                        <ul>
                                                            <li>Under construction</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>About an analysis program in a fMRI study</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=272" target="_blank" rel="noopener noreferrer">
                                                            SPM (Statistical Parametric Mapping)
                                                        </a>
                                                        <ul>
                                                            <li>Comprehensive free software used for PET/fMRI analysis. However, numerical computation software MATLAB (commercial) is necessary for operation. The software is quite refined and has been referenced often in studies. Moreover, it is frequently updated, and an extensive mailing list is offered.</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=605" target="_blank" rel="noopener noreferrer">
                                                            BrainVoyager
                                                        </a>
                                                        <ul>
                                                            <li>Comprehensive commercial software used for PET/fMRI analysis. It has abilities such as 2-dimensional cortical flatmaps which cannot be performed in the above-mentioned SPM software. It is also able to link with applied analysis such as independent component analysis, as well as with signal source analysis software BESA.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>Useful software of fMRI imaging</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=604" target="_blank" rel="noopener noreferrer">
                                                            MRIcro
                                                        </a>
                                                        <ul>
                                                            <li>Free software that can display various medical images such as MRI.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">Page Top</a>
                    </div>
                    <fieldset id="meg">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/MEG.png" alt="MEG" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>Magnetoencephalography (MEG)</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    <strong>Explanation of principles, equipments, methods and analysis and Information of websites related to Magnetoencephalography: MEG.</strong>
                                                </p>
                                                <h3>
                                                    <em>About principles and equipments of MEG</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/" onClick={(e) => showVideo(e, "tutorial_english_MEG1m_comp", 480, 320)}>
                                                            About MEG <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="movie icon" style={{ width: "18", height: "18" }} />
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                This video is an excerpt a portion of MEG in the tutorial video how to operate the equipment and measurement principles of brain activity measurements.(You can watch a full-length video from the preceding paragraph "<a href="#neuroimg">What is Neuroimaging?</a>" )
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/nimgdocs/tutorials/noukinoukensa.pdf" target="_blank" rel="noopener noreferrer">
                                                            Examination of brain function: measurement and analysis of MEG (written in Japanese) (PDF)
                                                        </a>
                                                        <ul>
                                                            <li>Basic principles, methods, analyses of MEG are explained.</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/nimgdocs/tutorials/slide.html" target="_blank" rel="noopener noreferrer">
                                                            Training text: "Measurement and Analysis of MEG", Research and Education Center for Brain Science, Hokkaido Univ. (written in Japanese) (HTML slides)
                                                        </a>
                                                        <ul>
                                                            <li>Principle of SQUID, measurement and analysis methodology, and signal source localization are simply explained.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>About an analysis program in an MEG study</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1494" target="_blank" rel="noopener noreferrer">
                                                            BESA
                                                        </a>
                                                        <ul>
                                                            <li>Commercial software used to analyze EEG and MEG. Signal source analysis (a.k.a. dipole estimation) is possible with this software.</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1497" target="_blank" rel="noopener noreferrer">
                                                            BrainStorm
                                                        </a>
                                                        <ul>
                                                            <li>Free software used for EEG and MEG analysis. Various signal source analyses are possible.</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1325" target="_blank" rel="noopener noreferrer">
                                                            EEGLAB
                                                        </a>
                                                        <ul>
                                                            <li>Free software used for EEG and MEG analysis. Many analyses can be done, including preprocessing (various filters, artifact removal, etc.), additive averaging, frequency analysis, and independent component analysis.</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1495" target="_blank" rel="noopener noreferrer">
                                                            FieldTrip
                                                        </a>
                                                        <ul>
                                                            <li>Free software used for EEG and MEG analysis. Analyses such as preprocessing, additive averaging, and frequency analysis can be performed. Multiple signal source analyses are also possible.</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=272" target="_blank" rel="noopener noreferrer">
                                                            SPM
                                                        </a>
                                                        <ul>
                                                            <li>SPM, which is widely used for PET/fMRI analysis, supports EEG and MEG. The SPM8 software is dependant on FieldTrip. It can do preprocessing, additive averaging, signal source estimation, and more. As of March 2009, some parts are still in development; please use accordingly.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">Page Top</a>
                    </div>
                    <fieldset id="nirs">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/NIRS.png" alt="NIRS" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>Near Infrared Spectroscopy (NIRS)</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    <strong>Explanation of principles, equipments, methods and analysis and Information of websites related to Near Infrared Spectroscopy: NIRS.</strong>
                                                </p>{" "}
                                                <h3>
                                                    <em>About principles and equipments of NIRS</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/" onClick={(e) => showVideo(e, "tutorial_english_NIRS1m_comp", 480, 320)}>
                                                            about NIRS <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="movie icon" style={{ width: "18", height: "18" }} />
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                This video is an excerpt a portion of NIRS in the tutorial video how to operate the equipment and measurement principles of brain activity measurements.(You can watch a full-length video from the preceding paragraph "<a href="#neuroimg">What is Neuroimaging?</a>" )<br />
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>About an analysis program in a NIRS study</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1496" target="_blank" rel="noopener noreferrer">
                                                            NIRS-SPM
                                                        </a>
                                                        <ul>
                                                            <li>A NIRS analysis program based on SPM, used for PET/fMRI analysis.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">Page Top</a>
                    </div>
                    <fieldset id="eeg">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/EEG.png" alt="EEG" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>Electroencephalography (EEG)</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    <strong>Explanation of principles, equipments, methods and analysis and Information of websites related to Electroencephalography: EEG.</strong>
                                                </p>{" "}
                                                <h3>
                                                    <em>About principles and equipments of EEG</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        About EEG
                                                        <ul>
                                                            <li>Under construction</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>About an analysis program in a EEG study</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1494" target="_blank" rel="noopener noreferrer">
                                                            BESA
                                                        </a>
                                                        <ul>
                                                            <li>Commercial software used to analyze EEG and MEG. Signal source analysis (a.k.a. dipole estimation) is possible with this software.</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1497" target="_blank" rel="noopener noreferrer">
                                                            BrainStorm
                                                        </a>
                                                        <ul>
                                                            <li>Free software used for EEG and MEG analysis. Various signal source analyses are possible.</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1325" target="_blank" rel="noopener noreferrer">
                                                            EEGLAB
                                                        </a>
                                                        <ul>
                                                            <li>Free software used for EEG and MEG analysis. Many analyses can be done, including preprocessing (various filters, artifact removal, etc.), additive averaging, frequency analysis, and independent component analysis.</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1495" target="_blank" rel="noopener noreferrer">
                                                            FieldTrip
                                                        </a>
                                                        <ul>
                                                            <li>Free software used for EEG and MEG analysis. Analyses such as preprocessing, additive averaging, and frequency analysis can be performed. Multiple signal source analyses are also possible.</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=272" target="_blank" rel="noopener noreferrer">
                                                            SPM
                                                        </a>
                                                        <ul>
                                                            <li>SPM, which is widely used for PET/fMRI analysis, supports EEG and MEG. The SPM8 software is dependant on FieldTrip. It can do preprocessing, additive averaging, signal source estimation, and more. As of March 2009, some parts are still in development; please use accordingly.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">Page Top</a>
                    </div>
                    <fieldset id="stim">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/Control.png" alt="Control" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>Experimental Control</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    <strong>In neuroimaging, it is necessary to present visual and auditory stimuli and collect subject reactions. Here, the software for the experimental control is introduced.</strong>
                                                </p>{" "}
                                                <h3>
                                                    <em>General stimulus presentation software</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=606" target="_blank" rel="noopener noreferrer">
                                                            Presentation
                                                        </a>
                                                        <ul>
                                                            <li>Multimedia stimulus presentation and control software which supports fMRI, MEG, NIRS, EEG, and more. It is also possible to collect subject reactions.</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=273" target="_blank" rel="noopener noreferrer">
                                                            Cogent
                                                        </a>
                                                        <ul>
                                                            <li>This software can interact with fMRI, MEG, etc. Especially used for accurate, real time control of visual stimulus presentation.</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=607" target="_blank" rel="noopener noreferrer">
                                                            Psychophysics Toolbox
                                                        </a>
                                                        <ul>
                                                            <li>Stimulus presentation control software that works with MATLAB.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">Page Top</a>
                    </div>
                    <fieldset id="soft">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/Software.png" alt="Software" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>Softwares</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    <strong>Neuroimaging studies require various techniques of image processing and signal processing. Here, the software that generally used for the basic processing is introduced.</strong>
                                                </p>{" "}
                                                <h3>
                                                    <em>Numerical Analysis software</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=274" target="_blank" rel="noopener noreferrer">
                                                            MATLAB
                                                        </a>
                                                        <ul>
                                                            <li>Necessary to use SPM, EEGLAB, BrainStorm, Cogent, Psychophysics Toolbox, etc.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">Page Top</a>
                    </div>
                    <fieldset id="basic">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/Textbook.png" alt="Textbook" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>Text Books</h2>
                                        <ul>
                                            <li>
                                                <strong>Neuroimaging is an integrated science. For this research, therefore, basic knowledge in various areas is necessary, including neuroscience, anatomy, physiology, psychology, statistics, and others. Here, introductory books, reference books, and related URLs are introduced to help obtain this basic knowledge.</strong>
                                                <br />
                                                <br />
                                                <h3>
                                                    <em>Introduction to neuroscience</em>
                                                </h3>
                                                <br />
                                                <br />
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=591" target="_blank" rel="noopener noreferrer">
                                                            Principles of Neural Science
                                                        </a>
                                                        <ul>
                                                            <li>Eric R. Kandel, James H. Schwartz, Thomas M. Jessell (ed.)</li>
                                                            <li>
                                                                Appleton &amp; Lange
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;A standard neuroscience textbook written in English. This book offers comprehensive coverage of neuroscience topics. More suitable for group study than for individual reading. The 5th edition is scheduled to be published in 2010.
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1491" target="_blank" rel="noopener noreferrer">
                                                            Essentials of Neural Science and Behavior
                                                        </a>
                                                        <ul>
                                                            <li>Eric R. Kandel</li>
                                                            <li>
                                                                Mcgraw-Hill
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;An English textbook written by the author of "Principles of Neural Science", however the entries are comparatively limited and are written more simply. The latest edition is scheduled to be published in 2009.
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1492" target="_blank" rel="noopener noreferrer">
                                                            Cognitive Neuroscience: The Biology of the Mind
                                                        </a>
                                                        <ul>
                                                            <li>Michael S. Gazzaniga, Richard B. Ivry, G. R. Mangun</li>
                                                            <li>
                                                                W W Norton &amp; Co Inc
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;An English language cognitive neuroscience textbook. Compared to the "Principles of Neural Science", there are fewer neuroscience entries in physiology and biology. Instead, more space is devoted to neuroimaging topics on psychological and cognitive science. Many newer studies are introduced, and this is the best textbook for learning the cognitive neuroscience field where the power of neuroimaging is utilized extensively.
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>Introduction to neuroimaging</em>
                                                </h3>
                                                <br />
                                                <br />
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=356" target="_blank" rel="noopener noreferrer">
                                                            Functional Magnetic Resonance Imaging
                                                        </a>
                                                        <ul>
                                                            <li>Scott A. Huettel, Allen W. Song, Gregory McCarthy</li>
                                                            <li>
                                                                Sinauer Associates Inc
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;An English-language introductory text of neuroimaging via functional magnetic resonance image. From the principles of MRI to experimental methodology to analysis, this book covers the whole subject. Once acquiring the basic knowledge of neuroscience, this is the best textbook to use once neuroimaging is finally put into practice.
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1493" target="_blank" rel="noopener noreferrer">
                                                            Functional MRI: An Introduction to Methods
                                                        </a>
                                                        <ul>
                                                            <li>Peter Jezzard, Paul M. Matthews, Stephen M. Smith (ed.)</li>
                                                            <li>
                                                                Oxford University Press
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;An English language neuroimaging textbook of functional magnetic resonance image methods, slightly more advanced and specialized than the previous book.
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">Page Top</a>
                    </div>
                    <fieldset id="training course">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/Training%20Course.png" alt="Training Courses" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>Training Courses</h2>
                                        <ul>
                                            <li>
                                                <strong>Following URLs offer information on training courses on neuroimaging.</strong>
                                                <br />
                                                <br />
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1506" target="_blank" rel="noopener noreferrer">
                                                            FMRIB Centre (University of Oxford)
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                FSL &amp; FreeSurfer Course
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;Lecture slides on introduction to neuroimaging are available.
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1507" target="_blank" rel="noopener noreferrer">
                                                            SPM Central
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                SPM Course
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;SPM Course slides are available.
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">Page Top</a>
                    </div>
                </>
            ),
            ja: (
                <>
                    <h1 id="top">ニューロイメージング入門</h1>
                    <ul>
                        <li>
                            <strong>
                                ここではニューロイメージング研究にこれから携わろうとする学生や研究者のために、さまざまなチュートリアルや資料を提供しています。このページには以下の項目が含まれています。
                                <br />
                                <br />
                            </strong>
                            <ul>
                                <li>
                                    <a href="#neuroimg">ニューロイメージングとは？</a>
                                </li>
                                <li>
                                    <a href="#fmri">機能的磁気共鳴画像法 (fMRI)</a>
                                </li>
                                <li>
                                    <a href="#meg">脳磁計測法 (MEG)</a>
                                </li>
                                <li>
                                    <a href="#nirs">近赤外線分光法 (NIRS)</a>
                                </li>
                                <li>
                                    <a href="#eeg">脳波計測法 (EEG)</a>
                                </li>
                                <li>
                                    <a href="#stim">実験制御</a>
                                </li>
                                <li>
                                    <a href="#soft">ソフトウェア</a>
                                </li>
                                <li>
                                    <a href="#basic">自習用教科書</a>
                                </li>
                                <li>
                                    <a href="#training%20course">講習会</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <br />
                    <fieldset id="neuroimg">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/neuro.png" alt="ニューロイメージング" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>ニューロイメージングとは？</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    <strong>ニューロイメージングに用いられる装置や方法についてビデオでやさしく説明します。</strong>
                                                </p>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?id=DD021" target="_blank" rel="noopener noreferrer">
                                                            脳の情報処理を探る（一般向け）（ビデオ）
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                情報通信研究機構(NICT)の脳活動計測装置の動作原理と計測方法についての一般向けチュートリアル・ビデオ。（2004年作成）
                                                                <br />
                                                                （視聴する際にはダウンロードが必要です。）
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?id=DD023" target="_blank" rel="noopener noreferrer">
                                                            脳の情報処理を探る（生徒向け）（ビデオ）
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                情報通信研究機構(NICT)の脳活動計測装置の動作原理と計測方法についての生徒向けチュートリアル・ビデオ。（2004年作成）
                                                                <br />
                                                                （視聴する際にはダウンロードが必要です。）
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">ページトップへ</a>
                    </div>
                    <fieldset id="fmri">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/fMRI.png" alt="fMRI" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>機能的磁気共鳴画像法 (fMRI)</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    <strong>機能的磁気共鳴画像法（functional Magnetic Resonance Imaging：fMRI）の原理・装置・実験方法・解析についての解説や関連情報提供サイトを紹介しています。</strong>
                                                </p>{" "}
                                                <h3>
                                                    <em>fMRIの原理と装置について</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/" onClick={(e) => showVideo(e, "tutorial_japanese_MRI_comp", 480, 320)}>
                                                            fMRIについて（一般向け） <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                情報通信研究機構(NICT)の脳活動計測装置の動作原理と計測方法についての一般向けチュートリアル・ビデオからfMRIに関する部分を抜粋したものです。（全編は前項の「<a href="#neuroimg">ニューロイメージングとは？</a>」から視聴可能）（2004年作成）
                                                                <br />
                                                                （再生にダウンロードの必要はありません。）
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/" onClick={(e) => showVideo(e, "tutorial_student_MRI1m_comp", 480, 320)}>
                                                            fMRIについて（生徒向け） <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                情報通信研究機構(NICT)の脳活動計測装置の動作原理と計測方法についての生徒向けチュートリアル・ビデオからfMRIに関する部分を抜粋したものです。（全編は前項の「<a href="#neuroimg">ニューロイメージングとは？</a>」から視聴可能）（2004年作成）
                                                                <br />
                                                                （再生にダウンロードの必要はありません。）
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        fMRIの原理解説（文章資料）
                                                        <ul>
                                                            <li>作成中</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>fMRI研究用分析プログラムについて</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=272" target="_blank" rel="noopener noreferrer">
                                                            SPM (Statistical Parametric Mapping)
                                                        </a>
                                                        <ul>
                                                            <li>PET/fMRIの分析に用いられる総合的な無料ソフトウェアです。ただし、動作には数値演算ソフトであるMATLAB（有償）が必要です。ソフトウエアの完成度は高く、論文にも多数引用されています。また、頻繁にアップデートが行われており、メーリングリストも充実しています。</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=605" target="_blank" rel="noopener noreferrer">
                                                            BrainVoyager
                                                        </a>
                                                        <ul>
                                                            <li>PET/fMRIの分析に用いられる総合的な有料ソフトウェアです。上記SPMでは不可能な脳皮質の平面展開などが可能です。独立成分分析などの応用解析や信号源解析ソフトBESAとのリンクも可能です。</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>fMRIデータ解析トレーニング教材</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        fMRI解析トレーニング用データと解析マニュアル
                                                        <ul>
                                                            <li>fMRIデータを解析ソフトSPM8を用いて解析するための初学者向けのトレーニングのための教材です。</li>
                                                        </ul>
                                                        <h4>1: ブロックデザイン編</h4>
                                                        <ul>
                                                            <li>
                                                                <a href="/modules/xoonips/detail.php?item_id=4090">フォーマット変換マニュアル</a>
                                                                <ul>
                                                                    <li>MRIで良く用いられるファイル形式であるdicomファイルからSPM8で解析可能なNiftiフォーマットへの変換を行うためのマニュアルです。</li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <a href="/modules/xoonips/detail.php?item_id=4089">解析マニュアル</a>
                                                                <ul>
                                                                    <li>変換後のファイルで個人解析と集団解析を行うための実習マニュアルです。</li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <a href="/modules/xoonips/detail.php?item_id=4091">サンプルデータ</a>
                                                                <ul>
                                                                    <li>個人解析用のデータと集団解析用のデータが入っています。</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                        <h4>
                                                            2: イベントデザイン編 <span style={{ color: "#0000ff" }}>NEW!!</span>
                                                        </h4>
                                                        <ul>
                                                            <li>
                                                                <a href="/modules/xoonips/detail.php?item_id=5930">実験条件</a>
                                                                <ul>
                                                                    <li>定時刺激の種類と刺激呈示時刻などの実験条件が記載されています。</li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <a href="/modules/xoonips/detail.php?item_id=5931">解析マニュアル</a>
                                                                <ul>
                                                                    <li>サンプルデータで個人解析と集団解析を行うための実習マニュアルです。</li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <a href="/modules/xoonips/detail.php?item_id=5955">サンプルデータ</a>
                                                                <ul>
                                                                    <li>個人解析用のデータと集団解析用のデータが入っています。</li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <a href="/modules/xoonips/detail.php?item_id=5932">バッチファイル</a>
                                                                <ul>
                                                                    <li>個人・集団解析のためのバッチファイルが入っています。解析のパラメータ入力の答え合わせに使います。</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                        <p>
                                                            SPM8の公式マニュアルは<a href="http://www.fil.ion.ucl.ac.uk/spm/">こちら</a>から
                                                        </p>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>MRIの画像処理に便利なソフトウェア</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=604" target="_blank" rel="noopener noreferrer">
                                                            MRIcro
                                                        </a>
                                                        <ul>
                                                            <li>MRIを始めとするさまざまな医療画像を表示できる無料のソフトウェアです。</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">ページトップへ</a>
                    </div>
                    <fieldset id="meg">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/MEG.png" alt="MEG" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>脳磁計測法 (MEG)</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    <strong>脳磁計測法（Magnetoencephalography：MEG）の原理・装置・実験方法・解析についての解説や関連情報提供サイトを紹介しています。</strong>
                                                </p>{" "}
                                                <h3>
                                                    <em>MEGの原理と装置について</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/" onClick={(e) => showVideo(e, "tutorial_japanese_MEG_comp", 480, 320)}>
                                                            MEGについて（一般向け） <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                情報通信研究機構(NICT)の脳活動計測装置の動作原理と計測方法についての一般向けチュートリアル・ビデオからMEGに関する部分を抜粋したものです。（全編は前項の「<a href="#neuroimg">ニューロイメージングとは？</a>」から視聴可能）（2004年作成）
                                                                <br />
                                                                （再生にダウンロードの必要はありません。）
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/" onClick={(e) => showVideo(e, "tutorial_student_MEG1m_comp", 480, 320)}>
                                                            MEGについて（生徒向け） <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                情報通信研究機構(NICT)の脳活動計測装置の動作原理と計測方法についての生徒向けチュートリアル・ビデオからMEGに関する部分を抜粋したものです。（全編は前項の「<a href="#neuroimg">ニューロイメージングとは？</a>」から視聴可能）（2004年作成）
                                                                <br />
                                                                （再生にダウンロードの必要はありません。）
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/" onClick={(e) => togglePanel(e, "a")}>
                                                            脳機能工学 「MEG計測と解析」
                                                        </a>
                                                        <ul>
                                                            <li>北海道大学大学院・情報科学研究科（平成１８年度）で行われた講義を録画した動画です。各回90分の講義を分割して掲載しています。</li>
                                                            <li>
                                                                MEGでの脳波計測・解析に必要な超伝導とSQUID、細胞活動と磁場の発生の関係、ダイポール磁場について実際の解析時の信号処理、ノイズ除去について、高次解析に関する講義です。
                                                                {isOpenA0 && (
                                                                    <div>
                                                                        <ul>
                                                                            <li>
                                                                                <a href="/" onClick={(e) => togglePanel(e, "a1")}>
                                                                                    第１回超伝導とSQUID
                                                                                </a>
                                                                                <ul>
                                                                                    <li>脳磁場検出に用いられる、超伝導コイルとSQUID(超伝導量子干渉計）について。</li>
                                                                                </ul>
                                                                                {isOpenA1 && (
                                                                                    <div>
                                                                                        <ul>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "1superconduction_and_SQUID/1superconduction_and_SQUID", 640, 480)}>
                                                                                                    1. 超伝導とSQUID <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "1superconduction_and_SQUID/2use_a_superconduction_coil_for_the_detection", 640, 480)}>
                                                                                                    2. 脳磁場の検出には超伝導コイルを用いる <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "1superconduction_and_SQUID/3SQUID_is_a_film_element", 640, 480)}>
                                                                                                    3. SQUIDは薄膜素子 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "1superconduction_and_SQUID/4inserted_two_Josephson_joining_in_a_superconduct_on_ring", 640, 480)}>
                                                                                                    4. SQUIDは超伝導リングにJosephson接合（JJ）を2個挿入したもの <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "1superconduction_and_SQUID/5terminal_element", 640, 480)}>
                                                                                                    5. SQUIDは2端子素子 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "1superconduction_and_SQUID/6periodic_input_and_output-related", 640, 480)}>
                                                                                                    6. SQUIDは周期的な入出力関係（Φ-V伝達特性）をもつ <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "1superconduction_and_SQUID/7characteristic_decides_sensitivity", 640, 480)}>
                                                                                                    7. V-Φ特性の傾きが感度を決める <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "1superconduction_and_SQUID/8linearization_of_non-linear_input_and_output_relations", 640, 480)}>
                                                                                                    8. 非線形な入出力関係を線形化する <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "1superconduction_and_SQUID/9magnetic_flux_lock_circuit", 640, 480)}>
                                                                                                    9. 磁束ロック回路 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "1superconduction_and_SQUID/10current-voltage_characteristic_of_SQUID", 640, 480)}>
                                                                                                    10. SQUIDの電流−電圧（I-V）特性 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "1superconduction_and_SQUID/11Finally", 640, 480)}>
                                                                                                    11. 最後に <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                                <br />
                                                                                                <br />
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/modules/nimgdocs/tutorials/dai1kaikougishiryou.pdf" target="_blank" rel="noopener noreferrer">
                                                                                                    第１回講義資料（pdf）
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                            </li>
                                                                            <li>
                                                                                <a href="/" onClick={(e) => togglePanel(e, "a2")}>
                                                                                    第２回 細胞の活動と磁場の発生
                                                                                </a>
                                                                                <ul>
                                                                                    <li>細胞活動から、いかにして磁場が発生しているのか。</li>
                                                                                </ul>
                                                                                {isOpenA2 && (
                                                                                    <div>
                                                                                        <ul>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "2the_cell_and_the_magnetic_field/1electric_current_in_the_cell", 640, 480)}>
                                                                                                    1. 細胞内電流 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "2the_cell_and_the_magnetic_field/2The_characteristic_of_the_MEG_measurement", 640, 480)}>
                                                                                                    2. ＭＥＧ計測の特徴 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "2the_cell_and_the_magnetic_field/3The_constitution_of_the_measurement_system", 640, 480)}>
                                                                                                    3. 計測システムの構成 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                                <br />
                                                                                                <br />
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/modules/nimgdocs/tutorials/dai2kaikougishiryou.pdf" target="_blank" rel="noopener noreferrer">
                                                                                                    第２回講義資料（pdf）
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                            </li>
                                                                            <li>
                                                                                <a href="/" onClick={(e) => togglePanel(e, "a3")}>
                                                                                    第３回 ダイポール磁場の特性
                                                                                </a>
                                                                                <ul>
                                                                                    <li>ダイポール磁場などの双極性磁場について。</li>
                                                                                </ul>
                                                                                {isOpenA3 && (
                                                                                    <div>
                                                                                        <ul>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "3dipole_magnetic_field/1The_characteristic_of_the_MEG_signal", 640, 480)}>
                                                                                                    1. MEG信号の特徴 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "3dipole_magnetic_field/2Outbreak_source", 640, 480)}>
                                                                                                    2. 発生源 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "3dipole_magnetic_field/3Space_characteristic", 640, 480)}>
                                                                                                    3. 空間特性 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "3dipole_magnetic_field/4Comparison_with_the_brain_electric_potential", 640, 480)}>
                                                                                                    4. 脳電位(脳波)との比較. <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "3dipole_magnetic_field/5measurement_method", 640, 480)}>
                                                                                                    5. 計測法 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                                <br />
                                                                                                <br />
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/modules/nimgdocs/tutorials/dai3kaikougishiryou.pdf" target="_blank" rel="noopener noreferrer">
                                                                                                    第３回講義資料（pdf）
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                            </li>
                                                                            <li>
                                                                                <a href="/" onClick={(e) => togglePanel(e, "a4")}>
                                                                                    第４回信号処理と信号源推定
                                                                                </a>
                                                                                <ul>
                                                                                    <li>MEGでの脳波解析時の信号処理、ノイズ除去について。</li>
                                                                                </ul>
                                                                                {isOpenA4 && (
                                                                                    <div>
                                                                                        <ul>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "4Signal_processing_and_a_signal_source_estimate/1%2B2%2B3%2B4MEG_signal_processing", 640, 480)}>
                                                                                                    1. MEG信号処理 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "4Signal_processing_and_a_signal_source_estimate/5%2B6%2B7filter_and_Addition_average", 640, 480)}>
                                                                                                    2. 信号処理(フィルタと加算平均) <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "4Signal_processing_and_a_signal_source_estimate/8%2B9%2B10%2B11the_highly_advanced_function", 640, 480)}>
                                                                                                    3. 高次機能の解析における信号源推定 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "4Signal_processing_and_a_signal_source_estimate/12%2B13%2B14The_stimulation_synchronization_addition", 640, 480)}>
                                                                                                    4. 加算平均(刺激同期加算)波形 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "4Signal_processing_and_a_signal_source_estimate/15%2B16%2B17single_current_electric_dipole", 640, 480)}>
                                                                                                    5. 信号源推定(単一電流双極子) <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "4Signal_processing_and_a_signal_source_estimate/18%2B19%2B20%2B21Left_Hemisphere_and_Right_Hemisphere", 640, 480)}>
                                                                                                    6. 左脳と右脳の信号分析 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "4Signal_processing_and_a_signal_source_estimate/22%2B23Reverse_problem_elucidation", 640, 480)}>
                                                                                                    7. 逆問題解法の考え方 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "4Signal_processing_and_a_signal_source_estimate/24%2B25%2B26word_re-posture_problem", 640, 480)}>
                                                                                                    8. 単語再構課題 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "4Signal_processing_and_a_signal_source_estimate/27%2B28%2B29%2B30Occipital_and_Temporal", 640, 480)}>
                                                                                                    9. 課題に対する信号の分析 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                                <br />
                                                                                                <br />
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/modules/nimgdocs/tutorials/dai4kaikougishiryou.pdf" target="_blank" rel="noopener noreferrer">
                                                                                                    第４回講義資料（pdf）
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                            </li>
                                                                            <li>
                                                                                <a href="/" onClick={(e) => togglePanel(e, "a5")}>
                                                                                    第5回 言語を中心とする高次機能解析
                                                                                </a>
                                                                                <ul>
                                                                                    <li>言語や認知などの機能マッピングの解析に使われる、脳全体の解析手法について。</li>
                                                                                </ul>
                                                                                {isOpenA5 && (
                                                                                    <div>
                                                                                        <ul>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "5highly_advanced_functional_analysis/1%2B2The_understanding_of_the_sentence", 640, 480)}>
                                                                                                    1. 文の理解 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "5highly_advanced_functional_analysis/3%2B4Korean", 640, 480)}>
                                                                                                    2. 韓国語（日本語と同じ語順）意味と文法に関わる脳活動の探索 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "5highly_advanced_functional_analysis/5%2B6%2B7%2B8Measurement_of_tangential_components_of_field", 640, 480)}>
                                                                                                    3. Measurement of tangential components of field <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "5highly_advanced_functional_analysis/9%2B10%2B11%2B12%2B13search_of_brain_activity_about_grammar", 640, 480)}>
                                                                                                    4. 日本語文章を用いた実験意味と文法に関わる脳活動の探索 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "5highly_advanced_functional_analysis/14%2B15current_distribution_estimate", 640, 480)}>
                                                                                                    5. 電流分布推定 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "5highly_advanced_functional_analysis/16%2B17%2B18%2B19LORETA", 640, 480)}>
                                                                                                    6. LORETA法 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "5highly_advanced_functional_analysis/20%2B21%2B22L2_norm_method", 640, 480)}>
                                                                                                    7. L2ノルム法 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "5highly_advanced_functional_analysis/23%2B24%2B2524_electric_current_distribution_estimates", 640, 480)}>
                                                                                                    8. 電流分布推定のシミュレーション <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/" onClick={(e) => showVideo(e, "5highly_advanced_functional_analysis/26-31reading_and_understanding_of_the_Japanese_sentence", 640, 480)}>
                                                                                                    9. 日本語文の読解時の脳活動 <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                                                                </a>
                                                                                                <br />
                                                                                                <br />
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="/modules/nimgdocs/tutorials/dai5kaikougishiryou.pdf" target="_blank" rel="noopener noreferrer">
                                                                                                    第５回講義資料（pdf）
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </li>
                                                            <li>
                                                                講師 北海道大学電子科学研究所 栗城 眞也 （現 東京電機大学先端工学研究所）
                                                                <br />
                                                                （教育担当：情報科学研究科・生命人間情報科学専攻）
                                                                <br />
                                                                作成：CEED 高氏秀則
                                                            </li>
                                                            <li>
                                                                <span style={{ color: "#ff0000" }}>注意事項：この動画ファイルはダウンロードできません。</span>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/nimgdocs/tutorials/noukinoukensa.pdf" target="_blank" rel="noopener noreferrer">
                                                            脳機能検査　「脳磁図の計測と解析」 （pdf）
                                                        </a>
                                                        <ul>
                                                            <li>脳磁図の計測原理、方法、解析法の基礎に関する解説がのっています。</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/nimgdocs/tutorials/slide.html" target="_blank" rel="noopener noreferrer">
                                                            北海道大学 脳科学研究教育センター 実習資料 「MEG計測と解析」（パワーポイント）
                                                        </a>
                                                        <ul>
                                                            <li>SQUIDの原理からMEG計測法，解析法，信号源推定までをやさしく解説してあります。</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>MEG研究用分析プログラムについて</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1494" target="_blank" rel="noopener noreferrer">
                                                            BESA
                                                        </a>
                                                        <ul>
                                                            <li>EEGおよびMEGの分析に用いられる有料ソフトウェアです。信号源解析（いわゆるダイポール推定）が可能なソフトウェアです。</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1497" target="_blank" rel="noopener noreferrer">
                                                            BrainStorm
                                                        </a>
                                                        <ul>
                                                            <li>EEGおよびMEGの分析に用いられる無料ソフトウェアです。様々な信号源解析が可能です。</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1325" target="_blank" rel="noopener noreferrer">
                                                            EEGLAB
                                                        </a>
                                                        <ul>
                                                            <li>EEGおよびMEGの分析に用いられる無料ソフトウェアです。各種フィルターやアーチファクト処理などの前処理から加算平均・周波数分析・独立成分分析法まで豊富な解析を行うことができます。</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1495" target="_blank" rel="noopener noreferrer">
                                                            FieldTrip
                                                        </a>
                                                        <ul>
                                                            <li>EEGおよびMEGの分析に用いられる無料ソフトウエアです。前処理から加算平均や周波数分析などの解析が可能です。また複数の信号源解析も可能です。</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=272" target="_blank" rel="noopener noreferrer">
                                                            SPM
                                                        </a>
                                                        <ul>
                                                            <li>PET/fMRIの分析に用いられて普及しているSPMが、EEG・MEGに対応しました。SPM8では、FieldTripに依存したソフトウエアになっています。前処理・加算平均・信号源推定などが可能です。2009年3月時点で、まだ開発中の部分がありますのでそれを踏まえてお使いください。</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">ページトップへ</a>
                    </div>
                    <fieldset id="nirs">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/NIRS.png" alt="NIRS" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>近赤外線分光法 (NIRS)</h2>
                                        <ul>
                                            <li>
                                                <strong>近赤外線分光法（Near Infrared Spectroscopy：NIRS）の原理・装置・実験方法・解析についての解説や関連情報提供サイトを紹介しています。</strong>
                                                <br />
                                                <br />
                                                <h3>
                                                    <em>NIRSの原理と装置について</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/" onClick={(e) => showVideo(e, "tutorial_japanese_NIRS_comp", 480, 320)}>
                                                            NIRSについて（一般向け） <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                情報通信研究機構(NICT)の脳活動計測装置の動作原理と計測方法についての一般向けチュートリアル・ビデオからNIRSに関する部分を抜粋したものです。（全編は前項の「<a href="#neuroimg">ニューロイメージングとは？</a>」から視聴可能）（2004年作成）
                                                                <br />
                                                                （再生にダウンロードの必要はありません。）
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/" onClick={(e) => showVideo(e, "tutorial_student_NIRS1m_comp", 480, 320)}>
                                                            NIRSについて（生徒向け） <img src="/modules/nimgdocs/tutorials/pic/tv.gif" alt="動画アイコン" style={{ width: "18", height: "18" }} />
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                情報通信研究機構(NICT)の脳活動計測装置の動作原理と計測方法についての生徒向けチュートリアル・ビデオからNIRSに関する部分を抜粋したものです。（全編は前項の「<a href="#neuroimg">ニューロイメージングとは？</a>」から視聴可能）（2004年作成）
                                                                <br />
                                                                （再生にダウンロードの必要はありません。）
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/nimgdocs/tutorials/slide2.html" target="_blank" rel="noopener noreferrer">
                                                            NIRSの背景と基礎（パワーポイント）
                                                        </a>
                                                        <ul>
                                                            <li>NIRSの原理が解説してあります。</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>NIRS研究用分析プログラムについて</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1496" target="_blank" rel="noopener noreferrer">
                                                            NIRS-SPM
                                                        </a>
                                                        <ul>
                                                            <li>PET/fMRIの分析で用いられるSPMに基づくNIRS分析用のプログラムです。</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">ページトップへ</a>
                    </div>
                    <fieldset id="eeg">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/EEG.png" alt="EEG" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>脳波計測法 (EEG)</h2>
                                        <ul>
                                            <li>
                                                <strong>脳波計測法（Electroencephalography：EEG）の原理・装置・実験方法・解析についての解説や関連情報提供サイトを紹介しています。</strong>
                                                <br />
                                                <br />
                                                <h3>
                                                    <em>EEGの原理と装置について</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        EEGについて
                                                        <ul>
                                                            <li>作成中</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>EEG研究用分析プログラムについて</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1494" target="_blank" rel="noopener noreferrer">
                                                            BESA
                                                        </a>
                                                        <ul>
                                                            <li>EEGおよびMEGの分析に用いられる有料ソフトウェアです。信号源解析（いわゆるダイポール推定）が可能なソフトウェアです。</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1497" target="_blank" rel="noopener noreferrer">
                                                            BrainStorm
                                                        </a>
                                                        <ul>
                                                            <li>EEGおよびMEGの分析に用いられる無料ソフトウェアです。様々な信号源解析が可能です。</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1325" target="_blank" rel="noopener noreferrer">
                                                            EEGLAB
                                                        </a>
                                                        <ul>
                                                            <li>EEGおよびMEGの分析に用いられる無料ソフトウェアです。各種フィルターやアーチファクト処理などの前処理から加算平均・周波数分析・独立成分分析法まで豊富な解析を行うことができます。</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1495" target="_blank" rel="noopener noreferrer">
                                                            FieldTrip
                                                        </a>
                                                        <ul>
                                                            <li>EEGおよびMEGの分析に用いられる無料ソフトウエアです。前処理から加算平均や周波数分析などの解析が可能です。また複数の信号源解析も可能です。</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=272" target="_blank" rel="noopener noreferrer">
                                                            SPM
                                                        </a>
                                                        <ul>
                                                            <li>PET/fMRIの分析に用いられて普及しているSPMが、EEG・MEGに対応しました。SPM8では、FieldTripに依存したソフトウエアになっています。前処理・加算平均・信号源推定などが可能です。2009年3月時点で、まだ開発中の部分がありますのでそれを踏まえてお使いください。</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">ページトップへ</a>
                    </div>
                    <fieldset id="stim">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/Control.png" alt="実験制御" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>実験制御</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    <strong>ニューロイメージングでは、視覚刺激・聴覚刺激の呈示、被験者の反応収集などが必要です。ここでは、そのためのソフトウェアの紹介をしています。</strong>
                                                </p>{" "}
                                                <h3>
                                                    <em>汎用刺激呈示ソフトウェア</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=606" target="_blank" rel="noopener noreferrer">
                                                            Presentation
                                                        </a>
                                                        <ul>
                                                            <li>fMRI・MEG・NIRS・EEGなどに対応可能なマルチメディア刺激呈示制御ソフトウェアです。被験者の反応収集も可能です。</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=273" target="_blank" rel="noopener noreferrer">
                                                            Cogent
                                                        </a>
                                                        <ul>
                                                            <li>fMRI・MEGなどに対応可能なソフトウェアです。特に視覚刺激呈示を精度よくリアルタイムで制御するために用いられます。</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=607" target="_blank" rel="noopener noreferrer">
                                                            Psychophysics Toolbox
                                                        </a>
                                                        <ul>
                                                            <li>Matlab上で動く刺激呈示制御用ソフトウェアです。</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">ページトップへ</a>
                    </div>
                    <fieldset id="soft">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/Software.png" alt="ソフトウェア" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>ソフトウェア</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    <strong>ニューロイメージングの研究推進には、さまざまな画像処理や信号処理を使います。ここでは、そのためのソフトウェアの紹介をしています。</strong>
                                                </p>{" "}
                                                <h3>
                                                    <em>汎用科学技術数値計算用ソフトウェア</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=274" target="_blank" rel="noopener noreferrer">
                                                            MATLAB
                                                        </a>
                                                        <ul>
                                                            <li>SPM, EEGLAB, BrainStorm, Cogent, Psychophysics Toolboxなどのソフトウェアを利用するために必要です。</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">ページトップへ</a>
                    </div>
                    <fieldset id="basic">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/Textbook.png" alt="基礎知識" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>自習用教科書</h2>
                                        <ul>
                                            <li>
                                                <strong>ニューロイメージングは総合科学です。したがって、その研究には、神経科学、解剖学、生理学、心理学、統計学などの知識を始めとする、様々な基礎知識が必要です。ここでは、その基礎知識を得るために役立つ、入門書や参考書を紹介しています。</strong>
                                                <br />
                                                <br />
                                                <h3>
                                                    <em>神経科学の入門書（和書）</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1489" target="_blank" rel="noopener noreferrer">
                                                            ニューロサイエンス入門
                                                        </a>
                                                        <ul>
                                                            <li>松村道一 著</li>
                                                            <li>
                                                                サイエンス社
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;本書は、神経科学の全般について書かれた日本語の入門書です。初めてニューロイメージングに取り組む学徒が、まずその基礎である神経科学の知識を習得するのに役立ちます。
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1490" target="_blank" rel="noopener noreferrer">
                                                            脳百話―動きの仕組みを解き明かす
                                                        </a>
                                                        <ul>
                                                            <li>松村道一・小田伸午・石原昭彦 編著</li>
                                                            <li>
                                                                市村出版
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;神経科学の入門書ですが、啓蒙書的な書き方がしてあります。上の本を難しいと感じた学徒に薦めます。
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=767" target="_blank" rel="noopener noreferrer">
                                                            カールソン神経科学テキスト―脳と行動
                                                        </a>
                                                        <ul>
                                                            <li>NEIL R.CARLSON 著、中村克樹・泰羅雅登 翻訳</li>
                                                            <li>
                                                                丸善
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;前書に比べ本格的な訳本の教科書ですが、総合的に神経科学を学ぶことができます。行動と脳の関連性というニューロイメージングにとってありがたい視点から書かれています。また豊富なカラー図が理解を助けてくれます。
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>神経科学の入門書（洋書）</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=591" target="_blank" rel="noopener noreferrer">
                                                            Principles of Neural Science
                                                        </a>
                                                        <ul>
                                                            <li>Eric R. Kandel ・ James H. Schwartz ・ Thomas M. Jessell 編集</li>
                                                            <li>
                                                                Appleton &amp; Lange
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;本書は、英文で書かれた標準的な神経科学の教科書です。総合的に神経科学を学ぶことができます。1人で読破すると言うよりは、仲間たちとゼミ型式で読むのに向いています。なお2010年に第5版が発売予定とのことです。
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1491" target="_blank" rel="noopener noreferrer">
                                                            Essentials of Neural Science and Behavior
                                                        </a>
                                                        <ul>
                                                            <li>Eric R. Kandel 著</li>
                                                            <li>
                                                                Mcgraw-Hill
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;Principles of Neural Scienceと同じ著者による英文の教科書ですが、Principles of Neural Scienceより項目が絞られており、また平易に書かれています。なお2009年に新版が発売予定です。
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1492" target="_blank" rel="noopener noreferrer">
                                                            Cognitive Neuroscience: The Biology of the Mind
                                                        </a>
                                                        <ul>
                                                            <li>Michael S. Gazzaniga ・ Richard B. Ivry ・ G. R. Mangun 著</li>
                                                            <li>
                                                                W W Norton &amp; Co Inc
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;英文で書かれた認知神経科学の教科書です。Principles of Neural Scienceに比べて生理学・生物学的な神経科学の項目が少なく、その分、心理学・認知科学におけるニューロイメージングの話題に紙面が割かれています。最新の報告も多く紹介されており、ニューロイメージングが最も威力を発揮している認知神経科学分野を知るのに最適な教科書です。
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h3>
                                                    <em>ニューロイメージングの入門書（洋書）</em>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=356" target="_blank" rel="noopener noreferrer">
                                                            Functional Magnetic Resonance Imaging
                                                        </a>
                                                        <ul>
                                                            <li>Scott A. Huettel ・ Allen W. Song ・ Gregory McCarthy 著</li>
                                                            <li>
                                                                Sinauer Associates Inc
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;英文で書かれた機能的磁気共鳴画像法によるニューロイメージングの入門書です。MRIの原理から始まり、実験方法から解析まで、本書1冊で全て勉強できます。神経科学の基礎的な知識を身につけた後で、いよいよニューロイメージングを実践する際に最適な教科書です。
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1493" target="_blank" rel="noopener noreferrer">
                                                            Functional Mri: An Introduction to Methods
                                                        </a>
                                                        <ul>
                                                            <li>Peter Jezzard ・ Paul M. Matthews ・ Stephen M. Smith 編集</li>
                                                            <li>
                                                                Oxford University Press
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;前書に比べ少し高度かつ専門的な機能的磁気共鳴画像法によるニューロイメージングの英文教科書です。
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">ページトップへ</a>
                    </div>
                    <fieldset id="training course">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle", textAlign: "center", width: "10%" }}>
                                        <img src="/modules/nimgdocs/tutorials/pic/Training%20Course.png" alt="講習会" style={{ width: "90px", height: "70px" }} />
                                    </td>
                                    <td style={{ width: "90%" }}>
                                        <h2>講習会</h2>
                                        <ul>
                                            <li>
                                                <strong>初学者が知識や技術を高めるための講習会が数多く行われています。</strong>
                                                <br />
                                                <br />
                                                <ul>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=394" target="_blank" rel="noopener noreferrer">
                                                            脳活動イメージングセンタ ( BAIC )
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                刺激呈示プログラミング・脳機能画像解析講習会
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;ニューロイメージングに関する講習会を定期的に行っています。
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1505" target="_blank" rel="noopener noreferrer">
                                                            生理学研究所
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                生理科学実験技術トレーニングコース
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;fMRIとMEGのトレーニングコースがあります。申し込み開始日なども掲載されています。
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1506" target="_blank" rel="noopener noreferrer">
                                                            オックスフォード大学
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                FSL &amp; FreeSurfer Course
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;FSL(FMRIB SOFTWARE LIBRARY)とFreeSurferに関する講習会のスライドが公開されています。
                                                                <br />
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/modules/xoonips/detail.php?item_id=1507" target="_blank" rel="noopener noreferrer">
                                                            SPMコース
                                                        </a>
                                                        <ul>
                                                            <li>
                                                                SPM開発チームによる講習会
                                                                <br />
                                                                &nbsp;&nbsp;&nbsp;SPM (Statistical Parametric Mapping) に関する講習会のスライドが公開されています。
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <div style={{ textAlign: "right", margin: "0 0 25px" }}>
                        <a href="#top">ページトップへ</a>
                    </div>
                </>
            ),
        };
        return contents[lang];
    };

    return (
        <>
            <Helmet>
                <title>Tutorials - NIMG Documents - {Functions.siteTitle(lang)}</title>
            </Helmet>
            {getContent()}
            <Modal style={styleOverlay} onClick={() => setIsShowVideo(false)} show={isShowVideo} onHide={() => setIsShowVideo(false)} renderBackdrop={() => <div style={styleBackdrop}></div>}>
                <div style={styleDialog} onClick={(e) => e.stopPropagation()}>
                    <span style={styleClose} className="close" onClick={() => setIsShowVideo(false)}>
                        &times;
                    </span>
                    <video width={videoWidth} height={videoHeight} poster={`movie/${videoName}.png`} onContextMenu={(e) => e.preventDefault()} controls>
                        <source src={`movie/${videoName}.mp4`} type="video/mp4" />
                        <source src={`movie/${videoName}.webm`} type="video/webm" />
                    </video>
                </div>
            </Modal>
        </>
    );
};

export default NimgdocsTutorials;
