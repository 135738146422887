import React from "react";
import { MultiLang } from "../../../config";
import { ItemPresentationSubType, ItemPresentationSubTypes } from "../../lib/ItemUtil";

interface PresentationTypeProps {
    lang: MultiLang;
    type: ItemPresentationSubType;
}

const PresentationType: React.FC<PresentationTypeProps> = (props: PresentationTypeProps) => {
    const { type } = props;
    const subtype = ItemPresentationSubTypes.find((value) => {
        return value.type === type;
    });
    if (typeof subtype === "undefined") {
        return null;
    }
    return <span>{subtype.label}</span>;
};

const PresentationUtil = {
    PresentationType,
};

export default PresentationUtil;
