import React from "react";
import { MultiLang } from "../../../../config";

interface Props {
    lang: MultiLang;
    size: number;
}

const FileSize: React.FC<Props> = (props: Props) => {
    const { size } = props;
    const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const power = size > 0 ? Math.floor(Math.log(size) / Math.log(1024)) : 0;
    const label = Math.round((size / Math.pow(1024, power)) * 10) / 10 + " " + units[power];
    return <span>{label}</span>;
};

export default FileSize;
