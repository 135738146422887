import DataAdvancedSearch from "./DataAdvancedSearch";
import DataDetail from "./DataDetail";
import DataList from "./DataList";
import DataTop from "./DataTop";

const ItemTypeData = {
    Top: DataTop,
    List: DataList,
    Detail: DataDetail,
    AdvancedSearch: DataAdvancedSearch,
};

export default ItemTypeData;
