import { Link } from "react-router-dom";
import Functions from "../../../functions";
import iconFile from "../../assets/images/icon_nimgcenter.gif";
import { ItemNimgcenter } from "../../lib/ItemUtil";
import ListBase, { ListBaseProps } from "../lib/ListBase";

class NimgcenterList extends ListBase {
    constructor(props: ListBaseProps) {
        super(props);
        this.label = "Nimgcenter";
        this.icon = iconFile;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemNimgcenter;
        return (
            <>
                <Link to={this.url}>{Functions.mlang(item.title, lang)}</Link>
                <br />
                Base-Item Type: {item.base_type_display_name}
                <br />
                {item.publication_year};
            </>
        );
    }
}

export default NimgcenterList;
