import ConferenceAdvancedSearch from "./ConferenceAdvancedSearch";
import ConferenceDetail from "./ConferenceDetail";
import ConferenceList from "./ConferenceList";
import ConferenceTop from "./ConferenceTop";

const ItemTypeConference = {
    Top: ConferenceTop,
    List: ConferenceList,
    Detail: ConferenceDetail,
    AdvancedSearch: ConferenceAdvancedSearch,
};

export default ItemTypeConference;
