import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { MultiLang } from "../../../config";
import Functions from "../../../functions";
import ItemUtil, { ItemSubTypes } from "../../lib/ItemUtil";

export interface TopBaseProps {
    lang: MultiLang;
}

class TopBase extends React.Component<TopBaseProps> {
    protected type: string = "";
    protected label: string = "";
    protected icon: string = "";
    protected description: string = "";
    protected subTypes: ItemSubTypes<any> = [];

    render() {
        const { lang } = this.props;
        const url = ItemUtil.getItemTypeSearchUrl(this.type, "");
        const links = this.subTypes.map(({ type, label }, i) => {
            const subUrl = ItemUtil.getItemTypeSearchUrl(this.type, type);
            return (
                <Fragment key={i}>
                    {i > 0 && " / "}
                    <Link to={subUrl}>{label}</Link>
                </Fragment>
            );
        });
        return (
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <img src={this.icon} alt={this.label} />
                            </td>
                            <td className="itemTypeName">
                                <Link to={url}>{this.label}</Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr />
                <div>{Functions.mlang(this.description, lang)}</div>
                {links}
            </div>
        );
    }
}

export default TopBase;
