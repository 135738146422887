import React from "react";
import { MultiLang } from "../../../../config";
import Functions from "../../../../functions";
import { ItemBasicChangeLog } from "../../../lib/ItemUtil";
import DateTime from "./DateTime";

interface Props {
    lang: MultiLang;
    changelog: ItemBasicChangeLog[];
}

const ChangeLog: React.FC<Props> = (props: Props) => {
    const { lang, changelog } = props;
    if (changelog.length === 0) {
        return null;
    }
    const elements = changelog.map((value, i) => {
        return (
            <tr key={i}>
                <td>
                    <DateTime lang={lang} date={value.log_date} onlyDate={true} />
                </td>
                <td>{Functions.mlang(value.log, lang)}</td>
            </tr>
        );
    });
    return (
        <table>
            <tbody>{elements}</tbody>
        </table>
    );
};

export default ChangeLog;
