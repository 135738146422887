import React from "react";
import { MultiLang } from "../../config";
import Functions from "../../functions";

interface Props {
    lang: MultiLang;
}

const RecentStatus: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = "[en]NIMG-PF recent status[/en][ja]NIMG-PF更新情報[/ja]";
    return (
        <div className="block">
            <div className="blockTitle">{Functions.mlang(title, lang)}</div>
            <div className="blockContent"></div>
        </div>
    );
};

export default RecentStatus;
