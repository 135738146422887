import StimulusAdvancedSearch from "./StimulusAdvancedSearch";
import StimulusDetail from "./StimulusDetail";
import StimulusList from "./StimulusList";
import StimulusTop from "./StimulusTop";

const ItemTypeStimulus = {
    Top: StimulusTop,
    List: StimulusList,
    Detail: StimulusDetail,
    AdvancedSearch: StimulusAdvancedSearch,
};

export default ItemTypeStimulus;
