import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../common/lib/Loading";
import PageNotFound from "../../common/lib/PageNotFound";
import { MultiLang } from "../../config";
import Functions from "../../functions";
import CreditsUtils, { CreditsMenuData } from "../lib/CreditsUtils";

interface Props {
    lang: MultiLang;
}

const CreditsMenu: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const [loading, setLoading] = useState<boolean>(true);
    const [menu, setMenu] = useState<CreditsMenuData[] | null>(null);

    useEffect(() => {
        const updatePage = async () => {
            const menu = await CreditsUtils.getMenu();
            setLoading(false);
            setMenu(menu);
        };
        updatePage();
    }, [lang]);

    if (loading) {
        return <Loading />;
    }
    if (menu === null || menu.length === 0) {
        return <PageNotFound lang={lang} />;
    }
    const links = menu.map((item, idx) => {
        const title = Functions.mlang(item.title, lang);
        const style = idx === 0 ? "menuTop" : "menuMain";
        return (
            <li key={idx}>
                <Link className={style} to={item.link}>
                    {title}
                </Link>
            </li>
        );
    });
    return <ul className="mainmenu">{links}</ul>;
};

export default CreditsMenu;
