import React from "react";
import { MultiLang } from "../../config";
import Functions from "../../functions";

interface Props {
    lang: MultiLang;
}

const NoticeSiteHasBeenArchived: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const notice = "[en]This site has been archived since FY2019 and is no longer updated.[/en][ja]このサイトは、2019年度よりアーカイブサイトとして運用されています。[/ja]";
    return <p style={{ color: "red" }}>{Functions.mlang(notice, lang)}</p>;
};

export default NoticeSiteHasBeenArchived;
