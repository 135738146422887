import React from "react";
import { MultiLang } from "../../../config";
import { ItemSimulatorSubType, ItemSimulatorSubTypes } from "../../lib/ItemUtil";

interface SimulatorTypeProps {
    lang: MultiLang;
    type: ItemSimulatorSubType;
}

const SimulatorType: React.FC<SimulatorTypeProps> = (props: SimulatorTypeProps) => {
    const { type } = props;
    const subtype = ItemSimulatorSubTypes.find((value) => {
        return value.type === type;
    });
    if (typeof subtype === "undefined") {
        return null;
    }
    return <span>{subtype.label}</span>;
};

const SimulatorUtil = {
    SimulatorType,
};

export default SimulatorUtil;
