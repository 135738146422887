import AdvancedSearchBase, { AdvancedSearchBaseProps } from "../lib/AdvancedSearchBase";

class PaperAdvancedSearch extends AdvancedSearchBase {
    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = "paper";
        this.title = "Paper";
        const keys = ["pubmed_id", "title", "keyword", "description", "doi", "author", "journal", "publication_year", "volume", "number", "page"];
        const show = props.query.hasType(this.type);
        const values: any = {};
        keys.forEach((key) => (values[key] = props.query.get(this.type, key)));
        this.state = { show, values };
    }

    getRows() {
        const rows = [
            { label: "PubMed ID", value: this.renderFieldInputText("pubmed_id", 50) },
            { label: "[en]Title[/en][ja]タイトル[/ja]", value: this.renderFieldInputText("title", 50) },
            { label: "[en]Free Keywords[/en][ja]フリーキーワード[/ja]", value: this.renderFieldInputText("keyword", 50) },
            { label: "[en]Description[/en][ja]概要[/ja]", value: this.renderFieldInputText("description", 50) },
            { label: "ID", value: this.renderFieldInputText("doi", 50) },
            { label: "[en]Author[/en][ja]著者[/ja]", value: this.renderFieldInputText("author", 50) },
            { label: "[en]Journal[/en][ja]ジャーナル[/ja]", value: this.renderFieldInputText("journal", 50) },
            { label: "[en]Publication Year[/en][ja]出版年[/ja]", value: this.renderFieldInputText("publication_year", 10) },
            { label: "[en]Volume[/en][ja]巻[/ja]", value: this.renderFieldInputText("volume", 50) },
            { label: "[en]Number[/en][ja]号[/ja]", value: this.renderFieldInputText("number", 50) },
            { label: "[en]Page[/en][ja]ページ[/ja]", value: this.renderFieldInputText("page", 50) },
        ];
        return rows;
    }
}

export default PaperAdvancedSearch;
