import React from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { MultiLang } from "../config";
import imageBannerNiu from "./assets/images/banner-niu.png";
import imageBannerRikenCbs from "./assets/images/banner-riken-cbs.png";
import imageBannerRiken from "./assets/images/banner-riken.png";
import imageBannerXoonips from "./assets/images/banner-xoonips.png";

interface Props {
    lang: MultiLang;
}

const Footer: React.FC<Props> = (props: Props) => {
    const location = useLocation();
    return (
        <footer id="footer">
            <div className="pageTop">
                <HashLink to={`${location.pathname}${location.search}#page`}>
                    <span className="hidden">Go Page Top</span>
                </HashLink>
            </div>
            <div className="link">
                <span>
                    <a href="http://www.riken.jp/" title="RIKEN" target="_blank" rel="noopener noreferrer">
                        <img src={imageBannerRiken} alt="RIKEN" />
                    </a>
                </span>
                <span>
                    <a href="https://cbs.riken.jp/" title="RIKEN Center for Brain Science" target="_blank" rel="noopener noreferrer">
                        <img src={imageBannerRikenCbs} alt="RIKEN Center for Brain Science" />
                    </a>
                </span>
                <span>
                    <a href="https://www.ni.riken.jp/" title="Neuroinformatics Unit, RIKEN Center for Brain Science" target="_blank" rel="noopener noreferrer">
                        <img src={imageBannerNiu} alt="Neuroinformatics Unit, RIKEN Center for Brain Science" />
                    </a>
                </span>
                <span>
                    <a href="http://xoonips.osdn.jp/" title="XooNIps" target="_blank" rel="noopener noreferrer">
                        <img src={imageBannerXoonips} alt="XooNIps" />
                    </a>
                </span>
            </div>
            <div className="copyright">
                <span>Copyright (C) 2018 Neuroinformatics Unit, RIKEN Center for Brain Science</span>
            </div>
        </footer>
    );
};

export default Footer;
