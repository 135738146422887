import React from "react";
import { MultiLang } from "../../../../config";
import DateTime from "./DateTime";

interface Props {
    lang: MultiLang;
    year: number;
    month: number;
    mday: number;
}

const PublicationDate: React.FC<Props> = (props: Props) => {
    const { lang, year, month, mday } = props;
    const d = new Date(year + "-" + month + "-" + mday);
    const timestamp = Math.floor(d.valueOf() / 1000);
    return <DateTime lang={lang} date={timestamp} onlyDate={true} />;
};

export default PublicationDate;
