import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { MultiLang } from "../../config";
import Functions from "../../functions";

interface Props {
    lang: MultiLang;
}

const PageNotFound: React.FC<Props> = (props) => {
    const { lang } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const url = "/";

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (location.pathname !== "/" && process.env.NODE_ENV === "production") {
            if (timer === null) {
                timer = setTimeout(() => {
                    navigate(url);
                }, 5000);
            }
        }
        return () => {
            if (timer !== null) {
                clearTimeout(timer);
                timer = null;
            }
        };
    }, [location, navigate]);

    return (
        <div>
            <Helmet>
                <title>Page Not Found - {Functions.siteTitle(lang)}</title>
            </Helmet>
            <h1>Page Not Found</h1>
            <section>
                <p>The page you were trying to access doesn't exist.</p>
                <p>
                    If the page does not automatically reload, please click <a href={url}>here</a>
                </p>
            </section>
        </div>
    );
};

export default PageNotFound;
