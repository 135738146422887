import { ItemNimgcenterBrainCoordinate } from "../../lib/ItemUtil";

const renderBrainCoodinates = (coordinates: ItemNimgcenterBrainCoordinate[]): string => {
    return coordinates
        .map((coordinate: ItemNimgcenterBrainCoordinate) => {
            return `${coordinate.x},${coordinate.y},${coordinate.z}`;
        })
        .join(": ");
};

const NimgcenterUtil = {
    renderBrainCoodinates,
};

export default NimgcenterUtil;
