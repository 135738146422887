import NimgcenterAdvancedSearch from "./NimgcenterAdvancedSearch";
import NimgcenterDetail from "./NimgcenterDetail";
import NimgcenterList from "./NimgcenterList";
import NimgcenterTop from "./NimgcenterTop";

const ItemTypeNimgcenter = {
    Top: NimgcenterTop,
    List: NimgcenterList,
    Detail: NimgcenterDetail,
    AdvancedSearch: NimgcenterAdvancedSearch,
};

export default ItemTypeNimgcenter;
