import iconFile from "../../assets/images/icon_simulator.gif";
import { ItemSimulatorSubTypes } from "../../lib/ItemUtil";
import TopBase, { TopBaseProps } from "../lib/TopBase";

class SimulatorTop extends TopBase {
    constructor(props: TopBaseProps) {
        super(props);
        this.type = "simulator";
        this.label = "Simulator";
        this.icon = iconFile;
        this.description = "[en]Programs/scripts for simulation.[/en][ja]シミュレーション用プログラム/スクリプト[/ja]";
        this.subTypes = ItemSimulatorSubTypes;
    }
}

export default SimulatorTop;
