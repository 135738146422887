import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MultiLang } from "../config";
import AdvancedSearchQuery from "./lib/AdvancedSearchQuery";
import ItemUtil, { SearchCallbackFunc, SortCondition } from "./lib/ItemUtil";
import XoonipsListItem from "./lib/XoonipsListItem";

interface Props {
    lang: MultiLang;
}

const XoonipsSearchByAdvancedKeyword: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const location = useLocation();
    const [query, setQeury] = useState<AdvancedSearchQuery>(ItemUtil.getAdvancedSearchQueryByQuery(location.search));
    const baseUrl = ItemUtil.getSearchByAdvancedKeywordsUrl(query);

    useEffect(() => {
        const query = ItemUtil.getAdvancedSearchQueryByQuery(location.search);
        setQeury(query);
    }, [location]);

    const searchFunc = (condition: SortCondition, func: SearchCallbackFunc) => {
        ItemUtil.getListByAdvancedSearchQuery(query, condition, func);
    };

    return (
        <div className="list">
            <h3>Listing item</h3>
            <XoonipsListItem lang={lang} url={baseUrl} search={searchFunc} />
        </div>
    );
};

export default XoonipsSearchByAdvancedKeyword;
