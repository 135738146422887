import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../common/lib/Loading";
import { MultiLang } from "../../config";
import Functions from "../../functions";
import imageStar from "../assets/images/star.gif";
import ItemUtil, { ItemCore } from "../lib/ItemUtil";
import styles from "./Rankings.module.css";

interface RankingsData {
    accessed?: {
        item_id: number;
        doi: string;
        title: string;
        count: number;
    }[];
    downloaded?: {
        item_id: number;
        doi: string;
        title: string;
        count: number;
    }[];
    contributed?: {
        uid: number;
        uname: string;
        name: string;
        count: number;
    }[];
    searched?: {
        keyword: string;
        count: number;
    }[];
}

interface Props {
    lang: MultiLang;
}

const Rankings: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const [loading, setLoading] = useState<boolean>(true);
    const [rankings, setRankings] = useState<RankingsData | null>(null);

    useEffect(() => {
        const updatePage = async () => {
            try {
                const response = await axios.get("/modules/xoonips/rankings.json");
                const rankings = response.status === 200 ? (response.data as RankingsData) : null;
                setRankings(rankings);
            } catch (e) {
                setRankings(null);
            }
            setLoading(false);
        };
        updatePage();
    }, []);

    const orderLabel = (order: number, lang: string) => {
        return String(order) + (lang === "en" ? Functions.ordinal(order) : "位");
    };

    if (loading) {
        return <Loading />;
    }
    if (rankings === null) {
        return <div>Failed to get data resource.</div>;
    }

    const items = [];
    if (rankings.accessed) {
        items.push({
            title: "[en]Frequently accessed items[/en][ja]頻繁に閲覧されるアイテム[/ja]",
            list: rankings.accessed.map((item, idx) => {
                const url = ItemUtil.getUrl(item as ItemCore);
                const title = Functions.mlang(item.title, lang);
                const order = orderLabel(idx + 1, lang);
                return (
                    <div key={idx} className={styles.item}>
                        <div className={styles.order}>{order}</div>
                        <div className={styles.title}>
                            <Link to={url} title={title}>
                                {title}
                            </Link>
                        </div>
                        {idx === 0 && (
                            <div className={styles.star}>
                                <img src={imageStar} alt={order} />
                            </div>
                        )}
                    </div>
                );
            }),
        });
    }
    if (rankings.downloaded) {
        items.push({
            title: "[en]Frequently downloaded items[/en][ja]頻繁にダウンロードされるアイテム[/ja]",
            list: rankings.downloaded.map((item, idx) => {
                const url = ItemUtil.getUrl(item as ItemCore);
                const title = Functions.mlang(item.title, lang);
                const order = orderLabel(idx + 1, lang);
                return (
                    <div key={idx} className={styles.item}>
                        <div className={styles.order}>{order}</div>
                        <div className={styles.title}>
                            <Link to={url} title={title}>
                                {title}
                            </Link>
                        </div>
                        {idx === 0 && (
                            <div className={styles.star}>
                                <img src={imageStar} alt={order} />
                            </div>
                        )}
                    </div>
                );
            }),
        });
    }
    if (rankings.contributed) {
        items.push({
            title: "[en]Most active contributers[/en][ja]最も多くアイテムを公開したユーザ[/ja]",
            list: rankings.contributed.map((item, idx) => {
                const name = item.name !== "" ? item.name + " (" + item.uname + ")" : item.uname;
                const title = Functions.mlang(name, lang);
                const order = orderLabel(idx + 1, lang);
                return (
                    <div key={idx} className={styles.item}>
                        <div className={styles.order}>{order}</div>
                        <div className={styles.uname}>{title}</div>
                        <div className={styles.count}>({item.count})</div>
                        {idx === 0 && (
                            <div className={styles.star}>
                                <img src={imageStar} alt={order} />
                            </div>
                        )}
                    </div>
                );
            }),
        });
    }
    if (rankings.searched) {
        items.push({
            title: "[en]Frequently searched keywords[/en][ja]頻繁に検索されるキーワード[/ja]",
            list: rankings.searched.map((item, idx) => {
                const url = ItemUtil.getSearchByKeywordUrl("all", item.keyword);
                const title = "Search by: " + item.keyword;
                const order = orderLabel(idx + 1, lang);
                return (
                    <div key={idx} className={styles.item}>
                        <div className={styles.order}>{order}</div>
                        <div className={styles.title}>
                            <Link to={url} title={title}>
                                {item.keyword}
                            </Link>
                        </div>
                        {idx === 0 && (
                            <div className={styles.star}>
                                <img src={imageStar} alt={order} />
                            </div>
                        )}
                    </div>
                );
            }),
        });
    }
    return (
        <div>
            {items.map((item, idx) => {
                return (
                    <Fragment key={idx}>
                        {idx !== 0 && <hr />}
                        <h3 className={styles.heading}>{Functions.mlang(item.title, lang)}</h3>
                        <div>{item.list}</div>
                    </Fragment>
                );
            })}
        </div>
    );
};

export default Rankings;
