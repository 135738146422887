import iconFile from "../../assets/images/icon_model.gif";
import { ItemModelSubTypes } from "../../lib/ItemUtil";
import TopBase, { TopBaseProps } from "../lib/TopBase";

class ModelTop extends TopBase {
    constructor(props: TopBaseProps) {
        super(props);
        this.type = "model";
        this.label = "Model";
        this.icon = iconFile;
        this.description = "[en]Model programs/scripts.[/en][ja]モデル プログラム/スクリプト[/ja]";
        this.subTypes = ItemModelSubTypes;
    }
}

export default ModelTop;
