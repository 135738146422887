const SITE_TITLE = "NeuroImaging Platform";
const SITE_SLOGAN = "";
const GOOGLE_ANALYTICS_TRACKING_ID = "UA-3757403-1";
const XOONIPS_ITEMTYPES = ["book", "paper", "presentation", "data", "tool", "nimgcenter", "model", "url"];

export type MultiLang = "en" | "ja";

const Config = {
    SITE_TITLE,
    SITE_SLOGAN,
    GOOGLE_ANALYTICS_TRACKING_ID,
    XOONIPS_ITEMTYPES,
};

export default Config;
