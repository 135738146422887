import { Fragment } from "react";
import { Link } from "react-router-dom";
import Functions from "../../../functions";
import iconFile from "../../assets/images/icon_paper.gif";
import { ItemPaper } from "../../lib/ItemUtil";
import ListBase, { ListBaseProps } from "../lib/ListBase";
import PaperUtil from "./PaperUtil";

class PaperList extends ListBase {
    constructor(props: ListBaseProps) {
        super(props);
        this.label = "Paper";
        this.icon = iconFile;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemPaper;
        const authors = item.author.map((author, i) => {
            return (
                <Fragment key={i}>
                    {i > 0 && ", "}
                    {Functions.mlang(author, lang)}
                </Fragment>
            );
        });
        return (
            <>
                <Link to={this.url}>{Functions.mlang(item.title, lang)}</Link>
                <br />
                {authors}
                <br />
                {Functions.mlang(item.journal, lang)}
                {item.publication_year}
                {item.volume !== null && " ;" + item.volume}
                {item.number !== null && " (" + item.number + ")"}
                {item.page !== "" && " :" + item.page}
                {item.pubmed_id !== "" && (
                    <>
                        {" "}
                        [PMID:
                        <PaperUtil.PubmedLink lang={lang} pubmedId={item.pubmed_id} />]
                    </>
                )}
            </>
        );
    }
}

export default PaperList;
