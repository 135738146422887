import { Fragment } from "react";
import { Link } from "react-router-dom";
import Functions from "../../../functions";
import iconFile from "../../assets/images/icon_model.gif";
import { ItemModel } from "../../lib/ItemUtil";
import ListBase, { ListBaseProps } from "../lib/ListBase";

class ModelList extends ListBase {
    constructor(props: ListBaseProps) {
        super(props);
        this.label = "Model";
        this.icon = iconFile;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemModel;
        const authors = item.creator.map((author, i) => {
            return (
                <Fragment key={i}>
                    {i > 0 && ", "}
                    {Functions.mlang(author, lang)}
                </Fragment>
            );
        });
        return (
            <>
                <Link to={this.url}>{Functions.mlang(item.title, lang)}</Link>
                <br />
                {authors}
            </>
        );
    }
}

export default ModelList;
