import FilesAdvancedSearch from "./FilesAdvancedSearch";
import FilesDetail from "./FilesDetail";
import FilesList from "./FilesList";
import FilesTop from "./FilesTop";

const ItemTypeFiles = {
    Top: FilesTop,
    List: FilesList,
    Detail: FilesDetail,
    AdvancedSearch: FilesAdvancedSearch,
};

export default ItemTypeFiles;
