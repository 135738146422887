import ToolAdvancedSearch from "./ToolAdvancedSearch";
import ToolDetail from "./ToolDetail";
import ToolList from "./ToolList";
import ToolTop from "./ToolTop";

const ItemTypeTool = {
    Top: ToolTop,
    List: ToolList,
    Detail: ToolDetail,
    AdvancedSearch: ToolAdvancedSearch,
};

export default ItemTypeTool;
