import React from "react";
import { MultiLang } from "../config";
import Functions from "../functions";
import ItemUtil, { SearchCallbackFunc, SortCondition } from "./lib/ItemUtil";
import XoonipsListItem from "./lib/XoonipsListItem";

interface Props {
    lang: MultiLang;
    type: string;
    keyword: string;
}

const XoonipsSearchByKeyword: React.FC<Props> = (props: Props) => {
    const { lang, type, keyword } = props;

    const searchFunc = (condition: SortCondition, func: SearchCallbackFunc) => {
        if (keyword === "") {
            const res = { total: 0, data: [] };
            func(res);
        } else {
            ItemUtil.getListByKeyword(type, keyword, condition, func);
        }
    };

    const baseUrl = ItemUtil.getSearchByKeywordUrl(type, keyword);
    return (
        <div className="list">
            <h3>{Functions.mlang("[en]Listing item[/en][ja]アイテム一覧[/ja]", lang)}</h3>
            <p>
                {Functions.mlang("[en]Search Keyword[/en][ja]検索キーワード[/ja]", lang)} : {keyword}
            </p>
            <XoonipsListItem lang={lang} url={baseUrl} search={searchFunc} />
        </div>
    );
};

export default XoonipsSearchByKeyword;
