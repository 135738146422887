import Author from "./Author";
import ChangeLog from "./ChangeLog";
import Contributer from "./Contributer";
import CreativeCommons from "./CreativeCommons";
import DateTime from "./DateTime";
import Description from "./Description";
import FileDownloadButton from "./FileDownloadButton";
import FileSize from "./FileSize";
import FreeKeyword from "./FreeKeyword";
import ItemFile from "./ItemFile";
import ItemIndex from "./ItemIndex";
import Language from "./Language";
import Preview from "./Preview";
import PublicationDate from "./PublicationDate";
import Readme from "./Readme";
import RelatedTo from "./RelatedTo";
import Rights from "./Rights";

const ItemTypeField = {
    Author,
    ChangeLog,
    Contributer,
    CreativeCommons,
    DateTime,
    Description,
    FileDownloadButton,
    FileSize,
    FreeKeyword,
    ItemFile,
    ItemIndex,
    Language,
    Preview,
    PublicationDate,
    Readme,
    RelatedTo,
    Rights,
};

export default ItemTypeField;
