import React from "react";
import { Helmet } from "react-helmet-async";
import XoopsCode from "../common/lib/XoopsCode";
import { MultiLang } from "../config";
import Functions from "../functions";
import jsonBulletin from "./assets/bulletin.json";

interface Props {
    lang: MultiLang;
}

const NimgdocsBulletinLog: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang("[en]Expired Posts[/en][ja]掲載期間の終了した投稿[/ja]", lang);
    const now = new Date().getTime() / 1000;
    const logs = jsonBulletin.filter((log) => log.end_date < now && log.text[lang] !== "");
    return (
        <>
            <Helmet>
                <title>
                    {title} - NIMG Documents - {Functions.siteTitle(lang)}
                </title>
            </Helmet>
            <h1>{Functions.mlang("[en]Expired Posts[/en][ja]掲載期間の終了した投稿[/ja]", lang)}</h1>
            {logs.map((log) => {
                return (
                    <div key={log.id}>
                        {log.tags.map((tag) => (
                            <span key={tag} style={{ margin: "0 2px", padding: "0 2px", color: "#666", backgroundColor: "#eee" }}>
                                {tag}
                            </span>
                        ))}
                        <XoopsCode lang={lang} text={log.text[lang]} dobr />
                        <div style={{ margin: "5px 0", textAlign: "right" }}>
                            {Functions.formatDate(log.post_date, "YYYY-MM-DD HH:mm:ss")} Posted by <b>{log.author}</b>
                        </div>
                        <hr style={{ margin: "10px 0", border: "none", borderTop: "1px dotted #ccc" }} />
                    </div>
                );
            })}
        </>
    );
};

export default NimgdocsBulletinLog;
