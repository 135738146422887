import NimgcenterSearchQuery from "../item-type/nimgcenter/SearchQuery";

type AdvancedSearchQueryData = Map<string, URLSearchParams>;

class AdvancedSearchQuery {
    private dataset: AdvancedSearchQueryData = new Map();

    empty() {
        let ret = true;
        this.dataset.forEach((data) => {
            data.forEach((value) => {
                value = value.trim();
                if (value.length !== 0) {
                    ret = false;
                }
            });
        });
        return ret;
    }

    set(type: string, key: string, value: string) {
        type = type.trim();
        key = key.trim();
        const data: URLSearchParams = this.dataset.has(type) ? (this.dataset.get(type) as URLSearchParams) : new URLSearchParams();
        data.set(key, value);
        this.dataset.set(type, data);
    }

    get(type: string, key: string) {
        type = type.trim();
        key = key.trim();
        return this.dataset.get(type)?.get(key) || "";
    }

    delete(type: string, key: string) {
        type = type.trim();
        key = key.trim();
        const data: URLSearchParams = this.dataset.has(type) ? (this.dataset.get(type) as URLSearchParams) : new URLSearchParams();
        data.delete(key);
        if (data.keys.length === 0) {
            this.dataset.delete(type);
        } else {
            this.dataset.set(type, data);
        }
    }

    deleteType(type: string) {
        type = type.trim();
        this.dataset.delete(type);
    }

    clear() {
        this.dataset.clear();
    }

    hasType(type: string) {
        return this.dataset.has(type);
    }

    getQueryParams() {
        let ret: URLSearchParams = new URLSearchParams();
        this.dataset.forEach((data, type) => {
            data.forEach((value, key) => {
                value = value.trim();
                value.length > 0 && ret.set(type + "." + key, value);
            });
        });
        return ret;
    }

    setByQueryString(queryString: string) {
        const query = new URLSearchParams(queryString);
        query.forEach((v, k) => {
            const [type, ...key] = k.split(".");
            if (typeof key === "undefined") {
                return;
            }
            this.set(type, key.join("."), v);
        });
    }

    getSearchFilter() {
        const regex = (str: string) => {
            return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        };
        const filter: any = [];
        this.dataset.forEach((data, type) => {
            if (type === "nimgcenter") {
                filter.push(NimgcenterSearchQuery.getAdvancedSearchFilter(data));
            } else {
                const filter2: any = [];
                filter2.push({ item_type_name: "xnp" + type });
                data.forEach((value, key) => {
                    const keys = key.split(".");
                    if (keys.length > 0) {
                        if (keys[0] === "file" && keys.length === 3) {
                            const fileType = keys[1];
                            const subKey = keys[2];
                            filter2.push({
                                file: {
                                    $elemMatch: {
                                        file_type_name: fileType,
                                        [subKey]: { $regex: [regex(value), "i"] },
                                    },
                                },
                            });
                        } else {
                            filter2.push({ [key]: { $regex: [regex(value), "i"] } });
                        }
                    }
                });
                filter.push({ $and: filter2 });
            }
        });
        return { $or: filter };
    }
}

export default AdvancedSearchQuery;
