import React from "react";
import NoticeSiteHasBeenArchived from "../../common/lib/NoticeSiteHasBeenArchived";
import XoopsCode from "../../common/lib/XoopsCode";
import { MultiLang } from "../../config";
import Functions from "../../functions";

interface Props {
    lang: MultiLang;
}

const Information: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = "[en]Information[/en][ja]お知らせ[/ja]";
    const content = {
        en: "This site was opened to the public at 23 Jun 2009. <s>When you submit an application form of user registration, please write it correctly. The application may not be approved, if it includes incorrect information or only abbreviation for address, organization, and division name.</s> (Currently, user registration is closed.)",
        ja: "このサイトは2009年6月23日に一般公開され、<s>一般ユーザの登録を受け付けています。ユーザ登録されると、データ登録やダウンロードが可能になるなど、より多くの機能をご利用出来ます。ユーザ登録の際には、正確な情報を入力していただくようお願いします。不正確な情報が記入されていたり、住所・機関・所属などが短縮形だけであるなどの場合は、登録が承認されないことがあります。</s> (現在、新規登録を受け付けておりません。)",
    };
    return (
        <div className="block">
            <div className="blockTitle">{Functions.mlang(title, lang)}</div>
            <div className="blockContent">
                <NoticeSiteHasBeenArchived lang={lang} />
                <XoopsCode lang={lang} text={content[lang]} dohtml={true} />
            </div>
        </div>
    );
};

export default Information;
