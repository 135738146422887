import React from "react";
import { MultiLang } from "../../../config";
import ItemUtil, { Item } from "../../lib/ItemUtil";
import SimPFLinkIcon from "./field/SimPFLinkIcon";

export interface ListBaseProps {
    lang: MultiLang;
    item: Item;
}

class ListBase extends React.Component<ListBaseProps> {
    protected label = "";
    protected icon = "";
    protected url: string;
    protected simpfLinkUrl: string;

    constructor(props: ListBaseProps) {
        super(props);
        this.url = ItemUtil.getUrl(props.item);
        this.simpfLinkUrl = ItemUtil.getSimPFLinkUrl(props.item.item_id);
    }

    renderBody() {
        return <></>;
    }

    render() {
        const { lang } = this.props;
        return (
            <table>
                <tbody>
                    <tr>
                        <td className="listIcon">
                            <img src={this.icon} alt={this.label} />
                        </td>
                        <td>{this.renderBody()}</td>
                        <td className="listExtra">
                            <SimPFLinkIcon lang={lang} url={this.simpfLinkUrl} isDetail={false} />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default ListBase;
