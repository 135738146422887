import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Modal } from "react-overlays";
import { MultiLang } from "../../../../config";
import Functions from "../../../../functions";
import ItemUtil, { ItemBasicFile } from "../../../lib/ItemUtil";
import DateTime from "./DateTime";
import FileSize from "./FileSize";
import styles from "./LicenseAgreementDialog.module.css";
import Rights from "./Rights";

interface Props {
    lang: MultiLang;
    file: ItemBasicFile;
    rights: string;
    useCc: number;
    ccCommercialUse: number;
    ccModification: number;
    show: boolean;
    unsetShow: () => void;
}

const LicenseAgreementDialog: React.FC<Props> = (props: Props) => {
    const { lang, file, rights, useCc, ccCommercialUse, ccModification, show, unsetShow } = props;

    const [isShow, setIsShow] = useState<boolean>(show);
    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        setIsShow(show);
        setDisabled(true);
    }, [show]);

    const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const disabled = e.target.value === "0";
        setDisabled(disabled);
    };

    const handleClickDownload = (e: MouseEvent<HTMLButtonElement>) => {
        unsetShow();
        setIsShow(false);
    };

    const handleClickCancel = () => {
        unsetShow();
        setIsShow(false);
    };

    const renderBackdrop = (props: any) => {
        return <div className={styles.overlay} {...props} />;
    };

    const date = new Date(file.timestamp);
    const timestamp = Math.floor(date.valueOf() / 1000);
    const url = ItemUtil.getFileUrl(file);
    return (
        <Modal className={styles.dialog} show={isShow} onHide={handleClickCancel} renderBackdrop={renderBackdrop}>
            <div>
                <div>
                    {Functions.mlang("[en]Download file information[/en][ja]ダウンロードするファイルの情報[/ja]", lang)}
                    <div className={styles.box}>
                        {file.original_file_name}
                        <br />
                        <table>
                            <tbody>
                                <tr>
                                    <td>Type</td>
                                    <td>: {file.mime_type}</td>
                                </tr>
                                <tr>
                                    <td>Size</td>
                                    <td>
                                        : <FileSize lang={lang} size={file.file_size} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Last updated</td>
                                    <td>
                                        : <DateTime lang={lang} date={timestamp} onlyDate={true} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br />
                <div>
                    {Functions.mlang("[en]License agreement[/en][ja]ファイルのライセンス[/ja]", lang)}
                    <div className={styles.box}>
                        {Functions.mlang("[en]Please read the following license agreement carefully.[/en][ja]このファイルには下記のライセンスが設定されています。[/ja]", lang)}
                        <div>
                            <Rights lang={lang} rights={rights} useCc={useCc} ccCommercialUse={ccCommercialUse} ccModification={ccModification} />
                            <input type="radio" name="radio_license" value="1" onChange={handleChangeCheckbox} checked={!disabled} />
                            {Functions.mlang("[en]I accept the terms in the license agreement.[/en][ja]ライセンスに同意します。[/ja]", lang)}
                            <br />
                            <input type="radio" name="radio_license" value="0" onChange={handleChangeCheckbox} checked={disabled} />
                            {Functions.mlang("[en]I do not accept the terms in the license agreement.[/en][ja]ライセンスに同意しません。[/ja]", lang)}
                            <br />
                        </div>
                    </div>
                </div>
                <br />
                <div className={styles.download}>
                    Acceptance is needed to download this file.
                    <br />
                    <a href={url} download={file.original_file_name}>
                        <button className="formButton" onClick={handleClickDownload} disabled={disabled}>
                            Download
                        </button>
                    </a>
                    <button className="formButton" onClick={handleClickCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default LicenseAgreementDialog;
