import React from "react";
import { MultiLang } from "../../../config";
import { ItemConference, ItemConferenceSubType, ItemConferenceSubTypes } from "../../lib/ItemUtil";

interface PresentationTypeProps {
    lang: MultiLang;
    type: ItemConferenceSubType;
}

const PresentationType: React.FC<PresentationTypeProps> = (props: PresentationTypeProps) => {
    const { type } = props;
    const subtype = ItemConferenceSubTypes.find((value) => {
        return value.type === type;
    });
    if (typeof subtype === "undefined") {
        return null;
    }
    return <span>{subtype.label}</span>;
};

interface ConferenceDateProps {
    lang: MultiLang;
    item: ItemConference;
}

const ConferenceDate: React.FC<ConferenceDateProps> = (props: ConferenceDateProps) => {
    const { item } = props;
    const monthStr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const from = "From: " + monthStr[item.conference_from_month - 1] + " " + item.conference_from_mday + ", " + item.conference_from_year;
    const to = "To: " + monthStr[item.conference_to_month - 1] + " " + item.conference_to_mday + ", " + item.conference_to_year;
    return (
        <span>
            {from} {to}
        </span>
    );
};

const ConferenceUtil = {
    PresentationType,
    ConferenceDate,
};

export default ConferenceUtil;
