import React, { useEffect, useState } from "react";
import { MultiLang } from "../../../../config";
import ItemType from "../../../item-type";
import ItemUtil from "../../../lib/ItemUtil";

interface Props {
    lang: MultiLang;
    relatedTo: number[];
}

const RelatedTo: React.FC<Props> = (props: Props) => {
    const { lang, relatedTo } = props;
    const [items, setItems] = useState<React.ReactNode[]>([]);

    useEffect(() => {
        const updateElements = () => {
            if (relatedTo.length === 0) {
                setItems([]);
            } else {
                ItemUtil.getList(relatedTo, (results) => {
                    const items = results.data.map((item, idx) => {
                        const evenodd = idx % 0 ? "even" : "odd";
                        return (
                            <tr key={item.item_id}>
                                <td className={evenodd}>
                                    <ItemType.List lang={lang} item={item} />
                                </td>
                            </tr>
                        );
                    });
                    setItems(items);
                });
            }
        };
        updateElements();
    }, [lang, relatedTo]);

    if (items.length === 0) {
        return null;
    }
    return (
        <table className="listTable">
            <tbody>
                <tr>
                    <th>Item summary</th>
                </tr>
                {items}
            </tbody>
        </table>
    );
};

export default RelatedTo;
