import AdvancedSearchBase, { AdvancedSearchBaseProps } from "../lib/AdvancedSearchBase";

class BookAdvancedSearch extends AdvancedSearchBase {
    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = "book";
        this.title = "Book";
        const keys = ["title", "keyword", "description", "doi", "author", "editor", "publisher", "publication_year", "isbn", "file.book_pdf.original_file_name"];
        const show = props.query.hasType(this.type);
        const values: any = {};
        keys.forEach((key) => (values[key] = props.query.get(this.type, key)));
        this.state = { show, values };
    }

    getRows() {
        const rows = [
            { label: "[en]Book Title[/en][ja]著書名[/ja]", value: this.renderFieldInputText("title", 50) },
            { label: "[en]Free Keywords[/en][ja]フリーキーワード[/ja]", value: this.renderFieldInputText("keyword", 50) },
            { label: "[en]Description[/en][ja]概要[/ja]", value: this.renderFieldInputText("description", 50) },
            { label: "ID", value: this.renderFieldInputText("doi", 50) },
            { label: "[en]Author[/en][ja]著者[/ja]", value: this.renderFieldInputText("author", 50) },
            { label: "[en]Editor[/en][ja]編集者[/ja]", value: this.renderFieldInputText("editor", 50) },
            { label: "[en]Publisher[/en][ja]出版社[/ja]", value: this.renderFieldInputText("publisher", 50) },
            { label: "[en]Publication Year[/en][ja]出版年[/ja]", value: this.renderFieldInputText("publication_year", 10) },
            { label: "ISBN", value: this.renderFieldInputText("isbn", 50) },
            { label: "[en]PDF File[/en][ja]PDF ファイル[/ja]", value: this.renderFieldInputText("file.book_pdf.original_file_name", 50) },
        ];
        return rows;
    }
}

export default BookAdvancedSearch;
