import iconFile from "../../assets/images/icon_memo.gif";
import TopBase, { TopBaseProps } from "../lib/TopBase";

class MemoTop extends TopBase {
    constructor(props: TopBaseProps) {
        super(props);
        this.type = "memo";
        this.label = "Memo";
        this.icon = iconFile;
        this.description = "[en]Personal Memo Pad.[/en][ja]汎用メモパッド[/ja]";
    }
}

export default MemoTop;
