export interface BrainCoordinate {
    x: number;
    y: number;
    z: number;
}
export type ConversionType = "icbm_spm2tal" | "tal2icbm_spm" | "icbm_fsl2tal" | "tal2icbm_fsl" | "icbm_other2tal" | "tal2icbm_other";
type ConversionVector = readonly [number, number, number, number];
type ConversionMatrix = readonly [ConversionVector, ConversionVector, ConversionVector, ConversionVector];

interface ConversionMatrixData {
    [key: string]: {
        label: string;
        matrix: ConversionMatrix;
    };
}

const conversionMatrix: ConversionMatrixData = {
    icbm_spm2tal: {
        label: "icbm_spm => Talairach",
        matrix: [
            [0.9254, 0.0024, -0.0118, -1.0207],
            [-0.0048, 0.9316, -0.0871, -1.7667],
            [0.0152, 0.0883, 0.8924, 4.0926],
            [0.0, 0.0, 0.0, 1.0],
        ],
    },
    tal2icbm_spm: {
        label: "Talairach => icbm_spm",
        matrix: [
            [1.0804, -0.0041, 0.0139, 1.0387],
            [0.0038, 1.0636, 0.1039, 1.4579],
            [-0.0188, -0.1052, 1.1101, -4.748],
            [0.0, 0.0, 0.0, 1.0],
        ],
    },
    icbm_fsl2tal: {
        label: "icbm_fsl => Talairach",
        matrix: [
            [0.9464, 0.0034, -0.0026, -1.068],
            [-0.0083, 0.9479, -0.058, -1.0239],
            [0.0053, 0.0617, 0.901, 3.1883],
            [0.0, 0.0, 0.0, 1.0],
        ],
    },
    tal2icbm_fsl: {
        label: "Talairach => icbm_fsl",
        matrix: [
            [1.0566, -0.004, 0.0028, 1.1155],
            [0.0088, 1.0505, 0.0677, 0.8694],
            [-0.0068, -0.0719, 1.1052, -3.6047],
            [0.0, 0.0, 0.0, 1.0],
        ],
    },
    icbm_other2tal: {
        label: "other(SPM99,Brain Voyager) => Talairach",
        matrix: [
            [0.9357, 0.0029, -0.0072, -1.0423],
            [-0.0065, 0.9396, -0.0726, -1.394],
            [0.0103, 0.0752, 0.8967, 3.6475],
            [0.0, 0.0, 0.0, 1.0],
        ],
    },
    tal2icbm_other: {
        label: "Talairach => other(SPM99,Brain Voyager)",
        matrix: [
            [1.0686, -0.004, 0.0083, 1.0782],
            [0.0064, 1.0574, 0.0857, 1.1682],
            [-0.0128, -0.0886, 1.1079, -4.178],
            [0.0, 0.0, 0.0, 1.0],
        ],
    },
    /*
    tal2mni: {
        label: 'Talairach => MNI(Matthew Brett,99)',
        matrix: null // See also, http://eeg.sourceforge.net/doc_m2html/bioelectromagnetism/tal2mni.html
    },
    mni2tal: {
        label: 'MNI => Talairach(Matthew Brett,99)',
        matrix: null // See also, http://eeg.sourceforge.net/doc_m2html/bioelectromagnetism/mni2tal.html
    }
    */
};

const isConversionType = (type: string): boolean => {
    return type in conversionMatrix;
};

const getConversionTypes = (): ConversionType[] => {
    return Object.keys(conversionMatrix) as ConversionType[];
};

const getConversionLabel = (type: ConversionType): string => {
    return conversionMatrix[type].label;
};

const getConversionMatrix = (type: ConversionType): ConversionMatrix => {
    return conversionMatrix[type].matrix;
};

const mulMatrix = (mat: ConversionMatrix, vec: ConversionVector): ConversionVector => {
    return [mat[0][0] * vec[0] + mat[0][1] * vec[1] + mat[0][2] * vec[2] + mat[0][3] * vec[3], mat[1][0] * vec[0] + mat[1][1] * vec[1] + mat[1][2] * vec[2] + mat[1][3] * vec[3], mat[2][0] * vec[0] + mat[2][1] * vec[1] + mat[2][2] * vec[2] + mat[2][3] * vec[3], mat[3][0] * vec[0] + mat[3][1] * vec[1] + mat[3][2] * vec[2] + mat[3][3] * vec[3]];
};

const convertCoordinate = (type: ConversionType, coord: BrainCoordinate): BrainCoordinate => {
    const vec: ConversionVector = [coord.x, coord.y, coord.z, 1.0];
    const res = mulMatrix(conversionMatrix[type].matrix, vec);
    return { x: res[0], y: res[1], z: res[2] };
};

/*
function mni2tal(v) {
    if (v[2] < 0) {
        return new Array(
            0.9900 * v[0],
            0.9688 * v[1] + 0.0420 * v[2],
            -0.0485 * v[1] + 0.8390 * v[2]
        );
    } else {
        return new Array(
            0.9900 * v[0],
            0.9688 * v[1] + 0.0460 * v[2],
            -0.0485 * v[1] + 0.9189 * v[2]
        );
    }
}

function tal2mni(v) {
    if (v[2] < 0) {
        return new Array(
            1.0101 * v[0],
            0.9688 * v[1] + 0.0460 * v[2],
            -0.0485 * v[1] + 0.9189 * v[2]
        );
    } else {
        return new Array(
            1.0101 * v[0],
            0.9712 * v[1] + 0.0501 * v[2],
            -0.0485 * v[1] + 0.8390 * v[2]
        );
    }
}
*/

const BrainCoordinateUtil = {
    isConversionType,
    getConversionTypes,
    getConversionLabel,
    getConversionMatrix,
    convertCoordinate,
};

export default BrainCoordinateUtil;
