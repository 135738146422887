import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../common/lib/Loading";
import { MultiLang } from "../../config";
import Functions from "../../functions";
import ItemType from "../item-type";
import { Item, SearchFunc, SearchResult, SortCondition, SortConditionLimit, SortConditionOrderBy, SortConditionOrderDir } from "./ItemUtil";
import styles from "./XoonipsListItem.module.css";

const SORT_CONDITION_DEFAULT: SortCondition = {
    limit: 20,
    page: 1,
    orderBy: "title",
    orderDir: 1,
};
const SORT_CONDITION_RANGE_ORDER_BY = ["title", "doi", "last_update_date", "creation_date", "publication_date"];
const SORT_CONDITION_RANGE_ORDER_DIR = ["0", "1"];
const SORT_CONDITION_RANGE_LIMIT = ["20", "50", "100"];

interface Props {
    lang: MultiLang;
    url: string;
    search: SearchFunc;
}

const getDefaultSortCondition = () => {
    return Object.assign({}, SORT_CONDITION_DEFAULT);
};

const getSortConditionByQuery = (queryString: string) => {
    const query = new URLSearchParams(queryString);
    const condition = getDefaultSortCondition();
    const orderby = query.get("orderby");
    if (orderby !== null && SORT_CONDITION_RANGE_ORDER_BY.includes(orderby)) {
        condition.orderBy = orderby as SortConditionOrderBy;
    }
    const order_dir = query.get("order_dir");
    if (order_dir !== null && SORT_CONDITION_RANGE_ORDER_DIR.includes(order_dir)) {
        condition.orderDir = parseInt(order_dir, 10) as SortConditionOrderDir;
    }
    const itemcount = query.get("itemcount");
    if (itemcount !== null && SORT_CONDITION_RANGE_LIMIT.includes(itemcount)) {
        condition.limit = parseInt(itemcount, 10) as SortConditionLimit;
    }
    const page = query.get("page");
    if (page !== null && page.match(/^\d+$/) !== null) {
        condition.page = parseInt(page, 10);
    }
    return condition;
};

const XoonipsListItem: React.FC<Props> = (props: Props) => {
    const { lang, url, search } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(true);
    const [condition, setCondition] = useState<SortCondition>(getDefaultSortCondition());
    const [result, setResult] = useState<SearchResult>({ total: 0, data: [] });

    useEffect(() => {
        const condition = getSortConditionByQuery(location.search);
        search(condition, (result) => {
            setLoading(false);
            setCondition(condition);
            setResult(result);
        });
    }, [location, url, search]);

    const getListUrl = (query: { orderby?: string; order_dir?: number; limit?: number; page?: number }) => {
        const newOrderBy = query.orderby ?? condition.orderBy;
        const newOrderDir = query.order_dir ?? condition.orderDir;
        const newLimit = query.limit ?? condition.limit;
        let newPage = query.page ?? condition.page;
        if (newLimit !== condition.limit || newOrderBy !== condition.orderBy) {
            newPage = SORT_CONDITION_DEFAULT.page;
        }
        const params = new URLSearchParams({
            orderby: newOrderBy,
            order_dir: String(newOrderDir),
            itemcount: String(newLimit),
            page: String(newPage),
        });
        const join = url.indexOf("?") < 0 ? "?" : "&";
        return url + join + params.toString();
    };

    const handleSelectOrderby = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        const url = getListUrl({ orderby: value });
        navigate(url);
    };

    const handleSelectItemcount = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(event.target.value, 10);
        const url = getListUrl({ limit: value });
        navigate(url);
    };

    const renderSortCriteria = () => {
        let orderDir;
        if (condition.orderDir !== SortConditionOrderDir.ASC) {
            const url = getListUrl({ order_dir: SortConditionOrderDir.ASC });
            orderDir = (
                <span>
                    &#9660;&nbsp;<Link to={url}>&#9650;</Link>
                </span>
            );
        } else {
            const url = getListUrl({ order_dir: SortConditionOrderDir.DESC });
            orderDir = (
                <span>
                    <Link to={url}>&#9660;</Link>&nbsp;&#9650;
                </span>
            );
        }
        return (
            <div className={styles.sortCriteria}>
                <table className="listTable">
                    <tbody>
                        <tr>
                            <td className="odd">
                                Order by&nbsp;
                                <select value={condition.orderBy} onChange={handleSelectOrderby}>
                                    <option value="title">{Functions.mlang("[en]Title[/en][ja]タイトル[/ja]", lang)}</option>
                                    <option value="doi">ID</option>
                                    <option value="last_update_date">{Functions.mlang("[en]Last Modified Date[/en][ja]最終更新日[/ja]", lang)}</option>
                                    <option value="creation_date">{Functions.mlang("[en]Created Date[/en][ja]作成日[/ja]", lang)}</option>
                                    <option value="publication_date">{Functions.mlang("[en]Date[/en][ja]日付[/ja]", lang)}</option>
                                </select>
                                &nbsp;{orderDir}
                            </td>
                            <td className="odd">
                                No.Item per page&nbsp;
                                <select value={condition.limit} onChange={handleSelectItemcount}>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const renderPageNavi = (result: SearchResult) => {
        const maxPage = Math.floor(result.total / condition.limit) + (result.total % condition.limit !== 0 ? 1 : 0);
        const link = (title: string, page: number, id: string) => {
            if (page === 0 || page === condition.page) {
                return (
                    <span key={id} className={styles.pageNaviItem}>
                        {title}
                    </span>
                );
            }
            const url: string = getListUrl({ page: page });
            return (
                <Link key={id} className={styles.pageNaviItem} to={url}>
                    {title}
                </Link>
            );
        };
        let startPage = condition.page - 4 > 1 ? condition.page - 4 : 1;
        const endPage = startPage + 9 > maxPage ? maxPage : startPage + 9;
        if (endPage - startPage < 9) {
            startPage = endPage - 9 > 0 ? endPage - 9 : 1;
        }
        let pageLinks: JSX.Element[] = [];
        pageLinks.push(link("PREV", condition.page - 1, "p"));
        for (let i = startPage; i <= endPage; i++) {
            pageLinks.push(link(String(i), i, String(i)));
        }
        pageLinks.push(link("NEXT", condition.page >= maxPage ? 0 : condition.page + 1, "n"));
        return <div className={styles.pageNavi}>{pageLinks}</div>;
    };

    if (loading) {
        return <Loading />;
    }
    if (result.data.length === 0) {
        return <p>No items found.</p>;
    }
    const startNum = 1 + condition.limit * (condition.page - 1);
    let endNum = condition.limit * condition.page;
    if (endNum > result.total) {
        endNum = result.total;
    }
    const pageNavi = renderPageNavi(result);
    const items = result.data.map((item: Item, idx) => {
        const evenodd = idx % 2 === 0 ? "even" : "odd";
        return (
            <tr key={item.item_id} className={evenodd}>
                <td>
                    <ItemType.List lang={lang} item={item} />
                </td>
            </tr>
        );
    });
    return (
        <>
            {renderSortCriteria()}
            <p>
                {startNum} - {endNum} of {result.total} Items
            </p>
            {pageNavi}
            <table className="listTable">
                <tbody>{items}</tbody>
            </table>
            {pageNavi}
        </>
    );
};

export default XoonipsListItem;
