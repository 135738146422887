import React from "react";
import { MultiLang } from "../../../../config";
import Description from "./Description";

interface Props {
    lang: MultiLang;
    readme: string;
}

const Readme: React.FC<Props> = (props: Props) => {
    const { lang, readme } = props;
    return <Description lang={lang} description={readme} className="readme" />;
};

export default Readme;
