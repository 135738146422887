import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PageNotFound from "../common/lib/PageNotFound";
import { MultiLang } from "../config";
import CreditsIndex from "./CreditsIndex";
import CreditsPage from "./CreditsPage";

interface Props {
    lang: MultiLang;
}

const CreditsTop: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const paramId = params.get("id");
    if (null === paramId) {
        return <CreditsIndex lang={lang} />;
    }
    if (/^[1-9][0-9]*$/.test(paramId)) {
        const id = parseInt(paramId, 10);
        return <CreditsPage lang={lang} id={id} />;
    }
    return <PageNotFound lang={lang} />;
};

const CreditsRedirectToTop: React.FC = () => {
    const location = useLocation();
    const url = location.pathname + "index.php" + location.search + location.hash;
    return <Navigate to={url} />;
};

const Credits: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <>
            <Routes>
                <Route path="" element={<CreditsRedirectToTop />} />
                <Route path="index.php" element={<CreditsTop lang={lang} />} />
                <Route path="aboutus.php" element={<CreditsPage lang={lang} id={0} />} />
                <Route path="*" element={<PageNotFound lang={lang} />} />
            </Routes>
        </>
    );
};

export default Credits;
