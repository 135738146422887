import iconFile from "../../assets/images/icon_paper.gif";
import TopBase, { TopBaseProps } from "../lib/TopBase";

class PaperTop extends TopBase {
    constructor(props: TopBaseProps) {
        super(props);
        this.type = "paper";
        this.label = "Paper";
        this.icon = iconFile;
        this.description = "[en]Related paper collection.[/en][ja]関連論文[/ja]";
    }
}

export default PaperTop;
