import { ItemPresentationSubTypes } from "../../lib/ItemUtil";
import AdvancedSearchBase, { AdvancedSearchBaseProps } from "../lib/AdvancedSearchBase";

class PresentationAdvancedSearch extends AdvancedSearchBase {
    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = "presentation";
        this.title = "Presentation&Doc";
        const now = new Date();
        const keys = ["title", "keyword", "description", "doi", "meeting_name", "presentation_type", "creator", "publication_year", "publication_month", "publication_mday", "file.preview.caption", "file.presentation_file.original_file_name"];
        const show = props.query.hasType(this.type);
        const values: any = {};
        keys.forEach((key) => (values[key] = props.query.get(this.type, key)));
        if (values["publication_year"] === "") {
            values["publication_year"] = now.getFullYear().toString();
        }
        if (values["publication_month"] === "") {
            values["publication_month"] = (now.getMonth() + 1).toString();
        }
        if (values["publication_mday"] === "") {
            values["publication_mday"] = now.getDate().toString();
        }
        this.state = { show, values };
        this.setIgnoreKey("publication_year");
        this.setIgnoreKey("publication_month");
        this.setIgnoreKey("publication_mday");
    }

    getRows() {
        const rows = [
            { label: "[en]Title[/en][ja]タイトル[/ja]", value: this.renderFieldInputText("title", 50) },
            { label: "[en]Free Keywords[/en][ja]フリーキーワード[/ja]", value: this.renderFieldInputText("keyword", 50) },
            { label: "[en]Description[/en][ja]概要[/ja]", value: this.renderFieldInputText("description", 50) },
            { label: "ID", value: this.renderFieldInputText("doi", 50) },
            { label: "[en]Presentation Type[/en][ja]ファイル形式[/ja]", value: this.renderFieldSelect("presentation_type", ItemPresentationSubTypes) },
            { label: "[en]Creator[/en][ja]作成者[/ja]", value: this.renderFieldInputText("creator", 50) },
            { label: "[en]Date[/en][ja]日付[/ja]", value: this.renderFieldDate("", "publication_year", "publication_month", "publication_mday") },
            { label: "[en]Caption[/en][ja]キャプション[/ja]", value: this.renderFieldInputText("file.preview.caption", 50) },
            { label: "[en]Presentation File[/en][ja]発表資料[/ja]", value: this.renderFieldInputText("file.presentation_file.original_file_name", 50) },
        ];
        return rows;
    }
}

export default PresentationAdvancedSearch;
