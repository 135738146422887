import React from "react";
import { MultiLang } from "../../../../config";
import CreativeCommons, { getCreativeCommonsType } from "./CreativeCommons";
import Description from "./Description";

interface Props {
    lang: MultiLang;
    rights: string;
    useCc: number;
    ccCommercialUse: number;
    ccModification: number;
}

const Rights: React.FC<Props> = (props: Props) => {
    const { lang, rights, useCc, ccCommercialUse, ccModification } = props;
    if (useCc === 0) {
        return <Description lang={lang} description={rights} className="rights" />;
    }
    const ccType = getCreativeCommonsType(ccCommercialUse, ccModification);
    return <CreativeCommons lang={lang} type={ccType} />;
};

export default Rights;
