import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Loading from "../common/lib/Loading";
import NoticeSiteHasBeenArchived from "../common/lib/NoticeSiteHasBeenArchived";
import PageNotFound from "../common/lib/PageNotFound";
import XoopsCode from "../common/lib/XoopsCode";
import { MultiLang } from "../config";
import Functions from "../functions";
import CreditsUtils, { CreditsIndexData, CreditsPageDetailData } from "./lib/CreditsUtils";

interface Props {
    lang: MultiLang;
    id: number;
}

const CreditsPage: React.FC<Props> = (props: Props) => {
    const { lang, id } = props;
    const [loading, setLoading] = useState<boolean>(true);
    const [index, setIndex] = useState<CreditsIndexData | null>(null);
    const [page, setPage] = useState<CreditsPageDetailData | null>(null);

    useEffect(() => {
        const updatePage = async () => {
            const index = await CreditsUtils.getIndex();
            const page = await CreditsUtils.getPage(id);
            setLoading(false);
            setIndex(index);
            setPage(page);
        };
        updatePage();
    }, [id]);

    if (loading) {
        return <Loading />;
    }
    if (page === null || index === null) {
        return <PageNotFound lang={lang} />;
    }

    const mtitle = Functions.mlang(index.name, lang);
    const title = Functions.mlang(page.title, lang);
    const lastupdate = page.lastupdate === 0 ? "" : Functions.formatDate(page.lastupdate, "MMMM Do, YYYY");
    return (
        <>
            <Helmet>
                <title>
                    {title} - {mtitle} - {Functions.siteTitle(lang)}
                </title>
            </Helmet>
            <h3>{title}</h3>
            <NoticeSiteHasBeenArchived lang={lang} />
            {"" !== lastupdate && (
                <div style={{ textAlign: "right" }}>
                    {Functions.mlang("[en]Last Update[/en][ja]最終更新日[/ja]", lang)} : {lastupdate}
                </div>
            )}
            <hr />
            <XoopsCode lang={lang} text={page.content} dohtml={true} />
        </>
    );
};
export default CreditsPage;
