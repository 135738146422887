import React, { useState } from "react";
import { MultiLang } from "../../config";
import Functions from "../../functions";

interface Props {
    lang: MultiLang;
}

const HowToUseVideo: React.FC<Props> = (props) => {
    const { lang } = props;
    const [skip, setSkip] = useState<boolean>(false);

    const onClickShowMovie = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setSkip(false);
    };

    const onClickSkipMovie = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setSkip(true);
    };

    const url = "/modules/nimgdocs/docs/viewlet/";
    const name = "ty-02" + (lang === "en" ? "eng" : "jap");
    const preview = `${url}/${name}_small.png`;
    const poster = `${url}/${name}.png`;
    const movieWebm = `${url}/${name}.webm`;
    const movieMp4 = `${url}/${name}.mp4`;

    return (
        <div className="block">
            <div className="blockContent">
                <div style={{ textAlign: "center" }}>
                    <div style={{ fontWeight: "bold", fontSize: "15pt", margin: "0 0 10px 0" }}>{Functions.mlang("[en]Function and how to use NIMG-PF[/en][ja]NIMG-PFの機能と使い方[/ja]", lang)}</div>
                    {skip ? (
                        <>
                            <div>
                                <a href="/" onClick={onClickShowMovie}>
                                    Show Movie
                                </a>
                            </div>
                            <img src={preview} alt="How to Use" />
                        </>
                    ) : (
                        <>
                            <div>
                                <a href="/" onClick={onClickSkipMovie}>
                                    Skip Movie
                                </a>
                            </div>
                            <video poster={poster} autoPlay loop>
                                <source type="video/mp4" src={movieMp4} />
                                <source type="video/webm" src={movieWebm} />
                                <p>
                                    Your browser doesn't support HTML5 video. Here is a <a href={movieMp4}>link to the video</a> instead.
                                </p>
                            </video>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HowToUseVideo;
