import iconFile from "../../assets/images/icon_book.gif";
import TopBase, { TopBaseProps } from "../lib/TopBase";

class BookTop extends TopBase {
    constructor(props: TopBaseProps) {
        super(props);
        this.type = "book";
        this.label = "Book";
        this.icon = iconFile;
        this.description = "[en]Related book collection.[/en][ja]関連書籍[/ja]";
    }
}

export default BookTop;
