import ItemTypeBinder from "./binder";
import ItemTypeBook from "./book";
import ItemTypeConference from "./conference";
import ItemTypeData from "./data";
import ItemTypeFiles from "./files";
import { AdvancedSearchBaseProps } from "./lib/AdvancedSearchBase";
import { DetailBaseProps } from "./lib/DetailBase";
import { ListBaseProps } from "./lib/ListBase";
import { TopBaseProps } from "./lib/TopBase";
import ItemTypeMemo from "./memo";
import ItemTypeModel from "./model";
import ItemTypeNimgcenter from "./nimgcenter";
import ItemTypePaper from "./paper";
import ItemTypePresentation from "./presentation";
import ItemTypeSimulator from "./simulator";
import ItemTypeStimulus from "./stimulus";
import ItemTypeTool from "./tool";
import ItemTypeUrl from "./url";

interface TopProps extends TopBaseProps {
    type: string;
}
const Top = (props: TopProps) => {
    const { lang, type } = props;
    switch (type) {
        case "xnpbinder":
            return <ItemTypeBinder.Top lang={lang} />;
        case "xnpbook":
            return <ItemTypeBook.Top lang={lang} />;
        case "xnpconference":
            return <ItemTypeConference.Top lang={lang} />;
        case "xnpdata":
            return <ItemTypeData.Top lang={lang} />;
        case "xnpfiles":
            return <ItemTypeFiles.Top lang={lang} />;
        case "xnpmemo":
            return <ItemTypeMemo.Top lang={lang} />;
        case "xnpmodel":
            return <ItemTypeModel.Top lang={lang} />;
        case "xnpnimgcenter":
            return <ItemTypeNimgcenter.Top lang={lang} />;
        case "xnppaper":
            return <ItemTypePaper.Top lang={lang} />;
        case "xnppresentation":
            return <ItemTypePresentation.Top lang={lang} />;
        case "xnpsimulator":
            return <ItemTypeSimulator.Top lang={lang} />;
        case "xnpstimulus":
            return <ItemTypeStimulus.Top lang={lang} />;
        case "xnptool":
            return <ItemTypeTool.Top lang={lang} />;
        case "xnpurl":
            return <ItemTypeUrl.Top lang={lang} />;
        default:
            return null;
    }
};

const List = (props: ListBaseProps) => {
    const { item } = props;
    switch (item.item_type_name) {
        case "xnpbinder":
            return <ItemTypeBinder.List {...props} />;
        case "xnpbook":
            return <ItemTypeBook.List {...props} />;
        case "xnpconference":
            return <ItemTypeConference.List {...props} />;
        case "xnpdata":
            return <ItemTypeData.List {...props} />;
        case "xnpfiles":
            return <ItemTypeFiles.List {...props} />;
        case "xnpmemo":
            return <ItemTypeMemo.List {...props} />;
        case "xnpmodel":
            return <ItemTypeModel.List {...props} />;
        case "xnpnimgcenter":
            return <ItemTypeNimgcenter.List {...props} />;
        case "xnppaper":
            return <ItemTypePaper.List {...props} />;
        case "xnppresentation":
            return <ItemTypePresentation.List {...props} />;
        case "xnpsimulator":
            return <ItemTypeSimulator.List {...props} />;
        case "xnpstimulus":
            return <ItemTypeStimulus.List {...props} />;
        case "xnptool":
            return <ItemTypeTool.List {...props} />;
        case "xnpurl":
            return <ItemTypeUrl.List {...props} />;
        default:
            return null;
    }
};

const Detail = (props: DetailBaseProps) => {
    const { item } = props;
    switch (item.item_type_name) {
        case "xnpbinder":
            return <ItemTypeBinder.Detail {...props} />;
        case "xnpbook":
            return <ItemTypeBook.Detail {...props} />;
        case "xnpconference":
            return <ItemTypeConference.Detail {...props} />;
        case "xnpdata":
            return <ItemTypeData.Detail {...props} />;
        case "xnpfiles":
            return <ItemTypeFiles.Detail {...props} />;
        case "xnpmemo":
            return <ItemTypeMemo.Detail {...props} />;
        case "xnpmodel":
            return <ItemTypeModel.Detail {...props} />;
        case "xnpnimgcenter":
            return <ItemTypeNimgcenter.Detail {...props} />;
        case "xnppaper":
            return <ItemTypePaper.Detail {...props} />;
        case "xnppresentation":
            return <ItemTypePresentation.Detail {...props} />;
        case "xnpsimulator":
            return <ItemTypeSimulator.Detail {...props} />;
        case "xnpstimulus":
            return <ItemTypeStimulus.Detail {...props} />;
        case "xnptool":
            return <ItemTypeTool.Detail {...props} />;
        case "xnpurl":
            return <ItemTypeUrl.Detail {...props} />;
        default:
            return null;
    }
};

interface AdvancedSearchProps extends AdvancedSearchBaseProps {
    type: string;
}
const AdvancedSearch = (props: AdvancedSearchProps) => {
    const { lang, type, query } = props;
    switch (type) {
        case "xnpbinder":
            return <ItemTypeBinder.AdvancedSearch lang={lang} query={query} />;
        case "xnpbook":
            return <ItemTypeBook.AdvancedSearch lang={lang} query={query} />;
        case "xnpconference":
            return <ItemTypeConference.AdvancedSearch lang={lang} query={query} />;
        case "xnpdata":
            return <ItemTypeData.AdvancedSearch lang={lang} query={query} />;
        case "xnpfiles":
            return <ItemTypeFiles.AdvancedSearch lang={lang} query={query} />;
        case "xnpmemo":
            return <ItemTypeMemo.AdvancedSearch lang={lang} query={query} />;
        case "xnpmodel":
            return <ItemTypeModel.AdvancedSearch lang={lang} query={query} />;
        case "xnpnimgcenter":
            return <ItemTypeNimgcenter.AdvancedSearch lang={lang} query={query} />;
        case "xnppaper":
            return <ItemTypePaper.AdvancedSearch lang={lang} query={query} />;
        case "xnppresentation":
            return <ItemTypePresentation.AdvancedSearch lang={lang} query={query} />;
        case "xnpsimulator":
            return <ItemTypeSimulator.AdvancedSearch lang={lang} query={query} />;
        case "xnpstimulus":
            return <ItemTypeStimulus.AdvancedSearch lang={lang} query={query} />;
        case "xnptool":
            return <ItemTypeTool.AdvancedSearch lang={lang} query={query} />;
        case "xnpurl":
            return <ItemTypeUrl.AdvancedSearch lang={lang} query={query} />;
        default:
            return null;
    }
};

const ItemType = {
    Top,
    List,
    Detail,
    AdvancedSearch,
};

export default ItemType;
