import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../common/lib/Loading";
import { MultiLang } from "../../config";
import Functions from "../../functions";
import imageStar from "../assets/images/star.gif";
import ItemUtil, { ItemCore } from "../lib/ItemUtil";
import styles from "./RecentContents.module.css";

interface RecentContentData {
    item_id: number;
    doi: string;
    last_update_date: number;
    title: string;
}
type RecentContentsData = RecentContentData[];

interface Props {
    lang: MultiLang;
}

const RecentContents: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const [loading, setLoading] = useState<boolean>(true);
    const [contents, setContents] = useState<RecentContentsData | null>(null);

    useEffect(() => {
        const updatePage = async () => {
            try {
                const response = await axios.get("/modules/xoonips/recent-contents.json");
                const contents = response.status === 200 ? (response.data as RecentContentsData) : null;
                setContents(contents);
            } catch (e) {
                setContents(null);
            }
            setLoading(false);
        };
        updatePage();
    }, []);

    if (loading) {
        return <Loading />;
    }
    if (contents === null) {
        return <div>Failed to get data resource.</div>;
    }

    const list = contents.map((item, idx) => {
        const url = ItemUtil.getUrl(item as ItemCore);
        const title = Functions.mlang(item.title, lang);
        const date = moment(new Date(item.last_update_date * 1000)).format("Y/M/D");
        const order = String(idx + 1) + (lang === "en" ? Functions.ordinal(idx + 1) : "位");
        return (
            <div key={idx} className={styles.item}>
                <div className={styles.order}>{order}</div>
                <div className={styles.title}>
                    <Link className={styles.title} to={url} title={title}>
                        {title}
                    </Link>
                </div>
                <div className={styles.date}> ({date})</div>
                {idx === 0 && (
                    <div className={styles.star}>
                        <img src={imageStar} alt={order} />
                    </div>
                )}
            </div>
        );
    });
    return (
        <div>
            <h3 className={styles.heading}>{Functions.mlang("[en]Newly Arrived Items[/en][ja]新着アイテム[/ja]", lang)}</h3>
            <div>{list}</div>
        </div>
    );
};

export default RecentContents;
