import Tree from "rc-tree";
import { DataNode, EventDataNode } from "rc-tree/lib/interface";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MultiLang } from "../../config";
import Functions from "../../functions";
import IndexUtil, { Index, INDEX_ID_PUBLIC } from "../lib/IndexUtil";
import styles from "./IndexTree.module.css";

interface Props {
    lang: MultiLang;
}

type Key = string | number;

const initialize = (lang: MultiLang) => {
    let keys: string[] = [];
    let eKeys: string[] = [];
    const makeTreeNode = (index: Index, depth: number): DataNode => {
        const title = Functions.mlang(index.title, lang) + (index.numOfItems > 0 ? " (" + index.numOfItems + ")" : "");
        const children = IndexUtil.getChildren(index.id);
        if (children.length === 0) {
            return { key: String(index.id), title: title };
        }
        const childTreeNodes = children.map((value: Index) => {
            return makeTreeNode(value, depth + 1);
        });
        if (depth < 1) {
            eKeys.push(String(index.id));
        }
        keys.push(String(index.id));
        return { key: String(index.id), title: title, children: childTreeNodes };
    };
    let elements: DataNode[] = [];
    const index = IndexUtil.get(INDEX_ID_PUBLIC);
    if (index !== null) {
        elements.push(makeTreeNode(index, 0));
    }
    return {
        elements: elements,
        keys: keys,
        expandedKeys: eKeys,
    };
};

const IndexTree: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const res = initialize(lang);
    const navigate = useNavigate();
    const [tree, setTree] = useState<DataNode[]>(res.elements);
    const [expandableKeys, setExpandableKeys] = useState<string[]>(res.keys);
    const [expandedKeys, setExpandedKeys] = useState<string[]>(res.expandedKeys);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    useEffect(() => {
        const res = initialize(lang);
        setTree(res.elements);
        setExpandableKeys(res.keys);
        setSelectedKeys([]);
    }, [lang]);

    const handleClickOpenAll = () => {
        setExpandedKeys(expandableKeys);
    };

    const handleClickCloseAll = () => {
        setExpandedKeys([]);
    };

    const handleExpand = (
        expandedKeys: Key[],
        info: {
            node: EventDataNode<DataNode>;
            expanded: boolean;
            nativeEvent: MouseEvent;
        }
    ): void => {
        const keys: string[] = expandedKeys.map((key) => {
            return typeof key === "string" ? key : String(key);
        });
        setExpandedKeys(keys);
    };

    const handleSelect = (
        selectedKeys: Key[],
        info: {
            event: "select";
            selected: boolean;
            node: EventDataNode<DataNode>;
            selectedNodes: DataNode[];
            nativeEvent: MouseEvent;
        }
    ): void => {
        const selectedKey = selectedKeys.shift() || 0;
        const key = typeof selectedKey === "string" ? parseInt(selectedKey, 10) : selectedKey;
        const url = IndexUtil.getUrl(key);
        setSelectedKeys([]);
        navigate(url);
    };

    return (
        <div className={styles.container}>
            <button className={styles.formButton} onClick={handleClickOpenAll}>
                open all
            </button>
            <button className={styles.formButton} onClick={handleClickCloseAll}>
                close all
            </button>
            <Tree className={styles.indexTree} expandedKeys={expandedKeys} selectedKeys={selectedKeys} onExpand={handleExpand} onSelect={handleSelect} showIcon={false} treeData={tree} />
        </div>
    );
};

export default IndexTree;
