import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Loading from "../common/lib/Loading";
import PageNotFound from "../common/lib/PageNotFound";
import { MultiLang } from "../config";
import Functions from "../functions";
import ItemType from "./item-type";
import ItemUtil, { Item } from "./lib/ItemUtil";

interface Props {
    lang: MultiLang;
    id: number | null;
    doi: string | null;
}

const XoonipsDetailItem: React.FC<Props> = (props: Props) => {
    const { lang, id, doi } = props;

    const [loading, setLoading] = useState<boolean>(true);
    const [item, setItem] = useState<Item | null>(null);

    useEffect(() => {
        if (doi !== null) {
            ItemUtil.getByDoi(doi, (item) => {
                setLoading(false);
                setItem(item);
            });
        } else if (id !== null) {
            ItemUtil.get(id, (item) => {
                setLoading(false);
                setItem(item);
            });
        }
    }, [id, doi]);

    if (loading) {
        return <Loading />;
    }
    if (item === null) {
        return <PageNotFound lang={lang} />;
    }
    return (
        <>
            <Helmet>
                <title>
                    {Functions.mlang(item.title, lang)} - {Functions.mlang("[en]Database[/en][ja]データベース[/ja]", lang)} - {Functions.siteTitle(lang)}
                </title>
            </Helmet>
            <h3>{Functions.mlang("[en]Detail[/en][ja]詳細[/ja]", lang)}</h3>
            <br />
            <ItemType.Detail lang={lang} item={item} />
        </>
    );
};

export default XoonipsDetailItem;
