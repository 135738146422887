import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MultiLang } from "../config";
import BrainCoordinateUtil, { BrainCoordinate, ConversionType } from "./lib/BrainCoordinateUtil";

interface Props {
    lang: MultiLang;
}

const NimgcenterBrainConv: React.FC<Props> = (props: Props) => {
    const defaultInputFormat = "\\d+\\.?\\d*";
    const defaultOutputFormat = "%x,%y,%z\\n";

    const [inputFormat, setInputFormat] = useState<string>(defaultInputFormat);
    const [inputArea, setInputArea] = useState<string>("");
    const [outputFormat, setOutputFormat] = useState<string>(defaultOutputFormat);
    const [outputArea, setOutputArea] = useState<string>("");
    const [conversionType, setConversionType] = useState<ConversionType>("icbm_spm2tal");

    const parseInput = (input: string, format: string): BrainCoordinate[] => {
        const ret: BrainCoordinate[] = [];
        const regObj = new RegExp(format, "g");
        const values = input.match(regObj) || [];
        for (let i = 0; i + 2 < values.length; i += 3) {
            ret.push({ x: parseFloat(values[i]), y: parseFloat(values[i + 1]), z: parseFloat(values[i + 2]) });
        }
        return ret;
    };

    const formatNumber = (value: number): string => {
        const digit = 4;
        const width = 7;
        const ret = value.toFixed(digit);
        return ret.length < width ? " ".repeat(width - ret.length) + ret : ret;
    };

    const onChangeInputFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputFormat(event.target.value);
    };

    const onClickDefaultInputFormatButton = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        setInputFormat(defaultInputFormat);
    };

    const onChangeInputArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputArea(event.target.value);
    };

    const onChangeOutputFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOutputFormat(event.target.value);
    };

    const onClickDefaultOutputFormatButton = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        setOutputFormat(defaultOutputFormat);
    };

    const onChangeMatrixType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (BrainCoordinateUtil.isConversionType(event.target.value)) {
            setConversionType(event.target.value as ConversionType);
        }
    };

    const onClickClearButton = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        setInputArea("");
        setOutputArea("");
    };

    const onClickConvertButton = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const inputValues = parseInput(inputArea, inputFormat);
        const outputValues = inputValues.map((inputValue) => BrainCoordinateUtil.convertCoordinate(conversionType, inputValue));
        const outputTextArea = outputValues
            .map((value) => {
                return outputFormat.replace("%x", formatNumber(value.x)).replace("%y", formatNumber(value.y)).replace("%z", formatNumber(value.z)).replace("\\n", "\n");
            })
            .join("");
        setOutputArea(outputTextArea);
    };

    const matrix = BrainCoordinateUtil.getConversionMatrix(conversionType);
    const matrixArea = "Conversion Matrix:\n" + matrix.map((vect) => " " + vect.map((value) => formatNumber(value)).join(", ")).join("\n");
    return (
        <div style={{ textAlign: "center" }}>
            <div style={{ background: "navajowhite", padding: "3px 0" }}>
                Brain Coordinate Converter is able to convert list of brain coordinate. <Link to="./howtouse.php">How To Use</Link>
            </div>
            <table style={{ textAlign: "left" }}>
                <tbody>
                    <tr>
                        <td>
                            <b>Input format: (Regular expression).</b>
                            <br />
                            <input type="text" value={inputFormat} onChange={onChangeInputFormat} />
                            <input type="button" onClick={onClickDefaultInputFormatButton} value="Default" />
                        </td>
                        <td>
                            <b>Output format: (%x,%y,%z is replaced. and \n)</b>
                            <br />
                            <input type="text" value={outputFormat} onChange={onChangeOutputFormat} />
                            <input type="button" onClick={onClickDefaultOutputFormatButton} value="Default" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Input:</b>
                            <br />
                            <textarea cols={37} rows={10} wrap="off" value={inputArea} onChange={onChangeInputArea} />
                        </td>
                        <td>
                            <b>Results:</b>
                            <br />
                            <textarea cols={37} rows={10} wrap="off" value={outputArea} readOnly />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <select size={8} onChange={onChangeMatrixType} value={conversionType}>
                                {BrainCoordinateUtil.getConversionTypes().map((type) => (
                                    <option key={type} value={type}>
                                        {BrainCoordinateUtil.getConversionLabel(type)}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td>
                            <textarea cols={37} rows={5} wrap="off" value={matrixArea} readOnly />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input type="button" onClick={onClickConvertButton} value="Convert" />
                        </td>
                        <td>
                            <input type="button" onClick={onClickClearButton} value="Clear" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default NimgcenterBrainConv;
