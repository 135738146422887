import React from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, Route, Routes } from "react-router-dom";
import PageNotFound from "../common/lib/PageNotFound";
import XoopsCode from "../common/lib/XoopsCode";
import { MultiLang } from "../config";
import jsonHowToUse from "./assets/howtouse.json";
import jsonUtilities from "./assets/utilities.json";
import NimgcenterBrainConv from "./NimgcenterBrainConv";

interface Props {
    lang: MultiLang;
}

const NimgcenterUtilities: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <>
            <Helmet>
                <title>NIMG Utilities</title>
            </Helmet>
            <Routes>
                <Route path="" element={<XoopsCode lang={lang} text={jsonUtilities[lang]} dohtml={true} />} />
                <Route path="brain_conv/" element={<NimgcenterBrainConv lang={lang} />} />
                <Route path="brain_conv/howtouse.php" element={<XoopsCode lang={lang} text={jsonHowToUse[lang]} dohtml={true} />} />
                <Route path="index.php" element={<Navigate to="/modules/xnpnimgcenter/utilities/" />} />
                <Route path="brain_conv/index.php" element={<Navigate to="/modules/xnpnimgcenter/utilities/brain_conv/" />} />
                <Route path="*" element={<PageNotFound lang={lang} />} />
            </Routes>
        </>
    );
};

export default NimgcenterUtilities;
