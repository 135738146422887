import iconFile from "../../assets/images/icon_url.gif";
import TopBase, { TopBaseProps } from "../lib/TopBase";

class UrlTop extends TopBase {
    constructor(props: TopBaseProps) {
        super(props);
        this.type = "url";
        this.label = "Url";
        this.icon = iconFile;
        this.description = "[en]Link information.[/en][ja]関連リンク[/ja]";
    }
}

export default UrlTop;
