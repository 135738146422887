import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import PageNotFound from "../common/lib/PageNotFound";
import { MultiLang } from "../config";
import Functions from "../functions";
import IndexUtil, { Index, INDEX_ID_PUBLIC } from "./lib/IndexUtil";
import ItemUtil, { SearchCallbackFunc, SortCondition } from "./lib/ItemUtil";
import XoonipsListIndex from "./lib/XoonipsListIndex";
import XoonipsListItem from "./lib/XoonipsListItem";

interface Props {
    lang: MultiLang;
    id: number | null;
}

const XoonipsSearchByIndexId: React.FC<Props> = (props: Props) => {
    const { lang, id } = props;
    const indexId = id ?? INDEX_ID_PUBLIC;

    const searchFunc = (condition: SortCondition, func: SearchCallbackFunc) => {
        if (indexId === 0) {
            const res = { total: 0, data: [] };
            func(res);
        } else {
            ItemUtil.getListByIndexId(indexId, condition, func);
        }
    };

    const index = IndexUtil.get(indexId);
    if (index === null) {
        return <PageNotFound lang={lang} />;
    }

    const baseUrl = indexId === 0 ? "/" : IndexUtil.getUrl(indexId);
    const pIndexes = IndexUtil.getParents(indexId);
    const parents = pIndexes.map((value: Index) => {
        const url: string = IndexUtil.getUrl(value.id);
        return (
            <Fragment key={value.id}>
                / <Link to={url}>{value.title}</Link>{" "}
            </Fragment>
        );
    });
    const title = pIndexes
        .map((value) => {
            return "/" + value.title;
        })
        .join("");

    return (
        <div className="list">
            <Helmet>
                <title>
                    {Functions.mlang(title, lang)} - {Functions.mlang("[en]Database[/en][ja]データベース[/ja]", lang)} - {Functions.siteTitle(lang)}
                </title>
            </Helmet>
            <h3>{Functions.mlang("[en]Listing item[/en][ja]アイテム一覧[/ja]", lang)}</h3>
            <div>{parents}</div>
            <XoonipsListIndex lang={lang} index={index} />
            <XoonipsListItem lang={lang} url={baseUrl} search={searchFunc} />
        </div>
    );
};

export default XoonipsSearchByIndexId;
