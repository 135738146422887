import Functions from "../../../functions";
import ItemUtil, { ItemData } from "../../lib/ItemUtil";
import DetailBase from "../lib/DetailBase";
import ItemTypeField from "../lib/field";
import SimPFLinkIcon from "../lib/field/SimPFLinkIcon";
import DataUtil from "./DataUtil";

class DataDetail extends DetailBase {
    getFields() {
        const { lang } = this.props;
        const item = this.props.item as ItemData;
        const fields = [
            { label: "ID", value: item.doi },
            { label: "[en]Language[/en][ja]言語[/ja]", value: <ItemTypeField.Language lang={lang} itemLang={item.lang} /> },
            { label: "[en]Title[/en][ja]タイトル[/ja]", value: Functions.mlang(item.title, lang) },
            { label: "[en]Free Keywords[/en][ja]フリーキーワード[/ja]", value: <ItemTypeField.FreeKeyword lang={lang} keyword={item.keyword} /> },
            { label: "[en]Description[/en][ja]概要[/ja]", value: <ItemTypeField.Description lang={lang} description={item.description} /> },
            { label: "[en]Date[/en][ja]日付[/ja]", value: <ItemTypeField.PublicationDate lang={lang} year={item.publication_year} month={item.publication_month} mday={item.publication_mday} /> },
            { label: "[en]Last Modified Date[/en][ja]最終更新日[/ja]", value: <ItemTypeField.DateTime lang={lang} date={item.last_update_date} /> },
            { label: "[en]Created Date[/en][ja]作成日[/ja]", value: <ItemTypeField.DateTime lang={lang} date={item.creation_date} /> },
            { label: "[en]Contributor[/en][ja]登録者[/ja]", value: <ItemTypeField.Contributer lang={lang} uname={item.uname} name={item.name} /> },
            { label: "[en]Item Type[/en][ja]アイテムタイプ[/ja]", value: item.item_type_display_name },
            { label: "[en]Change Log(History)[/en][ja]変更履歴[/ja]", value: <ItemTypeField.ChangeLog lang={lang} changelog={item.changelog} /> },
            { label: "[en]Data Type[/en][ja]データタイプ[/ja]", value: <DataUtil.DataType lang={lang} type={item.data_type} /> },
            { label: "[en]Experimenter[/en][ja]実験者[/ja]", value: <ItemTypeField.Author lang={lang} author={item.experimenter} /> },
            { label: "[en]Preview[/en][ja]プレビュー[/ja]", value: <ItemTypeField.Preview lang={lang} file={item.file} /> },
            { label: "[en]Data File[/en][ja]データファイル[/ja]", value: <ItemTypeField.ItemFile lang={lang} file={item.file} type="data_file" rights={item.rights} useCc={item.use_cc} ccCommercialUse={item.cc_commercial_use} ccModification={item.cc_modification} downloadLimit={item.attachment_dl_limit} /> },
            { label: "Readme", value: <ItemTypeField.Readme lang={lang} readme={item.readme} /> },
            { label: "Rights", value: <ItemTypeField.Rights lang={lang} rights={item.rights} useCc={item.use_cc} ccCommercialUse={item.cc_commercial_use} ccModification={item.cc_modification} /> },
            { label: "Index", value: <ItemTypeField.ItemIndex lang={lang} index={item.index} /> },
            { label: "[en]Related to[/en][ja]関連アイテム[/ja]", value: <ItemTypeField.RelatedTo lang={lang} relatedTo={item.related_to} /> },
        ];
        const simpfLinkUrl = ItemUtil.getSimPFLinkUrl(item.item_id);
        if (simpfLinkUrl !== "") {
            const field = { label: "Online Simulation", value: <SimPFLinkIcon lang={lang} url={simpfLinkUrl} isDetail={true} /> };
            fields.splice(14, 0, field);
        }
        return fields;
    }
}

export default DataDetail;
