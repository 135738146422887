import React from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PageNotFound from "../common/lib/PageNotFound";
import { MultiLang } from "../config";
import Functions from "../functions";
import { INDEX_ID_PUBLIC } from "./lib/IndexUtil";
import ItemUtil from "./lib/ItemUtil";
import styles from "./Xoonips.module.css";
import XoonipsAdvancedSearch from "./XoonipsAdvancedSearch";
import XoonipsDetailItem from "./XoonipsDetailItem";
import XoonipsSearchByAdvancedKeyword from "./XoonipsSearchByAdvancedKeyword";
import XoonipsSearchByIndexId from "./XoonipsSearchByIndexId";
import XoonipsSearchByItemType from "./XoonipsSearchByItemType";
import XoonipsSearchByKeyword from "./XoonipsSearchByKeyword";
import XoonipsTop from "./XoonipsTop";

interface Props {
    lang: MultiLang;
}

const ItemDetail: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const itemIdStr = params.get("item_id");
    const itemId = itemIdStr !== null ? (/^\d+$/.test(itemIdStr) ? parseInt(itemIdStr, 10) : 0) : null;
    const doi = params.get("id");
    return <XoonipsDetailItem lang={lang} id={itemId} doi={doi} />;
};

const ItemList: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get("index_id") || "";
    const indexId = /^\d+$/.test(id) ? parseInt(id, 10) : INDEX_ID_PUBLIC;
    return <XoonipsSearchByIndexId lang={lang} id={indexId} />;
};

const ItemSelect: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const op = params.get("op") ?? "";
    switch (op) {
        case "quicksearch": {
            const { type, keyword } = ItemUtil.getSearchKeywordByQuery(location.search);
            return <XoonipsSearchByKeyword lang={lang} type={type} keyword={keyword} />;
        }
        case "itemtypesearch": {
            const searchItemtype = params.get("search_itemtype") || "";
            const match = searchItemtype.match(/^xnp([a-z]+)$/);
            const itemType = match !== null ? match[1] : "";
            return <XoonipsSearchByItemType lang={lang} itemType={itemType} itemSubType="" />;
        }
        case "itemsubtypesearch": {
            let itemType: string | null = null;
            let itemSubType: string | null = null;
            // xoonips 3.x compatibility style
            params.forEach((v, k) => {
                if (/^xnp[a-z]+$/.test(k) && v === "on") {
                    itemType = k.replace(/^xnp/, "");
                    return;
                }
            });
            if (itemType !== null) {
                params.forEach((v, k) => {
                    if (k.match(`^xnp${itemType}_.+$`) !== null && !!v) {
                        itemSubType = v;
                        return;
                    }
                });
            }
            // simplified when archiving
            if (itemType === null) {
                const searchItemtype = params.get("search_itemtype") ?? "";
                const match = searchItemtype.match(/^xnp([a-z]+)$/);
                itemType = match !== null ? match[1] : "";
            }
            if (itemSubType === null) {
                itemSubType = params.get("search_subitemtype") ?? "";
            }

            return <XoonipsSearchByItemType lang={lang} itemType={itemType} itemSubType={itemSubType} />;
        }
        case "advanced": {
            return <XoonipsSearchByAdvancedKeyword lang={lang} />;
        }
    }
    return <PageNotFound lang={lang} />;
};

const Xoonips: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <div className={styles.database}>
            <Helmet>
                <title>
                    {Functions.mlang("[en]Database[/en][ja]データベース[/ja]", lang)} - {Functions.siteTitle(lang)}
                </title>
            </Helmet>
            <Routes>
                <Route index element={<XoonipsTop lang={lang} />} />
                <Route path="index.php" element={<Navigate to="/modules/xoonips/" />} />
                <Route path="detail.php" element={<ItemDetail lang={lang} />} />
                <Route path="listitem.php" element={<ItemList lang={lang} />} />
                <Route path="itemselect.php" element={<ItemSelect lang={lang} />} />
                <Route path="advanced_search.php" element={<XoonipsAdvancedSearch lang={lang} />} />
                <Route path="search/advanced" element={<XoonipsSearchByAdvancedKeyword lang={lang} />} />
                <Route path="*" element={<PageNotFound lang={lang} />} />
            </Routes>
        </div>
    );
};

export default Xoonips;
